import { FormElement } from '../formElement';
import { FormStep } from '../formStep';
import { TextField } from '../fields/textField';
import { GeoCoordinatesFormGroup } from '../fields/geoCoordinatesFormGroup';
import { CheckboxField } from '../fields/checkboxField';
import { SelectField } from '../fields/selectField';
import { UploadField } from '../fields/uploadField';
import { DateField } from '../fields/dateField';
import { CountField } from '../fields/countField';
import { WithOptional } from '../utility';
import { DateRangeField } from '../fields/dateRangeField';

type ConfigProps = WithOptional<KitchenSinkFormStep1, keyof FormStep>;

export class KitchenSinkFormStep1 extends FormStep {
    public readonly textBlock: FormElement;
    public readonly requiredField: TextField;
    public readonly multiLineField: TextField;
    public readonly numberField: TextField;
    public readonly wideField: TextField;
    public readonly innerLabelField: TextField;
    public readonly geoCoordinatesField: GeoCoordinatesFormGroup;
    public readonly checkboxField1: CheckboxField;
    public readonly checkboxField2: CheckboxField;
    public readonly selectField: SelectField<string>;
    public readonly selectFieldSingleOption: SelectField<string>;
    public readonly selectFieldSearch: SelectField<string>;
    public readonly selectFieldMsb: SelectField<string>;
    public readonly fileUpload: UploadField;
    public readonly datePickerField: DateField;
    public readonly datePickerRangeField: DateRangeField;
    public readonly countField: CountField;
    public readonly documentDownload: FormElement;
    public readonly formButton: FormElement;

    public constructor(config: ConfigProps) {
        super(config);

        this.textBlock = config.textBlock;
        this.requiredField = config.requiredField;
        this.multiLineField = config.multiLineField;
        this.numberField = config.numberField;
        this.wideField = config.wideField;
        this.innerLabelField = config.innerLabelField;
        this.geoCoordinatesField = config.geoCoordinatesField;
        this.checkboxField1 = config.checkboxField1;
        this.checkboxField2 = config.checkboxField2;
        this.selectField = config.selectField;
        this.selectFieldSingleOption = config.selectFieldSingleOption;
        this.selectFieldSearch = config.selectFieldSearch;
        this.selectFieldMsb = config.selectFieldMsb;
        this.fileUpload = config.fileUpload;
        this.datePickerField = config.datePickerField;
        this.datePickerRangeField = config.datePickerRangeField;
        this.countField = config.countField;
        this.documentDownload = config.documentDownload;
        this.formButton = config.formButton;
    }
}
