import { FormElement } from '../formElement';
import { FormStep } from '../formStep';
import { SelectField } from '../fields/selectField';
import { CheckboxesField } from '../fields/checkboxesField';
import { TextField } from '../fields/textField';
import { CheckboxField } from '../fields/checkboxField';
import { DateField } from '../fields/dateField';
import { PlantTypeFieldTypeForGenerationTypeFormStep } from '../shared/plantTypeFieldType';
import { WithOptional } from '../utility';
import { EnergyTypeFieldType } from '../shared/energyTypeFieldType';
import { EmergencyGeneratorOperatingModeType } from '../shared/emergencyGeneratorOperatingModeType';
import { RadioButtonsField } from '../fields/radioButtonsField';
import { PlantStructureTypeValueType } from '../shared/plantStructureTypeValueType';
import { TotalPlantPerformanceValueType } from '../shared/totalPlantPerformanceValueType';
import { RequestType } from '../shared/requestType';

type ConfigProps = WithOptional<GenerationTypeFormStep, keyof FormStep>;

export class GenerationTypeFormStep extends FormStep {
    public readonly stepIntro: FormElement;
    public readonly energyType: SelectField<EnergyTypeFieldType | ''>;
    public readonly plantType: SelectField<
        PlantTypeFieldTypeForGenerationTypeFormStep | ''
    >;
    public readonly plantStructureType: RadioButtonsField<
        PlantStructureTypeValueType
    >;
    public readonly totalPlantPerformance: RadioButtonsField<
        TotalPlantPerformanceValueType
    >;
    public readonly requestType: RadioButtonsField<RequestType>;
    public readonly requestWithPlanningInfo: FormElement;
    public readonly requestWithoutPlanningInfo: FormElement;
    public readonly emergencyGeneratorOperatingMode: CheckboxesField<
        EmergencyGeneratorOperatingModeType
    >;
    public readonly specifiedEmergencyGeneratorOperatingMode: TextField;
    public readonly plugInGeneratingPlant: CheckboxField;
    public readonly simplifiedRegistrationProcessNotice: FormElement;
    public readonly proceedWithFullRegistrationForEEGCompensation: CheckboxField;
    public readonly simplifiedProcedureHint: FormElement;
    public readonly bidirectionalMeter: CheckboxField;
    public readonly bidirectionalMeterInfo: FormElement;
    public readonly consumption: TextField;
    public readonly infeed: TextField;
    public readonly readingDate: DateField;
    public readonly plugInGeneratingPlantInfo: FormElement;

    public constructor(config: ConfigProps) {
        super(config);

        this.stepIntro = config.stepIntro;
        this.plugInGeneratingPlant = config.plugInGeneratingPlant;
        this.energyType = config.energyType;
        this.plantType = config.plantType;
        this.plantStructureType = config.plantStructureType;
        this.totalPlantPerformance = config.totalPlantPerformance;
        this.requestType = config.requestType;
        this.requestWithPlanningInfo = config.requestWithPlanningInfo;
        this.requestWithoutPlanningInfo = config.requestWithoutPlanningInfo;
        this.emergencyGeneratorOperatingMode =
            config.emergencyGeneratorOperatingMode;
        this.specifiedEmergencyGeneratorOperatingMode =
            config.specifiedEmergencyGeneratorOperatingMode;
        this.simplifiedRegistrationProcessNotice =
            config.simplifiedRegistrationProcessNotice;
        this.proceedWithFullRegistrationForEEGCompensation =
            config.proceedWithFullRegistrationForEEGCompensation;
        this.simplifiedProcedureHint = config.simplifiedProcedureHint;
        this.bidirectionalMeter = config.bidirectionalMeter;
        this.bidirectionalMeterInfo = config.bidirectionalMeterInfo;
        this.consumption = config.consumption;
        this.infeed = config.infeed;
        this.readingDate = config.readingDate;
        this.plugInGeneratingPlantInfo = config.plugInGeneratingPlantInfo;
    }
}
