import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import { BillingFormStep } from '@/types/forms/formSteps/billingFormStep';

export const billingRules = async (
    formStep: { model: BillingFormStep; formModel: ApplicationForm } & Vue,
): Promise<void> => {
    const applicationType = formStep.formModel.applicationType;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Abrechnung#FormularAbrechnung-noEegRemuneration
    formStep.model.noEegRemuneration.disabled =
        (applicationType !== 'photovoltaic' && applicationType !== 'dea') ||
        (!formStep.formModel.generationType
            .proceedWithFullRegistrationForEEGCompensation.disabled &&
            !!formStep.formModel.generationType
                .proceedWithFullRegistrationForEEGCompensation.value);

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Abrechnung#FormularAbrechnung-reasonNoEegRemuneration
    formStep.model.reasonNoEegRemuneration.disabled =
        formStep.model.noEegRemuneration.disabled ||
        !formStep.model.noEegRemuneration.value;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Abrechnung#FormularAbrechnung-explanationNoEegRemuneration
    formStep.model.explanationNoEegRemuneration.disabled =
        formStep.model.reasonNoEegRemuneration.disabled ||
        formStep.model.reasonNoEegRemuneration.value !== 'other';

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Abrechnung#FormularAbrechnung-noEegRemunerationHint
    formStep.model.noEegRemunerationHint.disabled =
        formStep.model.noEegRemuneration.disabled ||
        !formStep.model.noEegRemuneration.value;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854573897/Formular+Abrechnung#FormularAbrechnung-taxNumber
    formStep.model.taxNumber.disabled =
        (!formStep.model.taxNumberWillFollow.disabled &&
            !!formStep.model.taxNumberWillFollow.value) ||
        formStep.model.tax.disabled ||
        formStep.model.tax.value === 'noSalesTax';

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854573897/Formular+Abrechnung#FormularAbrechnung-taxNumberWillFollow
    formStep.model.taxNumberWillFollow.disabled =
        formStep.model.tax.disabled ||
        formStep.model.tax.value === 'noSalesTax';

    const noEegRenumerationIsChecked =
        !formStep.model.noEegRemuneration.disabled &&
        !!formStep.model.noEegRemuneration.value;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854573897/Formular+Abrechnung#FormularAbrechnung-directDebitMandate
    formStep.model.directDebitMandate.disabled = noEegRenumerationIsChecked;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854573897/Formular+Abrechnung#FormularAbrechnung-iban
    formStep.model.iban.disabled = noEegRenumerationIsChecked;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854573897/Formular+Abrechnung#FormularAbrechnung-nameAndDomicileCreditInstitution
    formStep.model.nameAndDomicileCreditInstitution.disabled = noEegRenumerationIsChecked;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854573897/Formular+Abrechnung#FormularAbrechnung-date
    formStep.model.date.disabled = noEegRenumerationIsChecked;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854573897/Formular+Abrechnung#FormularAbrechnung-accountOwnerName
    formStep.model.accountOwnerName.disabled = noEegRenumerationIsChecked;
};
