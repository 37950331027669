import { CountField } from '@/types/forms/fields/countField';
import { FormList } from '@/types/forms/fields/formList';
import { TextField } from '@/types/forms/fields/textField';
import { ManufacturerAndModuleTypeFormGroup } from '@/types/forms/formGroups/plantPerformance/manufacturerAndModuleTypeFormGroup';
import { required } from 'vuelidate/lib/validators';
import { float } from '@/validators/float';
import { positive } from '@/validators/positive';
import { FormField } from '@/types/forms/fields/formField';
import { FormElement } from '@/types/forms/formElement';
import { InverterFormGroup } from '@/types/forms/formGroups/plantPerformance/inverter/inverterFormGroup';
import { InverterModuleFormGroup } from '@/types/forms/formGroups/plantPerformance/inverter/inverterModuleFormGroup';
import { ActivePowerAndApparentPowerFormGroup } from '@/types/forms/formGroups/plantPerformance/activePowerAndApparentPowerFormGroup';
import { apparentPowerMustBeBiggerThanActualPower } from '@/validators/apparentPowerMustBeBiggerThanActualPower';
import { maxApparentPowerPlugInGeneratingPlant } from '@/validators/maxApparentPowerPlugInGeneratingPlant';
import { restrictedApparentPower } from './boundaryValues';

export const inverterFormGroup = new InverterFormGroup({
    inverterHeadline: new FormElement(),
    inverterModules: new FormList(InverterModuleFormGroup, {
        initializeItems: 1,
        minItemCount: 1,
        maxItemCount: 5,
        id: new FormField<string>(),
        inverterManufacturerAndModuleType: new ManufacturerAndModuleTypeFormGroup(
            {
                manufacturer: new TextField({
                    validators: {
                        required,
                    },
                }),
                moduleType: new TextField({
                    validators: {
                        required,
                    },
                }),
            },
        ),
        inverterQuantity: new CountField({
            value: 1,
            min: 1,
            validators: {
                required,
            },
        }),
        inverterContributionSymmetricalShortCircuitCurrent: new TextField({
            validators: {
                required,
                float,
                positive,
            },
        }),
        inverterVoltageSymmetricalShortCircuitCurrent: new TextField({
            validators: {
                required,
                float,
                positive,
            },
        }),
        inverterContributionSustainedShortCircuitCurrent: new TextField({
            validators: {
                required,
                float,
                positive,
            },
        }),
        inverterVoltageSustainedShortCircuitCurrent: new TextField({
            validators: {
                required,
                float,
                positive,
            },
        }),
        inverterActivePowerAndApparentPower: new ActivePowerAndApparentPowerFormGroup(
            {
                activePower: new TextField({
                    validators: {
                        required,
                        float,
                        positive,
                        apparentPowerMustBeBiggerThanActualPower,
                    },
                }),
                apparentPower: new TextField({
                    validators: {
                        required,
                        float,
                        positive,
                    },
                }),
            },
        ),
        inverterTotalActivePower: new FormField({
            value: 0,
        }),
        inverterTotalApparentPower: new FormField({
            value: 0,
            validators: {
                maxApparentPowerPlugInGeneratingPlant: maxApparentPowerPlugInGeneratingPlant(
                    restrictedApparentPower,
                ),
            },
        }),
    }),
    inverterOverallActivePower: new FormField({
        value: 0,
    }),
    inverterOverallActivePowerWarning: new FormElement(),
    inverterOverallApparentPower: new FormField({
        value: 0,
    }),
});
