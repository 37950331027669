import { required } from 'vuelidate/lib/validators';
import { FormElement } from '@/types/forms/formElement';
import { FormOption } from '@/types/forms/formOption';
import { DateField } from '@/types/forms/fields/dateField';
import { TextField } from '@/types/forms/fields/textField';
import { RadioButtonsField } from '@/types/forms/fields/radioButtonsField';
import { iban } from '@/validators/iban';
import { BillingFormStep } from '@/types/forms/formSteps/billingFormStep';
import { CheckboxField } from '@/types/forms/fields/checkboxField';
import { requiredDate } from '@/validators/requiredDate';

export const billingFormStep = new BillingFormStep({
    paymentRequirements: new FormElement(),
    noEegRemuneration: new CheckboxField(),
    reasonNoEegRemuneration: new RadioButtonsField({
        value: 'economicDisadvantages',
        options: [
            new FormOption({ key: 'economicDisadvantages' }),
            new FormOption({ key: 'other' }),
        ],
        validators: {
            required,
        },
    }),
    explanationNoEegRemuneration: new TextField({
        validators: {
            required,
        },
    }),
    noEegRemunerationHint: new FormElement(),
    entrepreneurHint: new FormElement(),
    taxNumberWillFollow: new CheckboxField(),
    taxNumber: new TextField({
        validators: {
            required,
        },
    }),
    tax: new RadioButtonsField({
        value: 'noSalesTax',
        options: [
            new FormOption({ key: 'noSalesTax' }),
            new FormOption({ key: 'salesTax' }),
        ],
        validators: {
            required,
        },
    }),
    plantOperatorObligationTaxChange: new FormElement(),
    directDebitMandate: new FormElement(),
    iban: new TextField({
        validators: {
            required,
            iban,
        },
    }),
    nameAndDomicileCreditInstitution: new TextField(),
    date: new DateField({
        validators: {
            requiredDate,
        },
    }),
    accountOwnerName: new TextField({
        validators: {
            required,
        },
    }),
});
