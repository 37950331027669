import { UploadedFileInfo } from '@/api/interfaces/file/uploadedFileInfo';
import { helpers } from 'vuelidate/lib/validators';
import { appConfiguration } from '@/services/form/appConfiguration';

// file types as array of extensions without the dot. e.g. ['pdf', 'jpg']
export const fileType = (value: UploadedFileInfo): boolean => {
    if (value?.scId) {
        const allowedTypes =
            appConfiguration?.fileUploadConfigurations[value.scId]?.fileTypes;
        const lowercaseExtension = value?.name?.split('.').pop()?.toLowerCase();
        return (
            !helpers.req(value) ||
            allowedTypes.some((key) => key.toLowerCase() === lowercaseExtension)
        );
    }
    return true;
};
