import Vue from 'vue';
import { PlantPerformanceFormStep } from '@/types/forms/formSteps/plantPerformanceFormStep';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import {
    getOverallNominalPower,
    isOverallActivePowerWarningEnabled,
} from '../../../utilities/application/plantPerformance';
import {
    isPluginGeneratingPlant,
    isPv,
    isRequestWithoutPlanning,
    isTotalPlantPerformanceAbove300,
} from '../common';

export const plantPerformanceInverterRules = async (
    formStep: {
        model: PlantPerformanceFormStep;
        formModel: ApplicationForm;
    } & Vue,
): Promise<void> => {
    const hasTotalPlantPerformanceAbove300 = isTotalPlantPerformanceAbove300(
        formStep.formModel,
    );
    const requestWithoutPlanning = isRequestWithoutPlanning(formStep.formModel);

    for (const module of formStep.model.inverter.inverterModules.items) {
        module.inverterManufacturerAndModuleType.disabled = isPluginGeneratingPlant(
            formStep.formModel,
        );

        module.inverterContributionSymmetricalShortCircuitCurrent.disabled =
            !hasTotalPlantPerformanceAbove300 || requestWithoutPlanning;
        module.inverterVoltageSymmetricalShortCircuitCurrent.disabled =
            !hasTotalPlantPerformanceAbove300 || requestWithoutPlanning;
        module.inverterContributionSustainedShortCircuitCurrent.disabled =
            !hasTotalPlantPerformanceAbove300 || requestWithoutPlanning;
        module.inverterVoltageSustainedShortCircuitCurrent.disabled =
            !hasTotalPlantPerformanceAbove300 || requestWithoutPlanning;
    }

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4864934167/Feldgruppe+Wechselrichter+PV+und+Speicher+MS#FeldgruppeWechselrichterPVundSpeicherMS-overallActivePower
    // if the overall active power differs from the photovoltaic overall nominal power
    // or the storage overall discharge power by more than 10%, a warning is shown in the form
    formStep.model.inverter.inverterOverallActivePowerWarning.disabled =
        isPluginGeneratingPlant(formStep.formModel) ||
        !isPv(formStep.formModel) ||
        !isOverallActivePowerWarningEnabled(
            formStep.model.inverter.inverterOverallActivePower.value,
            getOverallNominalPower(formStep.model),
        );
};
