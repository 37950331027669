import { FormField } from '@/types/forms/fields/formField';
import { addRequiredValidator } from './addRequiredValidator';
import { removeRequiredValidator } from './removeRequiredValidator';

export function toggleRequiredValidator(
    formField: FormField<unknown>,
    addOrRemove: boolean,
): void {
    removeRequiredValidator(formField);
    if (addOrRemove) {
        addRequiredValidator(formField);
    }
}
