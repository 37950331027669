import { PlantTypeFieldTypeForGenerationTypeFormStep } from '@/types/forms/shared/plantTypeFieldType';
import type { ApplicationType } from '@/types/forms/specific/applicationForm';

export function getApplicationType(
    plantType:
        | PlantTypeFieldTypeForGenerationTypeFormStep
        | ''
        | null
        | undefined,
): ApplicationType | undefined {
    switch (plantType) {
        case null:
        case undefined:
        case '':
            return undefined;
        case 'photovoltaicPowerPlant':
            return 'photovoltaic';
        case 'storage':
            return 'storage';
        case 'windPowerPlant':
        case 'hydroPowerPlant':
        case 'thermalPowerPlantDea':
            return 'dea';
        case 'thermalPowerPlantKwk':
        case 'fuelCell':
        case 'orcPowerPlant':
        case 'stirlingEngine':
            return 'powerHeatCoupling';
        default:
            return 'unknown';
    }
}
