import { JSONSchema7Definition } from 'json-schema';
import { FormFieldWithOptions } from './formFieldWithOptions';

export class RadioButtonsField<TOptionKey> extends FormFieldWithOptions<
    TOptionKey,
    TOptionKey
> {
    public getJsonSchema(): JSONSchema7Definition {
        // the type of the value is not specified
        return {};
    }
}
