import { PlantPerformanceFormStep } from '@/types/forms/formSteps/plantPerformanceFormStep';
import { photovoltaicFormGroup } from './plantPerformance/photovoltaic';
import { storageFormGroup } from './plantPerformance/storage';
import { generatorFormGroup } from './plantPerformance/generator';
import { CheckboxField } from '@/types/forms/fields/checkboxField';
import { checked } from '@/validators/checked';
import { inverterFormGroup } from './plantPerformance/inverter';
import { alwaysFalse } from '@/validators/alwaysFalse';
import { FormElement } from '@/types/forms/formElement';

const minOverallApparentPowerValidator = (
    model: PlantPerformanceFormStep,
    min: number,
) => {
    return (
        (typeof model.inverter.inverterOverallApparentPower === 'number' &&
            model.inverter.inverterOverallApparentPower > min) ||
        (typeof model.generator.generatorOverallApparentPower === 'number' &&
            model.generator.generatorOverallApparentPower > min)
    );
};

export const proofOfConformityValidator = (
    model: PlantPerformanceFormStep,
): boolean => minOverallApparentPowerValidator(model, 30);

export const plantPerformanceFormStep = new PlantPerformanceFormStep({
    photovoltaic: photovoltaicFormGroup,
    inverter: inverterFormGroup,
    storage: storageFormGroup,
    generator: generatorFormGroup,
    certificateHint: new CheckboxField({
        validators: {
            checked,
        },
    }),
    overallNominalPowerNotMatchingTotalPlantPerformanceValueTypeWarning: new FormElement(
        {
            validators: {
                alwaysFalse,
            },
        },
    ),
});
