import { JSONSchema7Definition } from 'json-schema';
import { defaults as _defaults } from 'lodash';
import {
    FormFieldWithOptions,
    FormFieldWithOptionsConfig,
    formFieldWithOptionsDefaultConfig,
} from './formFieldWithOptions';

export const selectFieldDefaultConfig = {
    ...formFieldWithOptionsDefaultConfig,
};

export class SelectField<TOptionKey> extends FormFieldWithOptions<
    TOptionKey,
    TOptionKey
> {
    public constructor(
        config: FormFieldWithOptionsConfig<TOptionKey, TOptionKey>,
    ) {
        const resolvedConfig = _defaults(
            config ||
                ({} as FormFieldWithOptionsConfig<TOptionKey, TOptionKey>),
            selectFieldDefaultConfig,
        );

        super(resolvedConfig);
    }

    public static preselectExactlyOneOption<TOptionKey>(
        select: SelectField<TOptionKey>,
        optionKey: TOptionKey,
    ): void {
        for (const option of select.options) {
            option.disabled = true;
        }
        const optionToPreselect = select.options.find(
            (option) => option.key === optionKey,
        );
        if (optionToPreselect) {
            optionToPreselect.disabled = false;
        }
        select.value = optionKey;
    }

    public getJsonSchema(): JSONSchema7Definition {
        // the type of the value is not specified
        return {};
    }
}
