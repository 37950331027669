import { FormStep } from '../formStep';
import { RadioButtonsField } from '../fields/radioButtonsField';
import { WithOptional } from '../utility';

type ConfigProps = WithOptional<KitchenSinkFormStep2, keyof FormStep>;

export class KitchenSinkFormStep2 extends FormStep {
    public readonly selected: RadioButtonsField<string>;
    public readonly selectedTiles: RadioButtonsField<string>;

    public constructor(config: ConfigProps) {
        super(config);

        this.selected = config.selected;
        this.selectedTiles = config.selectedTiles;
    }
}
