import { TextField } from '../fields/textField';
import { FormElement } from '../formElement';
import { RadioButtonsField } from '../fields/radioButtonsField';
import { DateField } from '../fields/dateField';
import { FormStep } from '../formStep';
import { WithOptional } from '../utility';
import { TaxValueType } from '../shared/taxValueType';
import { CheckboxField } from '../fields/checkboxField';
import { ReasonNoEegRemunerationType } from '../shared/reasonNoEegRemunerationType';

type ConfigProps = WithOptional<BillingFormStep, keyof FormStep>;

export class BillingFormStep extends FormStep {
    public readonly paymentRequirements: FormElement;
    public readonly noEegRemuneration: CheckboxField;
    public readonly reasonNoEegRemuneration: RadioButtonsField<
        ReasonNoEegRemunerationType
    >;
    public readonly noEegRemunerationHint: FormElement;
    public readonly explanationNoEegRemuneration: TextField;
    public readonly entrepreneurHint: FormElement;
    public readonly taxNumberWillFollow: CheckboxField;
    public readonly taxNumber: TextField;
    public readonly tax: RadioButtonsField<TaxValueType>;
    public readonly plantOperatorObligationTaxChange: FormElement;
    public readonly directDebitMandate: FormElement;
    public readonly iban: TextField;
    public readonly nameAndDomicileCreditInstitution: TextField;
    public readonly date: DateField;
    public readonly accountOwnerName: TextField;

    public constructor(config: ConfigProps) {
        super(config);

        this.paymentRequirements = config.paymentRequirements;
        this.noEegRemuneration = config.noEegRemuneration;
        this.reasonNoEegRemuneration = config.reasonNoEegRemuneration;
        this.noEegRemunerationHint = config.noEegRemunerationHint;
        this.explanationNoEegRemuneration = config.explanationNoEegRemuneration;
        this.entrepreneurHint = config.entrepreneurHint;
        this.taxNumberWillFollow = config.taxNumberWillFollow;
        this.taxNumber = config.taxNumber;
        this.tax = config.tax;
        this.plantOperatorObligationTaxChange =
            config.plantOperatorObligationTaxChange;
        this.directDebitMandate = config.directDebitMandate;
        this.iban = config.iban;
        this.nameAndDomicileCreditInstitution =
            config.nameAndDomicileCreditInstitution;
        this.date = config.date;
        this.accountOwnerName = config.accountOwnerName;
    }
}
