import moment from 'moment';
import { required, integer } from 'vuelidate/lib/validators';
import { positive } from '@/validators/positive';
import { maxDate } from '@/validators/maxDate';
import { requiredDate } from '@/validators/requiredDate';
import { FormElement } from '@/types/forms/formElement';
import { FormOption } from '@/types/forms/formOption';
import { TextField } from '@/types/forms/fields/textField';
import { CheckboxField } from '@/types/forms/fields/checkboxField';
import { CheckboxesField } from '@/types/forms/fields/checkboxesField';
import { SelectField } from '@/types/forms/fields/selectField';
import { DateField } from '@/types/forms/fields/dateField';
import { GenerationTypeFormStep } from '@/types/forms/formSteps/generationTypeFormStep';
import { RadioButtonsField } from '@/types/forms/fields/radioButtonsField';
import { checked } from '@/validators/checked';

export const generationTypeFormStep = new GenerationTypeFormStep({
    stepIntro: new FormElement(),
    energyType: new SelectField({
        options: [
            new FormOption({ key: 'energyStorage' }), // Energiespeicher
            new FormOption({ key: 'sun' }), // Sonne
            new FormOption({ key: 'wind' }), // Wind
            new FormOption({ key: 'water' }), // Wasser
            new FormOption({ key: 'biogas' }), // Biogas
            new FormOption({ key: 'biowaste' }), // Bioabfälle
            new FormOption({ key: 'biodiesel' }), // Biodiesel
            new FormOption({ key: 'slurry' }), // Gülle
            new FormOption({ key: 'plantOil' }), // Pflanzenöl
            new FormOption({ key: 'woodGas' }), // Holzgas
            new FormOption({ key: 'mineGas' }), // Grubengas
            new FormOption({ key: 'sewageGas' }), // Klärgas
            new FormOption({ key: 'landfillGas' }), // Deponiegas
            new FormOption({ key: 'geothermics' }), // Geothermie
            new FormOption({ key: 'naturalGas' }), // Erdgas
            new FormOption({ key: 'liquidGas' }), // Flüssiggas
            new FormOption({ key: 'dieselOil' }), // Dieselöl
        ],
        validators: {
            required,
        },
    }),
    plantType: new SelectField({
        options: [
            new FormOption({ key: 'photovoltaicPowerPlant' }), // Solaranlage
            new FormOption({ key: 'windPowerPlant' }), // Windenergieanlage
            new FormOption({ key: 'hydroPowerPlant' }), // Wasserkraftanlage
            new FormOption({ key: 'steamTurbine' }), // Wasserdampfturbine
            new FormOption({ key: 'thermalPowerPlantDea' }), // Blockheizkraftwerk DEA
            new FormOption({ key: 'thermalPowerPlantKwk' }), // Blockheizkraftwerk KWK
            new FormOption({ key: 'gasTurbine' }), // Gasturbine
            new FormOption({ key: 'fuelCell' }), // Brennstoffzelle
            new FormOption({ key: 'orcPowerPlant' }), // ORC-Anlage
            new FormOption({ key: 'emergencyGenerator' }), // Notstromaggregat > 100 ms
            new FormOption({ key: 'storage' }), // Speicher
            new FormOption({ key: 'stirlingEngine' }), // Stirling-Motor
        ],
        validators: {
            required,
        },
    }),
    plantStructureType: new RadioButtonsField({
        value: 'building',
        options: [
            new FormOption({ key: 'building' }),
            new FormOption({ key: 'noiseBarrier' }),
            new FormOption({ key: 'openSpacePlant' }),
        ],
        validators: {
            required,
        },
    }),
    totalPlantPerformance: new RadioButtonsField({
        value: 'below300',
        options: [
            new FormOption({ key: 'below300' }),
            new FormOption({ key: 'above300' }),
        ],
        validators: {
            required,
        },
    }),
    requestType: new RadioButtonsField({
        value: 'requestWithoutPlanning',
        options: [
            new FormOption({ key: 'requestWithoutPlanning' }),
            new FormOption({ key: 'requestWithPlanning' }),
        ],
        validators: {
            required,
        },
    }),
    requestWithPlanningInfo: new FormElement(),
    requestWithoutPlanningInfo: new FormElement(),
    emergencyGeneratorOperatingMode: new CheckboxesField({
        options: [
            new FormOption({ key: 'testOperation' }), // Probebetrieb
            new FormOption({ key: 'referenceTipCover' }), // Bezugsspitzenabdeckung
            new FormOption({ key: 'controlEnergyMarket' }), // Regelenergiemarkt
            new FormOption({ key: 'other' }), // Sonstiges
        ],
        validators: {
            required,
        },
    }),
    specifiedEmergencyGeneratorOperatingMode: new TextField({
        validators: {
            required,
        },
    }),
    plugInGeneratingPlant: new CheckboxField(),
    simplifiedRegistrationProcessNotice: new FormElement(),
    proceedWithFullRegistrationForEEGCompensation: new CheckboxField({
        validators: {
            checked,
        },
    }),
    simplifiedProcedureHint: new FormElement(),
    bidirectionalMeter: new CheckboxField(),
    bidirectionalMeterInfo: new FormElement(),
    consumption: new TextField({
        validators: {
            required,
            integer,
            positive,
        },
    }),
    infeed: new TextField({
        validators: {
            required,
            integer,
            positive,
        },
    }),
    readingDate: new DateField({
        availableEnd: moment().toDate(),
        validators: {
            requiredDate,
            maxDate: maxDate(moment().format('DD.MM.YYYY')),
        },
    }),
    plugInGeneratingPlantInfo: new FormElement(),
});
