import { Form } from '../form';
import { WithOptional } from '../utility';
import { KitchenSinkFormStep1 } from './kitchensinkFormStep1';
import { KitchenSinkFormStep2 } from './kitchensinkFormStep2';
import { KitchenSinkFormStep3 } from './kitchensinkFormStep3';
import { KitchenSinkFormStep4 } from './kitchensinkFormStep4';

type ConfigProps = WithOptional<KitchenSinkForm, keyof Form>;

export class KitchenSinkForm extends Form {
    public readonly applicationId: string;
    public readonly step1: KitchenSinkFormStep1;
    public readonly step2: KitchenSinkFormStep2;
    public readonly step3: KitchenSinkFormStep3;
    public readonly step4: KitchenSinkFormStep4;

    public constructor(config: ConfigProps) {
        super(Object.assign(config) as Form);

        this.applicationId = config.applicationId;
        this.step1 = config.step1;
        this.step2 = config.step2;
        this.step3 = config.step3;
        this.step4 = config.step4;
    }
}
