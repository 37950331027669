import { LocationFormStep } from '@/types/forms/formSteps/locationFormStep';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import { isPv, isPluginGeneratingPlant } from './common';
import { toggleRequiredValidator } from '@/utilities/toggleRequiredValidator';
import Vue from 'vue';

export const locationRules = async (
    formStep: {
        model: LocationFormStep;
        formModel: ApplicationForm;
    } & Vue,
): Promise<void> => {
    const isPvButNoPluginGeneratingPlant =
        !isPluginGeneratingPlant(formStep.formModel) &&
        isPv(formStep.formModel);

    // we have to wait one additional tick for the component watchers
    // to set the overall sums to the underlying models
    await Vue.nextTick();

    formStep.model.proceedWithCadastral.disabled = isPluginGeneratingPlant(
        formStep.formModel,
    );

    const proceedWithCadastral: boolean =
        !formStep.model.proceedWithCadastral.disabled &&
        formStep.model.proceedWithCadastral.value === true; // can also be null, that's why to check against true specifically

    toggleRequiredValidator(
        formStep.model.streetAndHousenumber.street,
        !proceedWithCadastral,
    );
    toggleRequiredValidator(
        formStep.model.streetAndHousenumber.housenumber,
        !proceedWithCadastral,
    );

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565594/Formular+Standort#FormularStandort-subdistrict
    formStep.model.subdistrict.disabled = !proceedWithCadastral;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565594/Formular+Standort#FormularStandort-meadow
    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565594/Formular+Standort#FormularStandort-lot
    formStep.model.meadowAndLot.disabled = !proceedWithCadastral;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565594/Formular+Standort#FormularStandort-building
    formStep.model.building.disabled = isPluginGeneratingPlant(
        formStep.formModel,
    );

    formStep.model.geographicMap.disabled =
        isPluginGeneratingPlant(formStep.formModel) ||
        !isPv(formStep.formModel);

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565594/Formular+Standort#FormularStandort-geographicLatCoordinates
    formStep.model.geographicLatCoordinates.disabled = isPluginGeneratingPlant(
        formStep.formModel,
    );

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565594/Formular+Standort#FormularStandort-geographicLngCoordinates
    formStep.model.geographicLngCoordinates.disabled = isPluginGeneratingPlant(
        formStep.formModel,
    );

    toggleRequiredValidator(
        formStep.model.geographicLatCoordinates.seconds,
        isPvButNoPluginGeneratingPlant,
    );
    toggleRequiredValidator(
        formStep.model.geographicLatCoordinates.degrees,
        isPvButNoPluginGeneratingPlant,
    );
    toggleRequiredValidator(
        formStep.model.geographicLatCoordinates.minutes,
        isPvButNoPluginGeneratingPlant,
    );

    toggleRequiredValidator(
        formStep.model.geographicLngCoordinates.seconds,
        isPvButNoPluginGeneratingPlant,
    );
    toggleRequiredValidator(
        formStep.model.geographicLngCoordinates.degrees,
        isPvButNoPluginGeneratingPlant,
    );
    toggleRequiredValidator(
        formStep.model.geographicLngCoordinates.minutes,
        isPvButNoPluginGeneratingPlant,
    );
};
