import { UploadedFileInfo } from '@/api/interfaces/file/uploadedFileInfo';
import { appConfiguration } from '@/services/form/appConfiguration';
import { helpers } from 'vuelidate/lib/validators';

// maximum file size in bytes
export const maxFileSize = (value: UploadedFileInfo): boolean => {
    if (value?.scId) {
        const maxSize =
            appConfiguration?.fileUploadConfigurations[value.scId]?.maxFileSize;
        return !helpers.req(value) || maxSize >= value.size;
    }
    return true;
};
