import { PlantOperatorFormStep } from '@/types/forms/formSteps/plantOperatorFormStep';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import { isPluginGeneratingPlant } from './common';

export const plantOperatorRules = async (
    formStep: {
        model: PlantOperatorFormStep;
        formModel: ApplicationForm;
    } & Vue,
): Promise<void> => {
    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854570380/Formular+Anlagenbetreiber#FormularAnlagenbetreiber-plantBuilder
    formStep.model.plantBuilder.disabled = !isPluginGeneratingPlant(
        formStep.formModel,
    );

    formStep.model.separatePropertyOwner.disabled = isPluginGeneratingPlant(
        formStep.formModel,
    );

    formStep.model.operators.items.forEach((operator) => {
        // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854570380/Formular+Anlagenbetreiber#FormularAnlagenbetreiber-companyName
        operator.companyName.disabled = operator.salutation.value !== 'company';

        // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854570380/Formular+Anlagenbetreiber#FormularAnlagenbetreiber-operatorName.firstName
        // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854570380/Formular+Anlagenbetreiber#FormularAnlagenbetreiber-operatorName.lastName
        operator.operatorName.disabled =
            operator.salutation.value === 'company';

        // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854570380/Formular+Anlagenbetreiber#FormularAnlagenbetreiber-contactPersonName.firstName
        // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854570380/Formular+Anlagenbetreiber#FormularAnlagenbetreiber-contactPersonName.lastName
        operator.contactPersonName.disabled =
            operator.salutation.value !== 'company';

        // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854570380/Formular+Anlagenbetreiber#FormularAnlagenbetreiber-birthday
        operator.birthday.disabled = operator.salutation.value === 'company';
    });

    const usePlantLocation =
        (!formStep.model.usePlantLocation.disabled &&
            formStep.model.usePlantLocation.value) ??
        false;
    formStep.model.zipAndCity.disabled = usePlantLocation;
    formStep.model.streetAndHousenumber.disabled = usePlantLocation;
};
