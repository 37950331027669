import { helpers, ValidationRule } from 'vuelidate/lib/validators';
import moment from 'moment';
import { germanDateFormat } from '@/utilities/germanFormats';
import { DateRange } from '@/types/forms/shared/dateRange';

/*
 * Determines whether the given string can be represented as a date. Moment js is used
 here to parse the string in German format since the JS Date Object handles
 german dates quite poorly. The moment() function accepts the string and format and
 will internally create a Date object. If this fails in anyway, it will not match
 the given value.
 https://momentjs.com/docs/#/use-it/
*/

export const date = ((value: string | null | DateRange): boolean => {
    if (typeof value === 'string' || value == null) {
        return (
            !helpers.req(value) ||
            moment(value, germanDateFormat).format(germanDateFormat) === value
        );
    } else {
        return (
            (!helpers.req(value.start) ||
                moment(value?.start, germanDateFormat).format(
                    germanDateFormat,
                ) === value?.start) &&
            (!helpers.req(value.end) ||
                moment(value.end, germanDateFormat).format(germanDateFormat) ===
                    value.end)
        );
    }
}) as ValidationRule;
