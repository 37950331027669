import { required, integer } from 'vuelidate/lib/validators';
import { checked } from '@/validators/checked';
import { positive } from '@/validators/positive';
import { minDate } from '@/validators/minDate';
import { maxDate } from '@/validators/maxDate';
import { inDateRange } from '@/validators/inDateRange';
import { fileType } from '@/validators/fileType';
import { requiredDate } from '@/validators/requiredDate';
import { maxFileSize } from '@/validators/maxFileSize';
import { FormElement } from '@/types/forms/formElement';
import { FormOption } from '@/types/forms/formOption';
import { TextField } from '@/types/forms/fields/textField';
import { CheckboxField } from '@/types/forms/fields/checkboxField';
import {
    GeoCoordinatesFormGroup,
    GeoOrientation,
} from '@/types/forms/fields/geoCoordinatesFormGroup';
import { SelectField } from '@/types/forms/fields/selectField';
import { UploadField } from '@/types/forms/fields/uploadField';
import { DateField } from '@/types/forms/fields/dateField';
import { KitchenSinkFormStep1 } from '@/types/forms/dummy/kitchensinkFormStep1';
import { CountField } from '@/types/forms/fields/countField';
import { DateRangeField } from '@/types/forms/fields/dateRangeField';

export const kitchenSinkFormStep1 = new KitchenSinkFormStep1({
    textBlock: new FormElement(),
    requiredField: new TextField({
        validators: {
            required,
        },
    }),
    multiLineField: new TextField({
        validators: {
            required,
        },
    }),
    numberField: new TextField({
        validators: {
            required,
            integer,
            positive,
        },
    }),
    wideField: new TextField({
        validators: {
            required,
        },
    }),
    innerLabelField: new TextField(),
    geoCoordinatesField: new GeoCoordinatesFormGroup({
        orientation: GeoOrientation.longitude,
    }),
    checkboxField1: new CheckboxField({
        validators: {
            checked,
        },
    }),
    checkboxField2: new CheckboxField({
        validators: {
            checked,
        },
    }),
    selectField: new SelectField({
        options: [
            new FormOption({ key: 'opt1' }),
            new FormOption({ key: 'opt2' }),
            new FormOption({ key: 'opt3' }),
        ],
        validators: {
            required,
        },
    }),
    selectFieldSingleOption: new SelectField({
        options: [new FormOption({ key: 'opt1' })],
        validators: {
            required,
        },
    }),
    selectFieldSearch: new SelectField({
        options: [
            new FormOption({ key: 'opt1' }),
            new FormOption({ key: 'opt2' }),
            new FormOption({ key: 'opt3' }),
            new FormOption({ key: 'opt4' }),
            new FormOption({ key: 'opt5' }),
            new FormOption({ key: 'opt6' }),
            new FormOption({ key: 'opt7' }),
            new FormOption({ key: 'opt8' }),
            new FormOption({ key: 'opt9' }),
        ],
        validators: {
            required,
        },
    }),
    selectFieldMsb: new SelectField({
        options: [],
        validators: {
            required,
        },
    }),
    fileUpload: new UploadField({
        validators: {
            required,
            fileType,
            maxFileSize,
        },
    }),
    datePickerField: new DateField({
        // value: '12.12.2020',
        availableStart: new Date(2020, 11, 1),
        availableEnd: new Date(2020, 11, 31),
        validators: {
            requiredDate,
            minDate: minDate('01.12.2020'),
            maxDate: maxDate('31.12.2020'),
            inDateRange: inDateRange('01.12.2020', '31.12.2020'),
        },
    }),
    datePickerRangeField: new DateRangeField({
        // value: {
        //     start: '06.04.2024',
        //     end: '10.04.2024',
        // },
        validators: {
            requiredDate,
            minDate: minDate('01.12.2020'),
            maxDate: maxDate('31.12.2020'),
            inDateRange: inDateRange('01.12.2020', '31.12.2020'),
        },
    }),
    countField: new CountField({
        validators: {
            required,
        },
    }),
    documentDownload: new FormElement(),
    formButton: new FormElement(),
});
