import { SelectField } from '@/types/forms/fields/selectField';
import { MeasuringConceptsFormStep } from '@/types/forms/formSteps/measuringConceptsFormStep';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import { isPluginGeneratingPlant, isStorage } from './common';
import { ValidationProperties, ValidationEvaluation } from 'vue/types/vue';
import { Validation } from 'vuelidate';

export const measuringConceptsRules = async (
    formStep: {
        model: MeasuringConceptsFormStep;
        formModel: ApplicationForm;
        validations:
            | (Validation &
                  ValidationProperties<unknown> &
                  ValidationEvaluation)
            | undefined;
    } & Vue,
): Promise<void> => {
    const zeroInfeed = formStep.model.operatingMode.options.find(
        (o) => o.key === 'zeroInfeed',
    );

    const commercialTransfer = formStep.model.operatingMode.options.find(
        (o) => o.key === 'commercialTransfer',
    );

    if (zeroInfeed) {
        zeroInfeed.disabled = true;
    }

    if (commercialTransfer) {
        commercialTransfer.disabled =
            isStorage(formStep.formModel) ||
            isPluginGeneratingPlant(formStep.formModel);
    }

    if (isStorage(formStep.formModel)) {
        formStep.model.operatingMode.options.forEach(
            (o) => (o.disabled = true),
        );

        const storageDischarge =
            formStep.formModel.plantData.storageDischarge.value;

        switch (storageDischarge) {
            case 'onlyIntoCustomersGridSelfSupply':
                SelectField.preselectExactlyOneOption(
                    formStep.model.operatingMode,
                    'zeroInfeed',
                );
                break;
            case 'intoCustomersGridAndPublicGridBivalent':
                SelectField.preselectExactlyOneOption(
                    formStep.model.operatingMode,
                    'surplusInfeed',
                );
                break;
            case 'onlyIntoPublicGridGridFeedIn':
                SelectField.preselectExactlyOneOption(
                    formStep.model.operatingMode,
                    'fullInfeed',
                );
                break;
        }
    }

    if (isPluginGeneratingPlant(formStep.formModel)) {
        SelectField.preselectExactlyOneOption(
            formStep.model.operatingMode,
            'surplusInfeed',
        );
    }

    formStep.model.fullInfeedBonus.disabled =
        formStep.formModel.applicationType !== 'photovoltaic' ||
        formStep.model.operatingMode.value !== 'fullInfeed' ||
        (formStep.formModel.generationType.plantStructureType.value !==
            'building' &&
            formStep.formModel.generationType.plantStructureType.value !==
                'noiseBarrier');

    formStep.model.fullInfeedBonusHeadline.disabled =
        formStep.model.fullInfeedBonus.disabled;

    formStep.model.measuringDeviceStorage.disabled = !isStorage(
        formStep.formModel,
    );

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565613/Formular+Erzeugungsart#FormularErzeugungsart-meterNumber
    formStep.model.meterNumber.disabled =
        formStep.formModel.generationType.plugInGeneratingPlant.disabled ||
        !formStep.formModel.generationType.plugInGeneratingPlant.value;

    formStep.model.measuringPointOperation.disabled =
        isStorage(formStep.formModel) &&
        !formStep.model.measuringDeviceStorage.value;

    formStep.model.measuringPointOperator.disabled =
        formStep.model.measuringPointOperation.disabled ||
        formStep.model.measuringPointOperation.value !== 'other';

    formStep.model.priceSheetMeasuringPointOperation.disabled =
        formStep.formModel.applicationType === 'storage' &&
        !formStep.model.measuringDeviceStorage.value;
};
