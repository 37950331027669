import { FormField } from '../../../fields/formField';
import { FormElementConfig } from '../../../formElement';
import { FormGroup } from '../../../formGroup';
import { QuantityAndNominalPowerFormGroup } from './quantityAndNominalPowerFormGroup';
import { ReferenceFormGroup } from '../../referenceFormGroup';

export type PhotovoltaicModuleFormGroupConfig = FormElementConfig &
    Omit<PhotovoltaicModuleFormGroup, keyof FormGroup>;

export class PhotovoltaicModuleFormGroup extends ReferenceFormGroup {
    public readonly pvQuantityAndNominalPower: QuantityAndNominalPowerFormGroup;
    public readonly pvTotalNominalPower: FormField<number>;

    public constructor(config: PhotovoltaicModuleFormGroupConfig) {
        super(config);

        this.pvQuantityAndNominalPower = config.pvQuantityAndNominalPower;
        this.pvTotalNominalPower = config.pvTotalNominalPower;
    }
}
