import { helpers, ValidationRule } from 'vuelidate/lib/validators';
import moment from 'moment';
import { germanDateFormat } from '@/utilities/germanFormats';
import { date } from './date';
import { DateRange } from '@/types/forms/shared/dateRange';

/*
 * Determines whether the given value is less than the given maxDate. Moment js is used
 here to parse the string in German format. The moment() function accepts the string and format and
 will internally create a Date object. The chained isSameOrBefore function performs the comparison.
 https://momentjs.com/docs/#/use-it/
*/

export const maxDate = (maxDate: string): ValidationRule => {
    return helpers.withParams(
        { type: 'maxDate', maxDate },
        (value: string | null | DateRange) => {
            const validateValue =
                typeof value === 'string' || value == null ? value : value.end;
            return (
                !!maxDate &&
                (!helpers.req(validateValue) ||
                    !date(validateValue) ||
                    moment(
                        validateValue,
                        germanDateFormat,
                        true,
                    ).isSameOrBefore(moment(maxDate, germanDateFormat, true)))
            );
        },
    );
};
