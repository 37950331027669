import { TextField } from '../fields/textField';
import { FormGroup } from '../formGroup';
import { WithOptional } from '../utility';

type ConfigProps = WithOptional<ZipAndCityFormGroup, keyof FormGroup>;

export class ZipAndCityFormGroup extends FormGroup {
    public readonly zip: TextField;
    public readonly city: TextField;

    public constructor(config: ConfigProps) {
        super(config);

        this.zip = config.zip;
        this.city = config.city;
    }
}
