import Vue from 'vue';
import Vuelidate from 'vuelidate';
import { Validation } from 'vuelidate';
import { RuleDecl } from 'vue/types/options';
import { ValidationGroups, ValidationProperties } from 'vue/types/vue';
import { mapValues as _mapValues } from 'lodash';
import { Form } from '@/types/forms/form';
import { FormGroup } from '@/types/forms/formGroup';
import { FormElement, FormElementConfig } from '@/types/forms/formElement';
import { FormField } from '@/types/forms/fields/formField';
import { Forms } from '@/types/forms/forms';
import { FormStep } from '@/types/forms/formStep';
import { cloneDeep as _cloneDeep } from 'lodash';
import { FormList } from '@/types/forms/fields/formList';

type Validations = { [identifier: string]: Validations | RuleDecl };

/**
 * Walks through FormGroups of this FormStep / FormGroup and returns the validators from the FormElements.
 * Recursively calls itself to handle sub-FormGroups.
 * @param disabled Needed to recursively disable FormGroups.
 */
function getFormGroupAndElementValidations(
    formGroup: FormGroup,
    disabled = false,
): Validations {
    const ret = {} as Validations;
    for (const prop in formGroup) {
        if (Object.prototype.hasOwnProperty.call(formGroup, prop)) {
            const formGroupProp = formGroup[prop as keyof FormGroup];
            if (formGroupProp instanceof FormList) {
                ret[prop] = {};
                formGroupProp.items.forEach((item, index) => {
                    // don't use $each here so items can have different validators
                    ret[prop][index] = {
                        ...formGroupProp.validators,
                        ...getFormGroupAndElementValidations(
                            item,
                            disabled || formGroupProp.disabled,
                        ),
                    };
                });
            } else if (formGroupProp instanceof FormGroup) {
                ret[prop] = {
                    ...formGroupProp.validators,
                    ...getFormGroupAndElementValidations(
                        formGroupProp,
                        disabled || formGroupProp.disabled,
                    ),
                };
            } else if (formGroupProp instanceof FormElement) {
                if (disabled || formGroupProp.disabled) {
                    ret[prop] = {};
                } else {
                    ret[prop] = formGroupProp.validators;
                }
            }
        }
    }
    return ret;
}

/**
 * Walks through the form, calls "getFormGroupAndElementValidations" for each FormStep to get the validators from the FormElements.
 */
function getFormValidations(form: Form): Validations {
    const ret = {} as Validations;
    for (const prop in form) {
        if (Object.prototype.hasOwnProperty.call(form, prop)) {
            const formProp = form[prop as keyof Form] as unknown;
            if (formProp instanceof FormStep) {
                ret[prop] = getFormGroupAndElementValidations(
                    formProp,
                    formProp.disabled,
                );
            }
        }
    }
    return ret;
}

/**
 * Main method to get the vuelidate validators for a formsConfig-like object.
 */
function getValidations(forms: Forms): Validations {
    return _mapValues(forms, getFormValidations);
}

type Values = { [identifier: string]: Values | unknown };

/**
 * Walks through FormGroups of this FormStep / FormGroup and returns the values from the FormFields.
 * Recursively calls itself to handle sub-FormGroups.
 */
function getFormGroupAndElementValues(formGroup: FormGroup): Values {
    const ret = {} as Values;
    for (const prop in formGroup) {
        if (Object.prototype.hasOwnProperty.call(formGroup, prop)) {
            const formGroupProp = formGroup[prop as keyof FormGroup];

            if (formGroupProp instanceof FormElement) {
                ret[prop] = getFormElementValues(formGroupProp);
            }
        }
    }
    return ret;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
function getFormListValues<
    T extends FormElement,
    ItemConfig extends FormElementConfig
>(formList: FormList<T, ItemConfig>): (Values | unknown)[] {
    return formList.items.map((item) => getFormElementValues(item));
}

function getFormElementValues(
    formElement: FormElement,
): Values | unknown | (Values | unknown)[] {
    if (formElement instanceof FormList) {
        return getFormListValues(formElement);
    } else if (formElement instanceof FormGroup) {
        return getFormGroupAndElementValues(formElement);
    } else if (formElement instanceof FormField) {
        return formElement.value;
    }
}

/**
 * Walks through the form, calls "getFormGroupAndElementValues" for each FormStep to get the values from the FormFields.
 */
function getFormValues(form: Form): Values {
    const ret = {} as Values;
    for (const prop in form) {
        if (Object.prototype.hasOwnProperty.call(form, prop)) {
            const formProp = form[prop as keyof Form] as unknown;
            if (formProp instanceof FormStep) {
                ret[prop] = getFormGroupAndElementValues(formProp);
            }
        }
    }

    return ret;
}

/**
 * Main method to get the vuelidate values for a formsConfig-like object.
 */
function getValues(forms: Forms): Values {
    return _mapValues(forms, getFormValues);
}

Vue.use(Vuelidate);

export function getValidationsClone(
    cloneSource: Forms,
    formReference?: Vue,
): ValidationProperties<Vue> & ValidationGroups & Validation {
    return createFormValidations(_cloneDeep(cloneSource), formReference);
}

function createValidations<TModel>(
    model: TModel,
    getValidations: (model: TModel) => Validations,
    getValues: (model: TModel) => Values,
    formReference?: Vue,
): ValidationProperties<Vue> & ValidationGroups & Validation {
    const extendedValidationComponent = Vue.extend({
        data() {
            return {
                model,
                modelValidations: {} as Validations,
            };
        },
        computed: {
            formValidations(): Validations {
                return getValidations(this.model);
            },
            values(): Values {
                return getValues(this.model);
            },
            formReference(): Vue | undefined {
                return formReference;
            },
        },
        watch: {
            async formValidations(): Promise<void> {
                await this.$nextTick;
                this.$v.$reset();
            },
            model: {
                // watch model instead of use computed property
                // so we can react on changes on array properties
                handler(newValue: TModel) {
                    if (newValue != null) {
                        this.modelValidations = getValidations(newValue);
                    }
                },
                deep: true,
                immediate: true,
            },
        },
        validations(): RuleDecl {
            return {
                values: this.modelValidations,
            };
        },
    });
    const validationComponent = new extendedValidationComponent();
    return validationComponent.$v.values as ValidationProperties<Vue> &
        ValidationGroups &
        Validation;
}

export function createFormValidations(
    model: Forms,
    formReference?: Vue,
): ValidationProperties<Vue> & ValidationGroups & Validation {
    return createValidations(model, getValidations, getValues, formReference);
}

export function createFormGroupValidations(
    model: FormGroup,
): ValidationProperties<Vue> & ValidationGroups & Validation {
    return createValidations(
        model,
        getFormGroupAndElementValidations,
        getFormGroupAndElementValues,
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ValidationsValueType = Validation & ValidationProperties<any>;

export type ValidationsCommonType = Validation &
    ValidationGroups &
    ValidationProperties<unknown>;
