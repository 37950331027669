import { required, integer, maxLength } from 'vuelidate/lib/validators';
import { positive } from '@/validators/positive';
import { houseNumber } from '@/validators/houseNumber';
import { FormElement } from '@/types/forms/formElement';
import { TextField } from '@/types/forms/fields/textField';
import { CheckboxField } from '@/types/forms/fields/checkboxField';
import {
    GeoCoordinatesFormGroup,
    GeoOrientation,
} from '@/types/forms/fields/geoCoordinatesFormGroup';
import { LocationFormStep } from '@/types/forms/formSteps/locationFormStep';
import { ZipAndCityFormGroup } from '@/types/forms/formGroups/zipAndCityFormGroup';
import { StreetAndHousenumberFormGroup } from '@/types/forms/formGroups/streetAndHousenumberFormGroup';
import { MeadowAndLotFormGroup } from '@/types/forms/formGroups/meadowAndLotFormGroup';
import { addressRequiredIfNoCadastral } from '@/validators/addressRequiredIfNoCadastral';
import { AddressCheckFeedbackFormGroup } from '@/types/forms/formGroups/electricityUnavailableAtAddressFormGroup';
import { FormField } from '@/types/forms/fields/formField';
import { AddressesApiAvailabilityResult } from '@/types/forms/shared/addressesApi/addressesApiAvailabilityResult';
import { length } from '@/validators/length';

export const locationFormStep = new LocationFormStep({
    stepIntro: new FormElement(),
    zipAndCity: new ZipAndCityFormGroup({
        zip: new TextField({
            validators: {
                required,
                integer,
                positive,
                length: length(5),
            },
        }),
        city: new TextField({
            validators: {
                required,
            },
        }),
    }),
    streetAndHousenumber: new StreetAndHousenumberFormGroup({
        street: new TextField({
            validators: {
                required,
            },
        }),
        housenumber: new TextField({
            validators: {
                required,
                houseNumber,
                maxLength: maxLength(8),
            },
        }),
    }),
    availabilityResult: new FormField<AddressesApiAvailabilityResult>({
        validators: {
            addressRequiredIfNoCadastral,
        },
    }),
    addressCheckFeedback: new AddressCheckFeedbackFormGroup({
        addressCheckFeedbackCheckingHeadline: new FormElement(),
        addressCheckFeedbackAvailableHeadline: new FormElement(),
        addressCheckFeedbackEnterAddressHeadline: new FormElement(),
        addressCheckFeedbackUnavailableHeadline: new FormElement(),
        addressCheckFeedbackUnavailableInfoText: new FormElement(),
    }),
    proceedWithCadastral: new CheckboxField(),
    subdistrict: new TextField({
        validators: {
            required,
        },
    }),
    meadowAndLot: new MeadowAndLotFormGroup({
        meadow: new TextField({
            validators: {
                required,
                integer,
                positive,
            },
        }),
        lot: new TextField({
            validators: {
                required,
            },
        }),
    }),
    building: new TextField(),
    geographicMap: new FormElement(),
    mapHeadline: new FormElement(),
    mapHint: new FormElement(),
    mapConsentHeadline: new FormElement(),
    mapConsentText: new FormElement(),
    mapConsentHint: new FormElement(),
    geographicLatCoordinates: new GeoCoordinatesFormGroup({
        orientation: GeoOrientation.latitude,
    }),
    geographicLngCoordinates: new GeoCoordinatesFormGroup({
        orientation: GeoOrientation.longitude,
    }),
});
