import { JSONSchema7Definition } from 'json-schema';
import { defaults as _defaults } from 'lodash';
import { FormOption } from '../formOption';
import {
    FormField,
    FormFieldConfig,
    formFieldDefaultConfig,
} from './formField';

export interface FormFieldWithOptionsConfig<TValue, TOptionKey>
    extends FormFieldConfig<TValue> {
    options: FormOption<TOptionKey>[];
}

export const formFieldWithOptionsDefaultConfig = {
    ...formFieldDefaultConfig,
    options: [],
};

// eslint-disable-next-line @typescript-eslint/comma-dangle
export class FormFieldWithOptions<
    TValue,
    TOptionKey
> extends FormField<TValue | null> {
    public options: FormOption<TOptionKey>[];

    public constructor(config: FormFieldWithOptionsConfig<TValue, TOptionKey>) {
        const resolvedConfig = _defaults(
            config || ({} as FormFieldWithOptionsConfig<TValue, TOptionKey>),
            formFieldWithOptionsDefaultConfig,
        );

        super(resolvedConfig);
        this.options = resolvedConfig.options;
    }

    public getJsonSchema(): JSONSchema7Definition {
        // the type of the value is not specified
        return {};
    }
}
