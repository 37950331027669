import { alwaysInDtoAttribute } from '@/types/forms/alwaysinDtoAttribute';
import { FormField } from '../../../fields/formField';
import { FormElement } from '../../../formElement';
import { FormGroup } from '../../../formGroup';
import { WithOptional } from '../../../utility';
import { StorageModuleFormList } from './storageModuleFormList';

type ConfigProps = WithOptional<StorageFormGroup, keyof FormGroup>;

export class StorageFormGroup extends FormGroup {
    public readonly storageHeadline: FormElement;
    public readonly storageModules: StorageModuleFormList;
    @alwaysInDtoAttribute()
    public readonly storageOverallDischargePower: FormField<number>;
    @alwaysInDtoAttribute()
    public readonly storageOverallStorageCapacity: FormField<number>;

    public constructor(config: ConfigProps) {
        super(config);

        this.storageHeadline = config.storageHeadline;
        this.storageModules = config.storageModules;
        this.storageOverallDischargePower = config.storageOverallDischargePower;
        this.storageOverallStorageCapacity =
            config.storageOverallStorageCapacity;
    }
}
