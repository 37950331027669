import { helpers, ValidationRule } from 'vuelidate/lib/validators';
import { get as _get } from 'lodash';
import { SelectField } from '@/types/forms/fields/selectField';
import { CountryValueType } from '@/types/forms/shared/countryValueType';
import { Forms } from '@/types/forms/forms';

interface MockFormModel {
    model: Forms;
}

export function zip(modelCountryPath: string): ValidationRule {
    return helpers.withParams({ type: 'modelCountryPath' }, function (
        this: MockFormModel,
        value: number | string,
    ): boolean {
        let localModelCountryPath = modelCountryPath;
        if (!helpers.req(value)) {
            return true;
        }

        const normalizedValue = `${value}`;

        if (localModelCountryPath.indexOf('[*]') >= 0) {
            // Path contains index. That means we have a form list, probably the propertyOwner case.
            // So now we have to determine the correct index, by matching the zip:
            const matches = /(.+\.(.+))\[\*\]\..+/g.exec(localModelCountryPath);
            if (matches?.length == 3) {
                // const fullPath = matches[0];
                const subPath = matches[1];
                const listElementName = matches[2];
                const listElements = _get(this.model, subPath);
                if (listElements) {
                    for (const listElementIndex in listElements) {
                        const listElement = listElements[listElementIndex];
                        const listElementZip =
                            listElement?.zipAndCity?.zip?.value;
                        if (listElementZip == normalizedValue) {
                            // Found the correct index
                            // (There is an edge-case, where zips in both owners are identical, but country differs.
                            //  There is no solution for that. Shouldn't be an actual issue, though.)
                            localModelCountryPath = localModelCountryPath.replace(
                                `${listElementName}[*]`,
                                `${listElementName}[${listElementIndex}]`,
                            );
                            break;
                        }
                    }
                }
            }
        }

        const country = (_get(this.model, localModelCountryPath) as SelectField<
            CountryValueType
        >)?.value;
        let regex = /.*/g;

        switch (country) {
            case 'deu' /* Deutschland */:
            case 'fra' /* Frankreich */:
                regex = /^(\d{5})$/g;
                break;
            case 'aut' /* Österreich */:
            case 'dnk' /* Dänemark */:
            case 'che' /* Schweiz */:
                regex = /^(\d{4})$/g;
                break;
            case 'nld' /* Niederlande */:
                regex = /^(\d{4}\s?[a-zA-Z]{2})$/g;
                break;
            case 'pol' /* Polen */:
                regex = /^(\d{2}-\d{3})$/g;
                break;
        }

        const result = new RegExp(regex).test(normalizedValue);
        return result;
    });
}
