import { isPv } from '@/services/form/rules/application/common';
import { Forms } from '@/types/forms/forms';
import { parseGermanFloat } from '@/utilities/germanFormats';
import { CombinedVueInstance } from 'vue/types/vue';
import { ValidationRule } from 'vuelidate/lib/validators';

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const mustBeNoMoreThan20PercentAboveSuggestion = function <
    Instance extends Vue
>(
    this: CombinedVueInstance<
        Instance,
        { model: Forms },
        unknown,
        unknown,
        unknown
    >,
    value: string,
) {
    const pv = isPv(this.model.application);
    if (!pv) {
        return true;
    }
    const PV_OVERALLNOMINALPOWER_TO_SELFGENERATIONQUANTITY_FACTOR = 850;
    const selfGenerationQuantitySuggestion =
        (this.model.application.plantPerformance.photovoltaic
            .pvOverallNominalPower.value ?? 0) *
        PV_OVERALLNOMINALPOWER_TO_SELFGENERATIONQUANTITY_FACTOR;

    const val = parseGermanFloat(value || '0');
    return val <= selfGenerationQuantitySuggestion * 1.2;
} as ValidationRule;
