import { CheckboxField } from '@/types/forms/fields/checkboxField';
import { RadioButtonsField } from '@/types/forms/fields/radioButtonsField';
import { FormElement } from '@/types/forms/formElement';
import { FormOption } from '@/types/forms/formOption';
import { ControllableConsumptionDevicesFormStep } from '@/types/forms/formSteps/controllableConsumptionDevicesFormStep';
import { checked } from '@/validators/checked';
import { required } from 'vuelidate/lib/validators';

export const controllableConsumptionDevicesFormStep = new ControllableConsumptionDevicesFormStep(
    {
        controllableConsumptionDevicesInfo: new FormElement(),
        gridControlConditionsAccepted: new CheckboxField({
            validators: {
                checked,
            },
        }),
        gridControlImplementationPreference: new RadioButtonsField({
            value: 'directControl',
            options: [
                new FormOption({ key: 'directControl' }),
                new FormOption({ key: 'emsControl' }),
            ],
            validators: {
                required,
            },
        }),
        controllableConsumptionFacilitiesRequirementInfo: new FormElement(),
    },
);
