import { UploadField } from '@/types/forms/fields/uploadField';
import {
    DocumentsFormStep,
    GeneratorModuleFormGroup,
    InverterModuleFormGroup,
    StorageModuleFormGroup,
} from '@/types/forms/formSteps/documentsFormStep';
import { fileType } from '@/validators/fileType';
import { maxFileSize } from '@/validators/maxFileSize';
import { required } from 'vuelidate/lib/validators';
import { FormList } from '@/types/forms/fields/formList';

export const documentsFormStep = new DocumentsFormStep({
    meterPicture: new UploadField({
        validators: {
            required,
            fileType,
            maxFileSize,
        },
    }),
    bafaApproval: new UploadField({
        validators: {
            fileType,
            maxFileSize,
        },
    }),
    gridAndPlantProtectionProofOfConformity: new UploadField({
        validators: {
            fileType,
            maxFileSize,
        },
    }),
    performanceMonitoringCertificate: new UploadField({
        validators: {
            required,
            fileType,
            maxFileSize,
        },
    }),
    sitePlanWithLotNumber: new UploadField({
        validators: {
            required,
            fileType,
            maxFileSize,
        },
    }),
    electricalSystemCircuitDiagram: new UploadField({
        validators: {
            required,
            fileType,
            maxFileSize,
        },
    }),
    proofOfReadyForPlanning: new UploadField({
        validators: {
            required,
            fileType,
            maxFileSize,
        },
    }),
    generatorModules: new FormList(GeneratorModuleFormGroup, {
        initializeItems: 1,
        minItemCount: 1,
        maxItemCount: 5,
        generatorConformityConfirmationOrUnitCertificate: new UploadField({
            validators: {
                required,
                fileType,
                maxFileSize,
            },
        }),
        generatorTechnicalDataSheet: new UploadField({
            validators: {
                required,
                fileType,
                maxFileSize,
            },
        }),
        generatorProofOfConformity: new UploadField({
            validators: {
                fileType,
                maxFileSize,
            },
        }),
        generatorUnitOrPrototypeConfirmation: new UploadField({
            validators: {
                required,
                fileType,
                maxFileSize,
            },
        }),
        generatorComponentCertificate: new UploadField({
            validators: {
                fileType,
                maxFileSize,
            },
        }),
    }),
    inverterModules: new FormList(InverterModuleFormGroup, {
        initializeItems: 1,
        minItemCount: 1,
        maxItemCount: 5,
        inverterConformityConfirmationOrUnitCertificate: new UploadField({
            validators: {
                required,
                fileType,
                maxFileSize,
            },
        }),
        inverterTechnicalDataSheet: new UploadField({
            validators: {
                required,
                fileType,
                maxFileSize,
            },
        }),
        inverterProofOfConformity: new UploadField({
            validators: {
                fileType,
                maxFileSize,
            },
        }),
        inverterUnitCertificate: new UploadField({
            validators: {
                required,
                fileType,
                maxFileSize,
            },
        }),
    }),
    storageModules: new FormList(StorageModuleFormGroup, {
        initializeItems: 1,
        minItemCount: 1,
        maxItemCount: 5,
        storageConformityDeclarationStorageSystem: new UploadField({
            validators: {
                required,
                fileType,
                maxFileSize,
            },
        }),
        storageManufacturerDataSheet: new UploadField({
            validators: {
                required,
                fileType,
                maxFileSize,
            },
        }),
    }),
});
