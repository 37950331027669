import { InverterModuleFormList } from './inverterModuleFormList';
import { FormGroup } from '../../../formGroup';
import { FormField } from '../../../fields/formField';
import { FormElement } from '../../../formElement';
import { WithOptional } from '../../../utility';
import { alwaysInDtoAttribute } from '@/types/forms/alwaysinDtoAttribute';

type ConfigProps = WithOptional<InverterFormGroup, keyof FormGroup>;

export class InverterFormGroup extends FormGroup {
    public readonly inverterHeadline: FormElement;
    public readonly inverterModules: InverterModuleFormList;
    @alwaysInDtoAttribute()
    public readonly inverterOverallActivePower: FormField<number>;
    public readonly inverterOverallActivePowerWarning: FormElement;
    @alwaysInDtoAttribute()
    public readonly inverterOverallApparentPower: FormField<number>;

    public constructor(config: ConfigProps) {
        super(config);

        this.inverterHeadline = config.inverterHeadline;
        this.inverterModules = config.inverterModules;
        this.inverterOverallActivePower = config.inverterOverallActivePower;
        this.inverterOverallActivePowerWarning =
            config.inverterOverallActivePowerWarning;
        this.inverterOverallApparentPower = config.inverterOverallApparentPower;
    }
}
