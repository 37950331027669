import { gt, lt } from '@/services/floatComparison';
import { getPercentageChange } from '@/services/percentage';
import { PlantPerformanceFormStep } from '@/types/forms/formSteps/plantPerformanceFormStep';
import {
    ApplicationForm,
    ApplicationType,
} from '@/types/forms/specific/applicationForm';
import {
    formatGermanNumber,
    parseGermanFloat,
} from '@/utilities/germanFormats';

export function isOverallActivePowerWarningEnabled(
    value: number | null,
    referenceValue: number | null,
): boolean {
    if (!value || !referenceValue) {
        return false;
    }

    const percentageChange = getPercentageChange(value, referenceValue);
    const limit = 0.1;

    return gt(percentageChange, limit) || lt(percentageChange, -limit);
}

export function getOverallNominalPower(
    plantPerformanceModel: PlantPerformanceFormStep,
): number | null {
    if (!plantPerformanceModel) {
        return null;
    }

    if (
        !plantPerformanceModel.photovoltaic.disabled &&
        !plantPerformanceModel.photovoltaic.pvOverallNominalPower.disabled
    ) {
        return plantPerformanceModel.photovoltaic.pvOverallNominalPower
            .value !== null
            ? plantPerformanceModel.photovoltaic.pvOverallNominalPower.value
            : null;
    }

    if (
        !plantPerformanceModel.storage.disabled &&
        !plantPerformanceModel.storage.storageOverallDischargePower.disabled
    ) {
        return plantPerformanceModel.storage.storageOverallDischargePower
            .value !== null
            ? plantPerformanceModel.storage.storageOverallDischargePower.value
            : null;
    }

    if (
        !plantPerformanceModel.generator.disabled &&
        !plantPerformanceModel.generator.generatorOverallActivePower.disabled
    ) {
        return plantPerformanceModel.generator.generatorOverallActivePower
            .value;
    }

    return null;
}

export function getOverallActivePower(
    applicationType: ApplicationType | undefined,
    plantPerformanceModel: PlantPerformanceFormStep,
): number | null {
    if (!plantPerformanceModel) {
        return null;
    }

    if (applicationType == 'photovoltaic' || applicationType == 'storage') {
        if (
            !plantPerformanceModel.inverter.disabled &&
            !plantPerformanceModel.inverter.inverterOverallActivePower.disabled
        ) {
            return plantPerformanceModel.inverter.inverterOverallActivePower
                .value;
        }
    } else {
        if (
            !plantPerformanceModel.generator.disabled &&
            !plantPerformanceModel.generator.generatorOverallActivePower
                .disabled
        ) {
            return plantPerformanceModel.generator.generatorOverallActivePower
                .value;
        }
    }

    return null;
}

export function getOverallApparentPower(
    applicationType: ApplicationType | undefined,
    plantPerformanceModel: PlantPerformanceFormStep,
): number | null {
    if (!plantPerformanceModel) {
        return null;
    }

    if (applicationType == 'photovoltaic' || applicationType == 'storage') {
        if (
            !plantPerformanceModel.inverter.disabled &&
            !plantPerformanceModel.inverter.inverterOverallApparentPower
                .disabled
        ) {
            return plantPerformanceModel.inverter.inverterOverallApparentPower
                .value;
        }
    } else {
        if (
            !plantPerformanceModel.generator.disabled &&
            !plantPerformanceModel.generator.generatorOverallApparentPower
                .disabled
        ) {
            return plantPerformanceModel.generator.generatorOverallApparentPower
                .value;
        }
    }

    return null;
}

export function calculateSelfGenerationQuantity(
    formModel: ApplicationForm,
    editModel: ApplicationForm | null = null,
): string | null {
    const PV_OVERALLNOMINALPOWER_TO_SELFGENERATIONQUANTITY_FACTOR = 850;

    const modelSelfGenerationQuantity =
        (formModel.plantPerformance.photovoltaic.pvOverallNominalPower.value ??
            0) * PV_OVERALLNOMINALPOWER_TO_SELFGENERATIONQUANTITY_FACTOR;
    const editSelfGenerationQuantity = editModel
        ? (editModel.plantPerformance.photovoltaic.pvOverallNominalPower
              .value ?? 0) *
          PV_OVERALLNOMINALPOWER_TO_SELFGENERATIONQUANTITY_FACTOR
        : modelSelfGenerationQuantity;

    const parsedSelfGenerationQuantity = parseGermanFloat(
        formModel.measuringConcepts.selfGenerationQuantity.value ?? '',
    );

    const finalSelfGenerationQuantity =
        parsedSelfGenerationQuantity === 0 ||
        modelSelfGenerationQuantity === parsedSelfGenerationQuantity
            ? formatGermanNumber(editSelfGenerationQuantity, {
                  decimals: 2,
                  thousandsSeparator: true,
              })
            : formModel.measuringConcepts.selfGenerationQuantity.value;

    return finalSelfGenerationQuantity;
}
