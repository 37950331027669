import { required } from 'vuelidate/lib/validators';
import { FormField } from '@/types/forms/fields/formField';
import { UploadedFileInfo } from '@/api/interfaces/file/uploadedFileInfo';

export function addRequiredValidator(
    formField: FormField<unknown | UploadedFileInfo>,
): void {
    if (
        !Object.prototype.hasOwnProperty.call(formField.validators, 'required')
    ) {
        formField.validators = {
            ...formField.validators,
            required,
        };
    }
}
