import { PlantPerformanceFormStep } from '@/types/forms/formSteps/plantPerformanceFormStep';
import { PowerReductionValueType } from '@/types/forms/shared/powerReductionValueType';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import {
    isPv,
    isPowerHeatCoupling,
    isStorage,
    isDea,
    isUnknown,
    isNominalPowerBelowOrEqualTippingPoint,
    isActivePowerAboveTippingPoint,
    isActivePowerBelowOrEqualTippingPoint,
    isNonHighEfficiencyChpPlant,
    isHighEfficiencyChpPlant,
    isPluginGeneratingPlant,
    isNominalPowerAboveTippingPoint,
} from '../common';
import { findFirstValidKey } from '@/utilities/findFirstValidKey';
import { PlantDataFormStep } from '@/types/forms/formSteps/plantDataFormStep';

const isOther = (formModel: ApplicationForm) =>
    isDea(formModel) || isPowerHeatCoupling(formModel) || isUnknown(formModel);

//https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854573319/Formular+Anlagenleistung#FormularAnlagenleistung-isPvNominalPowerInRange
const isPvNominalPowerInRangeRule = function (
    model: PlantPerformanceFormStep,
    formModel: ApplicationForm,
): boolean {
    return (
        isPv(formModel) &&
        isNominalPowerAboveTippingPoint(model, 25) &&
        isNominalPowerBelowOrEqualTippingPoint(model, 100)
    );
};

//https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854573319/Formular+Anlagenleistung#FormularAnlagenleistung-isPhcActivePowerInRange
const isPhcActivePowerInRangeRule = function (
    model: PlantPerformanceFormStep,
    formModel: ApplicationForm,
): boolean {
    return (
        isPowerHeatCoupling(formModel) &&
        ((isNonHighEfficiencyChpPlant(formModel.plantData) &&
            isActivePowerBelowOrEqualTippingPoint(model, 100)) ||
            (isHighEfficiencyChpPlant(formModel.plantData) &&
                isActivePowerAboveTippingPoint(model, 25) &&
                isActivePowerBelowOrEqualTippingPoint(model, 100)))
    );
};

//https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854573319/Formular+Anlagenleistung#FormularAnlagenleistung-isDeaUnknownMiddleRangeActivePower
const isDeaUnknownMiddleRangeActivePowerRule = function (
    model: PlantPerformanceFormStep,
    formModel: ApplicationForm,
): boolean {
    return (
        (isDea(formModel) || isUnknown(formModel)) &&
        isActivePowerAboveTippingPoint(model, 25) &&
        isActivePowerBelowOrEqualTippingPoint(model, 100)
    );
};

/**
 * Disables any invalid options in the Power Reduction Type Radio Group based on the supplied powerReductionRules
 */
const setPowerReductionTypeOptions = function (
    model: PlantDataFormStep,
    powerReductionRules: Record<PowerReductionValueType, boolean>,
): void {
    model.infeedManagement.imPowerReductionType.options.forEach((o) => {
        o.disabled = !powerReductionRules[o.key];
    });
};

type InfeedManagementConditionalInfoKey = 'pvInfo' | 'otherInfo';

export const plantDataInfeedManagementRules = async (
    formStep: {
        model: PlantDataFormStep;
        formModel: ApplicationForm;
    } & Vue,
): Promise<void> => {
    //Execute Rules
    const isPvNominalPowerInRange = isPvNominalPowerInRangeRule(
        formStep.formModel.plantPerformance,
        formStep.formModel,
    );

    const isPhcActivePowerInRange = isPhcActivePowerInRangeRule(
        formStep.formModel.plantPerformance,
        formStep.formModel,
    );

    const isDeaUnknownMiddleRangeActivePower = isDeaUnknownMiddleRangeActivePowerRule(
        formStep.formModel.plantPerformance,
        formStep.formModel,
    );

    const isPlugin = isPluginGeneratingPlant(formStep.formModel);

    //set overall visibility of the infeed management section
    formStep.model.infeedManagement.disabled =
        isPlugin ||
        isStorage(formStep.formModel) ||
        (!isPvNominalPowerInRange &&
            !isPhcActivePowerInRange &&
            !isDeaUnknownMiddleRangeActivePower);

    //In-Feed Management Conditional Info
    const infeedManagementConditionInfo = {
        pvInfo: isPv(formStep.formModel),
        otherInfo: isOther(formStep.formModel),
    } as Record<InfeedManagementConditionalInfoKey, boolean>;

    formStep.model.infeedManagement.imHeadline.conditionalInfoKey = findFirstValidKey(
        infeedManagementConditionInfo,
    );

    //Power Reduction Items Display Logic
    const isAudioFrequencyControlReceiver =
        isPvNominalPowerInRange ||
        isPhcActivePowerInRange ||
        isDeaUnknownMiddleRangeActivePower;

    const noPowerReduction =
        isPluginGeneratingPlant(formStep.formModel) &&
        (isPvNominalPowerInRange || isDeaUnknownMiddleRangeActivePower);

    const powerReductionRules: Record<PowerReductionValueType, boolean> = {
        noPowerReduction: noPowerReduction,
        audioFrequencyControlReceiverInternal: isAudioFrequencyControlReceiver,
        audioFrequencyControlReceiverExternal: isAudioFrequencyControlReceiver,
    };

    setPowerReductionTypeOptions(formStep.model, powerReductionRules);

    //Billing and Contracting Rules
    const isAudioFrequencyControlReceiverInternal =
        !formStep.model.infeedManagement.imPowerReductionType.disabled &&
        formStep.model.infeedManagement.imPowerReductionType.value ===
            'audioFrequencyControlReceiverInternal';

    const isAudioFrequencyControlReceiverExternal =
        !formStep.model.infeedManagement.imPowerReductionType.disabled &&
        formStep.model.infeedManagement.imPowerReductionType.value ===
            'audioFrequencyControlReceiverExternal';

    formStep.model.infeedManagement.imAudioFrequencyControlReceiverContract.disabled = !isAudioFrequencyControlReceiverInternal;

    formStep.model.infeedManagement.imProvisionThrough.disabled = !isAudioFrequencyControlReceiverExternal;

    //Billing Rules
    const isSepa =
        !formStep.model.infeedManagement.imBilling.disabled &&
        formStep.model.infeedManagement.imBilling.value === 'sepa';
    formStep.model.infeedManagement.imBilling.disabled = !isAudioFrequencyControlReceiverInternal;
    formStep.model.infeedManagement.imDirectDebitMandate.disabled = !isSepa;
    formStep.model.infeedManagement.imIban.disabled = !isSepa;
    formStep.model.infeedManagement.imCreditInstitutionName.disabled = !isSepa;
    formStep.model.infeedManagement.imAccountHolderName.disabled = !isSepa;
    formStep.model.infeedManagement.imBillingDate.disabled = !isSepa;

    formStep.model.infeedManagement.imTechnicalSpecificationsNote.disabled =
        !formStep.formModel.billing.noEegRemuneration.disabled &&
        !!formStep.formModel.billing.noEegRemuneration.value;
};
