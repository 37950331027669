import { parseGermanFloat } from '@/utilities/germanFormats';
import { ValidationRule } from 'vuelidate/lib/validators';

interface PowerValue {
    apparentPower: string;
}

export const apparentPowerMustBeBiggerThanActualPower = ((
    value: string,
    vm: PowerValue,
): boolean => {
    return (
        parseGermanFloat(value || '0') <=
        parseGermanFloat(vm.apparentPower || '0')
    );
}) as ValidationRule;
