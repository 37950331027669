import { UploadedFileInfo } from '@/api/interfaces/file/uploadedFileInfo';
import { JSONSchema7Definition } from 'json-schema';
import { defaults as _defaults } from 'lodash';
import {
    FormFieldConfig,
    formFieldDefaultConfig,
    FormField,
} from './formField';

export interface UploadFieldConfig extends FormFieldConfig<UploadedFileInfo> {
    value?: UploadedFileInfo;
}

export const uploadFieldDefaultConfig = {
    ...formFieldDefaultConfig,
};

export class UploadField extends FormField<UploadedFileInfo> {
    public constructor(config?: UploadFieldConfig) {
        const resolvedConfig = _defaults(
            config || ({} as UploadFieldConfig),
            uploadFieldDefaultConfig,
        );

        super(resolvedConfig);
    }

    public getJsonSchema(): JSONSchema7Definition {
        return {
            type: 'object',
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                },
                size: {
                    type: 'number',
                },
                url: {
                    type: 'string',
                },
            },
        };
    }
}
