import { DateField } from '@/types/forms/fields/dateField';
import { RadioButtonsField } from '@/types/forms/fields/radioButtonsField';
import { TextField } from '@/types/forms/fields/textField';
import { FormElement } from '@/types/forms/formElement';
import { FormGroup } from '@/types/forms/formGroup';
import { BillingValueType } from '@/types/forms/shared/billingValueType';
import { PowerReductionValueType } from '@/types/forms/shared/powerReductionValueType';
import { WithOptional } from '@/types/forms/utility';

type ConfigProps = WithOptional<InfeedManagementFormGroup, keyof FormGroup>;

export class InfeedManagementFormGroup extends FormGroup {
    public readonly imHeadline: FormElement;
    public readonly imPowerReductionType: RadioButtonsField<
        PowerReductionValueType
    >;
    public readonly imAudioFrequencyControlReceiverContract: FormElement;
    public readonly imProvisionThrough: TextField;
    public readonly imTechnicalSpecificationsNote: FormElement;

    public readonly imBilling: RadioButtonsField<BillingValueType>;
    public readonly imDirectDebitMandate: FormElement;
    public readonly imIban: TextField;
    public readonly imCreditInstitutionName: TextField;
    public readonly imAccountHolderName: TextField;
    public readonly imBillingDate: DateField;

    public constructor(config: ConfigProps) {
        super(config);

        this.imHeadline = config.imHeadline;
        this.imPowerReductionType = config.imPowerReductionType;
        this.imAudioFrequencyControlReceiverContract =
            config.imAudioFrequencyControlReceiverContract;
        this.imProvisionThrough = config.imProvisionThrough;
        this.imTechnicalSpecificationsNote =
            config.imTechnicalSpecificationsNote;
        this.imBilling = config.imBilling;
        this.imDirectDebitMandate = config.imDirectDebitMandate;
        this.imIban = config.imIban;
        this.imCreditInstitutionName = config.imCreditInstitutionName;
        this.imAccountHolderName = config.imAccountHolderName;
        this.imBillingDate = config.imBillingDate;
    }
}
