import { parseGermanFloat } from '@/utilities/germanFormats';
import { helpers, ValidationRule } from 'vuelidate/lib/validators';
import { float } from './float';

export const positive = ((value: string | number | Date): boolean => {
    if (!helpers.req(value) || !float(value)) {
        return true;
    }

    const numericValue =
        typeof value === 'string' ? parseGermanFloat(value) : +value;

    return numericValue >= 0;
}) as ValidationRule;
