import { Form } from './form';

/**
 * Adds iterable getter-only property.
 * @param fn getter function
 */
export function enumerableGetterAttribute<T>(fn: (target: T) => unknown) {
    return (target: T, propertyKey: keyof T): void => {
        function getter(this: T) {
            return fn(this);
        }

        Object.defineProperty(Form.prototype, propertyKey, {
            get: getter,
            // for...in iterates only over enumerable properties
            enumerable: true,
        });
    };
}
