import { JSONSchema7Definition } from 'json-schema';
import { defaults as _defaults } from 'lodash';
import { date } from '@/validators/date';
import {
    FormFieldConfig,
    formFieldDefaultConfig,
    FormField,
} from './formField';
import { DateRange } from '../shared/dateRange';

export interface DateRangeFieldConfig extends FormFieldConfig<DateRange> {
    value?: DateRange;
}

export const dateFieldDefaultConfig = {
    ...formFieldDefaultConfig,
    value: {
        start: '',
        end: '',
    },
};

export class DateRangeField extends FormField<DateRange> {
    public constructor(config?: DateRangeFieldConfig) {
        const resolvedConfig = _defaults(
            config || ({} as DateRangeFieldConfig),
            dateFieldDefaultConfig,
        ) as DateRangeFieldConfig;

        resolvedConfig.validators = {
            date,
            ...resolvedConfig.validators,
        };

        super(resolvedConfig);
    }

    public getJsonSchema(): JSONSchema7Definition {
        return {
            type: 'string',
        };
    }
}
