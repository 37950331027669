import { required } from 'vuelidate/lib/validators';
import { checked } from '@/validators/checked';
import { RadioButtonsField } from '@/types/forms/fields/radioButtonsField';
import { FormOption } from '@/types/forms/formOption';
import { PlantDataFormStep } from '@/types/forms/formSteps/plantDataFormStep';
import { TextField } from '@/types/forms/fields/textField';
import { SelectField } from '@/types/forms/fields/selectField';
import { CheckboxField } from '@/types/forms/fields/checkboxField';
import { maxDateNow } from '@/validators/maxDateNow';
import { DateField } from '@/types/forms/fields/dateField';
import { FormElement } from '@/types/forms/formElement';
import { infeedManagementFormGroup } from './plantData/infeedManagement';

export const plantDataFormStep = new PlantDataFormStep({
    newOrExistingApplication: new RadioButtonsField({
        value: 'new',
        options: [
            new FormOption({ key: 'new' }),
            new FormOption({ key: 'change' }),
        ],
        validators: {
            required,
        },
    }),
    applicationNumber: new TextField({
        validators: {
            required,
        },
    }),
    newOrExistingPlant: new RadioButtonsField({
        value: 'first',
        options: [
            new FormOption({ key: 'first' }),
            new FormOption({ key: 'additional' }),
            new FormOption({ key: 'performanceIncrease' }),
            new FormOption({ key: 'capacityIncrease' }),
        ],
        validators: {
            required,
        },
    }),
    powerHeatCouplingPlantType: new RadioButtonsField({
        value: 'nonHighEfficiencyChpPlant',
        options: [
            new FormOption({ key: 'nonHighEfficiencyChpPlant' }),
            new FormOption({ key: 'highEfficiencyChpPlant' }),
        ],
        validators: {
            required,
        },
    }),
    powerHeatCouplingPaymentsBasedOnLawHint: new FormElement(),
    bafaApprovalWillBeSubmittedLater: new CheckboxField(),
    plantWasAlreadyCommissioned: new CheckboxField(),
    initialCommissioningDate: new DateField({
        validators: {
            maxDateNow,
        },
    }),
    plantBuildingType: new RadioButtonsField({
        value: 'residentialBuilding',
        options: [
            new FormOption({ key: 'residentialBuilding' }),
            new FormOption({ key: 'noResidentialBuilding' }),
        ],
        validators: {
            required,
        },
    }),
    plantPosition: new RadioButtonsField({
        value: 'indoor',
        options: [
            new FormOption({ key: 'indoor' }),
            new FormOption({ key: 'outdoor' }),
        ],
        validators: {
            required,
        },
    }),
    pvInstallationType: new RadioButtonsField({
        value: 'residentialOrSimilarBuilding',
        options: [
            new FormOption({ key: 'residentialOrSimilarBuilding' }),
            new FormOption({ key: 'outsideNonResidentialBuilding' }),
            new FormOption({ key: 'outsideNonResidentialBuildingEEG48Abs1' }),
            new FormOption({ key: 'structuralFacility' }),
            new FormOption({ key: 'nextToResidentialBuilding' }),
            new FormOption({ key: 'publicWasteDisposalFacilities' }),
            new FormOption({ key: 'approvedDevelopmentPlan' }),
            new FormOption({ key: 'artificialWaterBody' }),
            new FormOption({ key: 'agriculturalLandOrParkingLots' }),
        ],
        validators: {
            required,
        },
    }),
    pvInstallationTypeForAgriculturalLandOrParkingLots: new RadioButtonsField({
        value: 'agriculturalLand',
        options: [
            new FormOption({ key: 'agriculturalLand' }),
            new FormOption({ key: 'permanentCrops' }),
            new FormOption({ key: 'grassLand' }),
            new FormOption({ key: 'parkingLots' }),
            new FormOption({ key: 'peatSoils' }),
        ],
        validators: {
            required,
        },
    }),
    storageCoupling: new SelectField({
        options: [
            new FormOption({
                key: 'acCoupled',
            }),
            new FormOption({
                key: 'dcCoupled',
            }),
        ],
        validators: {
            required,
        },
    }),
    storageConnectionConcept: new RadioButtonsField({
        value:
            'powerGenerationPlantWithStorageInTheConsumerPathAndConsumptionPlant',
        options: [
            new FormOption({
                key:
                    'powerGenerationPlantWithStorageInTheConsumerPathAndConsumptionPlant',
            }),
            new FormOption({
                key:
                    'storageWithoutPowerGenerationPlantAndWithoutConsumptionPlant',
            }),
            new FormOption({
                key:
                    'storageFacilityWithPowerGenerationPlantAndWithoutConsumptionPlant',
            }),
            new FormOption({
                key:
                    'powerGenerationPlantWithStorageFacilityInTheGenerationPathAndConsumptionPlant',
            }),
        ],
        validators: {
            required,
        },
    }),
    electricityTaxExemptionHint: new FormElement(),
    tenantElectricitySurcharge: new CheckboxField(),
    requirementTenantElectricitySurchargeHint: new FormElement(),
    minimumRequirementLivingSpace: new CheckboxField({
        validators: {
            checked,
        },
    }),
    electricityConsumptionWithinThisBuilding: new CheckboxField({
        validators: {
            checked,
        },
    }),
    noAdditionalNetworkForEndConsumer: new CheckboxField({
        validators: {
            checked,
        },
    }),
    federalNetworkAgencyHint: new FormElement(),
    proofOfRegistrationPhotovoltaic: new CheckboxField({
        validators: {
            checked,
        },
    }),
    proofOfAllocationOfTenantElectricitySurcharge: new CheckboxField({
        validators: {
            checked,
        },
    }),
    declarationEnwgHint: new FormElement(),
    plantOperatorKnowsEnwgSpecifications: new CheckboxField({
        validators: {
            checked,
        },
    }),
    storageConnection: new FormElement(),
    storageCharge: new SelectField({
        options: [
            new FormOption({ key: 'onlyFromEeg' }),
            new FormOption({ key: 'onlyFromKwkg' }),
            new FormOption({ key: 'fromEegAndKwkg' }),
            new FormOption({ key: 'onlyFromPublicGrid' }),
            new FormOption({ key: 'fromPublicGridAndEeg' }),
            new FormOption({ key: 'fromPublicGridAndKwkg' }),
            new FormOption({ key: 'fromPublicGridAndEegAndKwkg' }),
        ],
        validators: {
            required,
        },
    }),
    storageDischarge: new SelectField({
        options: [
            new FormOption({ key: 'onlyIntoCustomersGridSelfSupply' }),
            new FormOption({ key: 'onlyIntoPublicGridGridFeedIn' }),
            new FormOption({ key: 'intoCustomersGridAndPublicGridBivalent' }),
        ],
        validators: {
            required,
        },
    }),
    storagePublicDischargeWarning: new FormElement(),
    storageBalancingEnergyMarketParticipation: new CheckboxField(),
    storageBalancingEnergyMarketParticipationType: new RadioButtonsField({
        value: 'positiveAndNegative',
        options: [
            new FormOption({ key: 'positiveAndNegative' }),
            new FormOption({ key: 'onlyPositive' }),
            new FormOption({ key: 'onlyNegative' }),
        ],
        validators: {
            required,
        },
    }),
    infeedManagement: infeedManagementFormGroup,
});
