import { maxLength, required } from 'vuelidate/lib/validators';
import { FormElement } from '@/types/forms/formElement';
import { FormOption } from '@/types/forms/formOption';
import { CheckboxField } from '@/types/forms/fields/checkboxField';
import { RadioButtonsField } from '@/types/forms/fields/radioButtonsField';
import { DateField } from '@/types/forms/fields/dateField';
import { PowerConnectionFormStep } from '@/types/forms/formSteps/powerConnectionFormStep';
import { float } from '@/validators/float';
import { TextField } from '@/types/forms/fields/textField';
import { positiveInteger } from '@/validators/positiveInteger';
import { positive } from '@/validators/positive';
import { requiredDate } from '@/validators/requiredDate';
import { mustBeLowerThanToExistingPlantsTotalPower } from '@/validators/mustBeLowerThanToExistingPlantsTotalPower';
import { mustBeLowerThanFeedInPowerRecommendation } from '@/validators/mustBeLowerThanFeedInPowerRecommendation';
import { alwaysFalse } from '@/validators/alwaysFalse';

export const powerConnectionFormStep = new PowerConnectionFormStep({
    doesConnectionExist: new RadioButtonsField({
        value: 'yes',
        options: [
            new FormOption({ key: 'yes' }),
            new FormOption({ key: 'ordered' }),
            new FormOption({ key: 'no' }),
        ],
        validators: {
            required,
        },
    }),
    orderNumber: new TextField({
        validators: {
            required,
            orderNumber: positiveInteger,
            maxLength: maxLength(8),
        },
    }),
    whichVoltageLevel: new RadioButtonsField({
        value: 'lowVoltage',
        options: [
            new FormOption({ key: 'lowVoltage' }),
            new FormOption({ key: 'mediumVoltage' }),
            new FormOption({ key: 'unknown' }),
        ],
        validators: {
            required,
        },
    }),
    hasExistingPlants: new CheckboxField(),
    existingPlantsTotalPower: new TextField({
        validators: {
            required,
            float,
            positive,
        },
    }),
    repoweringPlanned: new CheckboxField(),
    repoweringTotalPower: new TextField({
        validators: {
            required,
            float,
            positive,
            mustBeLowerThanToExistingPlantsTotalPower,
        },
    }),
    feedInPowerShallBeLimitedByAController: new CheckboxField(),
    feedInPower: new TextField({
        validators: {
            required,
            float,
            positive,
            mustBeLowerThanFeedInPowerRecommendation,
        },
    }),
    plannedReferencePower: new TextField({
        validators: {
            required,
            float,
            positive,
        },
    }),
    ofWhichOwnUseGeneratingPlant: new TextField({
        validators: {
            required,
            float,
            positive,
        },
    }),
    ownUseExceedsPlannedPowerWarning: new FormElement({
        validators: {
            alwaysFalse,
        },
    }),
    noConnectionAvailable: new FormElement(),
    reactivePowerCompensationSystemExists: new CheckboxField(),
    unitCapacity: new TextField({
        validators: {
            required,
            float,
        },
    }),
    unitIsPrototype: new CheckboxField(),
    buildingPowerDemand: new CheckboxField(),
    requiredPower: new TextField({
        validators: {
            required,
            float,
        },
    }),
    fromWhen: new DateField({
        validators: {
            requiredDate,
        },
    }),
});
