import { PlantPerformanceFormStep } from '@/types/forms/formSteps/plantPerformanceFormStep';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';

export const plantPerformancePhotovoltaicRules = async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formStep: {
        model: PlantPerformanceFormStep;
        formModel: ApplicationForm;
    } & Vue,
): Promise<void> => {
    // no rules at the moment
};
