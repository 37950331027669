import { FormElement } from '../formElement';
import { FormGroup } from '../formGroup';
import { WithOptional } from '../utility';

type ConfigProps = WithOptional<AddressCheckFeedbackFormGroup, keyof FormGroup>;

export class AddressCheckFeedbackFormGroup extends FormGroup {
    public readonly addressCheckFeedbackCheckingHeadline: FormElement;
    public readonly addressCheckFeedbackAvailableHeadline: FormElement;
    public readonly addressCheckFeedbackEnterAddressHeadline: FormElement;
    public readonly addressCheckFeedbackUnavailableHeadline: FormElement;
    public readonly addressCheckFeedbackUnavailableInfoText: FormElement;

    public constructor(config: ConfigProps) {
        super(config);

        this.addressCheckFeedbackCheckingHeadline =
            config.addressCheckFeedbackCheckingHeadline;
        this.addressCheckFeedbackAvailableHeadline =
            config.addressCheckFeedbackAvailableHeadline;
        this.addressCheckFeedbackEnterAddressHeadline =
            config.addressCheckFeedbackEnterAddressHeadline;
        this.addressCheckFeedbackUnavailableHeadline =
            config.addressCheckFeedbackUnavailableHeadline;
        this.addressCheckFeedbackUnavailableInfoText =
            config.addressCheckFeedbackUnavailableInfoText;
    }
}
