import { JSONSchema7Definition } from 'json-schema';
import { defaults as _defaults } from 'lodash';
import { date } from '@/validators/date';
import {
    FormFieldConfig,
    formFieldDefaultConfig,
    FormField,
} from './formField';

export interface DateFieldConfig extends FormFieldConfig<string> {
    value?: string;
    availableStart?: Date;
    availableEnd?: Date;
}

export const dateFieldDefaultConfig = {
    ...formFieldDefaultConfig,
};

export class DateField extends FormField<string> {
    public readonly availableStart: Date | undefined;
    public readonly availableEnd: Date | undefined;

    public constructor(config?: DateFieldConfig) {
        const resolvedConfig = _defaults(
            config || ({} as DateFieldConfig),
            dateFieldDefaultConfig,
        ) as DateFieldConfig;

        resolvedConfig.validators = {
            date,
            ...resolvedConfig.validators,
        };

        super(resolvedConfig);

        this.availableStart = config?.availableStart;
        this.availableEnd = config?.availableEnd;
    }

    public getJsonSchema(): JSONSchema7Definition {
        return {
            type: 'string',
        };
    }
}
