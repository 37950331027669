import { FormStep } from '../formStep';
import { WithOptional } from '../utility';
import { UploadField } from '../fields/uploadField';
import { FormGroup } from '../formGroup';
import { FormList } from '../fields/formList';
import { FormElementConfig } from '../formElement';
import { ReferenceFormGroup } from '../formGroups/referenceFormGroup';

type ConfigProps = WithOptional<DocumentsFormStep, keyof FormStep>;

type GeneratorModuleFormGroupConfig = FormElementConfig &
    Omit<GeneratorModuleFormGroup, keyof FormGroup>;

export class GeneratorModuleFormGroup extends ReferenceFormGroup {
    public readonly generatorTechnicalDataSheet: UploadField;
    public readonly generatorConformityConfirmationOrUnitCertificate: UploadField;
    public readonly generatorProofOfConformity: UploadField;
    public readonly generatorUnitOrPrototypeConfirmation: UploadField;
    public readonly generatorComponentCertificate: UploadField;

    constructor(
        config: GeneratorModuleFormGroupConfig,
        id: string | undefined = undefined,
    ) {
        super(config, id);

        this.generatorTechnicalDataSheet = config.generatorTechnicalDataSheet;
        this.generatorConformityConfirmationOrUnitCertificate =
            config.generatorConformityConfirmationOrUnitCertificate;
        this.generatorProofOfConformity = config.generatorProofOfConformity;
        this.generatorUnitOrPrototypeConfirmation =
            config.generatorUnitOrPrototypeConfirmation;
        this.generatorComponentCertificate =
            config.generatorComponentCertificate;
    }
}

export type GeneratorModuleFormList = FormList<
    GeneratorModuleFormGroup,
    GeneratorModuleFormGroupConfig
>;

export type InverterModuleFormGroupConfig = FormElementConfig &
    Omit<InverterModuleFormGroup, keyof FormGroup>;

export class InverterModuleFormGroup extends ReferenceFormGroup {
    public readonly inverterConformityConfirmationOrUnitCertificate: UploadField;
    public readonly inverterTechnicalDataSheet: UploadField;
    public readonly inverterProofOfConformity: UploadField;
    public readonly inverterUnitCertificate: UploadField;

    public constructor(
        config: InverterModuleFormGroupConfig,
        id: string | undefined = undefined,
    ) {
        super(config, id);

        this.inverterConformityConfirmationOrUnitCertificate =
            config.inverterConformityConfirmationOrUnitCertificate;
        this.inverterTechnicalDataSheet = config.inverterTechnicalDataSheet;
        this.inverterProofOfConformity = config.inverterProofOfConformity;
        this.inverterUnitCertificate = config.inverterUnitCertificate;
    }
}

export type InverterModuleFormList = FormList<
    InverterModuleFormGroup,
    InverterModuleFormGroupConfig
>;

export type StorageModuleFormGroupConfig = FormElementConfig &
    Omit<StorageModuleFormGroup, keyof FormGroup>;

export class StorageModuleFormGroup extends ReferenceFormGroup {
    public readonly storageConformityDeclarationStorageSystem: UploadField;
    public readonly storageManufacturerDataSheet: UploadField;

    public constructor(
        config: StorageModuleFormGroupConfig,
        id: string | undefined = undefined,
    ) {
        super(config, id);

        this.storageConformityDeclarationStorageSystem =
            config.storageConformityDeclarationStorageSystem;
        this.storageManufacturerDataSheet = config.storageManufacturerDataSheet;
    }
}

export type StorageModuleFormList = FormList<
    StorageModuleFormGroup,
    StorageModuleFormGroupConfig
>;

export class DocumentsFormStep extends FormStep {
    public readonly meterPicture: UploadField;
    public readonly bafaApproval: UploadField;

    public readonly gridAndPlantProtectionProofOfConformity: UploadField;

    public readonly performanceMonitoringCertificate: UploadField;
    public readonly sitePlanWithLotNumber: UploadField;
    public readonly electricalSystemCircuitDiagram: UploadField;

    public readonly proofOfReadyForPlanning: UploadField;

    public readonly generatorModules: GeneratorModuleFormList;
    public readonly inverterModules: InverterModuleFormList;
    public readonly storageModules: StorageModuleFormList;

    public constructor(config: ConfigProps) {
        super(config);
        this.meterPicture = config.meterPicture;
        this.bafaApproval = config.bafaApproval;

        this.gridAndPlantProtectionProofOfConformity =
            config.gridAndPlantProtectionProofOfConformity;

        this.performanceMonitoringCertificate =
            config.performanceMonitoringCertificate;
        this.sitePlanWithLotNumber = config.sitePlanWithLotNumber;
        this.electricalSystemCircuitDiagram =
            config.electricalSystemCircuitDiagram;

        this.proofOfReadyForPlanning = config.proofOfReadyForPlanning;

        this.generatorModules = config.generatorModules;
        this.inverterModules = config.inverterModules;
        this.storageModules = config.storageModules;
    }
}
