import { JSONSchema7Definition } from 'json-schema';
import { FormFieldWithOptions } from './formFieldWithOptions';

export class CheckboxesField<TOptionKey> extends FormFieldWithOptions<
    TOptionKey[],
    TOptionKey
> {
    public getJsonSchema(): JSONSchema7Definition {
        return {
            type: 'array',
        };
    }
}
