import { helpers, ValidationRule } from 'vuelidate/lib/validators';
import moment from 'moment';
import { germanDateFormat } from '@/utilities/germanFormats';
import { date } from './date';
import { DateRange } from '@/types/forms/shared/dateRange';

/*
 * Determines whether the given value is between the specified minDate and maxDate. Moment js is used
 here to parse the string in German format. The moment() function accepts the string and format and
 will internally create a Date object. The chained isBetween function performs the comparison. The last
 parameter sets inclusivity for the start and end dates.
 https://momentjs.com/docs/#/use-it/
*/

export const inDateRange = (
    minDate: string,
    maxDate: string,
): ValidationRule => {
    return helpers.withParams(
        { type: 'inDateRange', minDate, maxDate },
        (value: string | null | DateRange) => {
            const validateValueMin =
                typeof value === 'string' || value == null
                    ? value
                    : value.start;
            const validateValueMax =
                typeof value === 'string' || value == null ? value : value.end;
            return (
                !!minDate &&
                !!maxDate &&
                (!helpers.req(validateValueMin) ||
                    !date(validateValueMin) ||
                    moment(validateValueMin, germanDateFormat, true).isBetween(
                        moment(minDate, germanDateFormat, true),
                        moment(maxDate, germanDateFormat, true),
                        undefined,
                        '[]',
                    )) &&
                !!minDate &&
                !!maxDate &&
                (!helpers.req(validateValueMax) ||
                    !date(validateValueMax) ||
                    moment(validateValueMax, germanDateFormat, true).isBetween(
                        moment(minDate, germanDateFormat, true),
                        moment(maxDate, germanDateFormat, true),
                        undefined,
                        '[]',
                    ))
            );
        },
    );
};
