import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import { locationFormStep } from './application/location';
import { generationTypeFormStep } from './application/generationType';
import { powerConnectionFormStep } from './application/powerConnection';
import { plantOperatorFormStep } from './application/plantOperator';
import { plantBuilderFormStep } from './application/plantBuilder';
import { plantDataFormStep } from './application/plantData';
import { propertyOwnerFormStep } from './application/propertyOwner';
import { plantPerformanceFormStep } from './application/plantPerformance';
import { measuringConceptsFormStep } from './application/measuringConcepts';
import { controllableConsumptionDevicesFormStep } from './application/controllableConsumptionDevices';
import { billingFormStep } from './application/billing';
import { checkAndSendFormStep } from './application/checkAndSend';
import { documentsFormStep } from './application/documents';

export const applicationForm = new ApplicationForm({
    steps: [
        '_init_',
        'generationType',
        'location',
        'plantPerformance',
        'powerConnection',
        'plantData',
        'measuringConcepts',
        'controllableConsumptionDevices',
        'plantOperator',
        'plantBuilder',
        'propertyOwner',
        'billing',
        'documents',
        'checkAndSend',
    ],
    applicationId: null,
    generationType: generationTypeFormStep,
    location: locationFormStep,
    plantPerformance: plantPerformanceFormStep,
    powerConnection: powerConnectionFormStep,
    plantOperator: plantOperatorFormStep,
    plantBuilder: plantBuilderFormStep,
    plantData: plantDataFormStep,
    propertyOwner: propertyOwnerFormStep,
    measuringConcepts: measuringConceptsFormStep,
    controllableConsumptionDevices: controllableConsumptionDevicesFormStep,
    billing: billingFormStep,
    documents: documentsFormStep,
    checkAndSend: checkAndSendFormStep,
    /**
     * If you change the version, you also have to modify src\services\form\modelService.ts -> migrateApplicationFormData() accordingly
     */
    version: 9,
    serverSideMigration: false,
});
