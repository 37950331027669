import { helpers, ValidationRule } from 'vuelidate/lib/validators';
import moment from 'moment';
import { germanDateFormat } from '@/utilities/germanFormats';
import { date } from './date';
import { DateRange } from '@/types/forms/shared/dateRange';

/*
 * Determines whether the given value is greater than the given minDate. Moment js is used
 here to parse the string in German format. The moment() function accepts the string and format and
 will internally create a Date object. The chained isSameOrAfter function performs the comparison.
 https://momentjs.com/docs/#/use-it/
*/

export const minDate = (minDate: string): ValidationRule => {
    return helpers.withParams(
        { type: 'minDate', minDate },
        (value: string | null | DateRange) => {
            const validateValue =
                typeof value === 'string' || value == null
                    ? value
                    : value.start;
            return (
                !!minDate &&
                (!helpers.req(validateValue) ||
                    !date(validateValue) ||
                    moment(validateValue, germanDateFormat, true).isSameOrAfter(
                        moment(minDate, germanDateFormat, true),
                    ))
            );
        },
    );
};
