import { CheckboxField } from '../fields/checkboxField';
import { RadioButtonsField } from '../fields/radioButtonsField';
import { FormElement } from '../formElement';
import { FormStep } from '../formStep';
import { GridControlImplementationPreferenceType } from '../shared/gridControlImplementationPreferenceType';
import { WithOptional } from '../utility';

type ConfigProps = WithOptional<
    ControllableConsumptionDevicesFormStep,
    keyof FormStep
>;

export class ControllableConsumptionDevicesFormStep extends FormStep {
    public readonly controllableConsumptionDevicesInfo: FormElement;
    public readonly gridControlConditionsAccepted: CheckboxField;
    public readonly gridControlImplementationPreference: RadioButtonsField<
        GridControlImplementationPreferenceType
    >;
    public readonly controllableConsumptionFacilitiesRequirementInfo: FormElement;

    constructor(config: ConfigProps) {
        super(config);
        this.controllableConsumptionDevicesInfo =
            config.controllableConsumptionDevicesInfo;
        this.gridControlConditionsAccepted =
            config.gridControlConditionsAccepted;
        this.gridControlImplementationPreference =
            config.gridControlImplementationPreference;
        this.controllableConsumptionFacilitiesRequirementInfo =
            config.controllableConsumptionFacilitiesRequirementInfo;
    }
}
