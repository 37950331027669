import { generalRules } from './application/general';
import { locationRules } from './application/location';
import { generationTypeRules } from './application/generationType';
import { powerConnectionRules } from './application/powerConnection';
import { plantOperatorRules } from './application/plantOperator';
import { plantBuilderRules } from './application/plantBuilder';
import { plantDataRules } from './application/plantData';
import { propertyOwnerRules } from './application/propertyOwner';
import { plantPerformanceRules } from './application/plantPerformance';
import { measuringConceptsRules } from './application/measuringConcepts';
import { controllableConsumptionDevicesRules } from './application/controllableConsumptionDevices';
import { billingRules } from './application/billing';
import { documentsRules } from './application/documents';
import { checkAndSendRules } from './application/checkAndSend';
import { confirmationRules } from './application/confirmation';

export const applicationRules = {
    general: generalRules,
    location: locationRules,
    generationType: generationTypeRules,
    powerConnection: powerConnectionRules,
    plantOperator: plantOperatorRules,
    plantBuilder: plantBuilderRules,
    plantData: plantDataRules,
    propertyOwner: propertyOwnerRules,
    plantPerformance: plantPerformanceRules,
    measuringConcepts: measuringConceptsRules,
    controllableConsumptionDevices: controllableConsumptionDevicesRules,
    billing: billingRules,
    documents: documentsRules,
    checkAndSend: checkAndSendRules,
    confirmation: confirmationRules,
};
