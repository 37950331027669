import { FormStep } from '../formStep';
import { CheckboxesField } from '../fields/checkboxesField';
import { WithOptional } from '../utility';

type ConfigProps = WithOptional<KitchenSinkFormStep3, keyof FormStep>;

export class KitchenSinkFormStep3 extends FormStep {
    public readonly checkedOptions1: CheckboxesField<string>;
    public readonly checkedOptions2: CheckboxesField<string>;
    public readonly dropdownCheckedOptions: CheckboxesField<string>;

    public constructor(config: ConfigProps) {
        super(config);

        this.checkedOptions1 = config.checkedOptions1;
        this.checkedOptions2 = config.checkedOptions2;
        this.dropdownCheckedOptions = config.dropdownCheckedOptions;
    }
}
