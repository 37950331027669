export const germanDateFormat = 'DD.MM.YYYY';
export const commaSeparator = ',';
export const thousandsSeparator = '.';

export function parseGermanFloat(value: string): number {
    return value ? parseFloat(value.replace(/\./g, '').replace(',', '.')) : NaN;
}

export function formatGermanNumber(
    value: number,
    options: {
        decimals?: number;
        thousandsSeparator?: boolean;
    } = {},
): string {
    // Intl.NumberFormat is not being used, because it runs on top of the browser
    // and won't work when unit testing
    const fixedValue =
        options.decimals !== null && options.decimals !== undefined
            ? value.toFixed(options.decimals)
            : value;

    // split number into integer and decimal parts
    const [integerString, decimalString] = `${fixedValue}`.split('.');

    // add thousands separators
    const separatedIntegerString = options.thousandsSeparator
        ? integerString.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator)
        : integerString;

    return decimalString
        ? `${separatedIntegerString}${commaSeparator}${decimalString}`
        : decimalString;
}
