export const epsilon = 0.000000001;
const defaultEpsilon = epsilon;

export const eq = (
    value1: number,
    value2: number,
    epsilon = defaultEpsilon,
): boolean => {
    return Math.abs(value1 - value2) < epsilon;
};

export const gt = (
    value1: number,
    value2: number,
    epsilon = defaultEpsilon,
): boolean => {
    return value1 - value2 > epsilon;
};

export const lt = (
    value1: number,
    value2: number,
    epsilon = defaultEpsilon,
): boolean => {
    return gt(value2, value1, epsilon);
};

export const gte = (
    value1: number,
    value2: number,
    epsilon = defaultEpsilon,
): boolean => {
    return gt(value1, value2, epsilon) || eq(value1, value2, epsilon);
};

export const lte = (
    value1: number,
    value2: number,
    epsilon = defaultEpsilon,
): boolean => {
    return gte(value2, value1, epsilon);
};

export const clamp = (
    value: number,
    min: number,
    max: number,
    epsilon = defaultEpsilon,
): number => {
    return lt(value, min, epsilon)
        ? min
        : gt(value, max, epsilon)
        ? max
        : value;
};
