import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import {
    isDischargePowerAboveTippingPoint,
    isPluginGeneratingPlant,
    isPv,
    isRequestWithoutPlanning,
    isStorage,
} from './common';
import Vue from 'vue';

export const generalRules = async (
    application: ApplicationForm,
): Promise<void> => {
    await Vue.nextTick();

    application.powerConnection.disabled = isPluginGeneratingPlant(application);

    application.plantBuilder.disabled =
        (!application.plantOperator.plantBuilder.disabled &&
            application.plantOperator.plantBuilder.value === 'operator') ||
        isRequestWithoutPlanning(application);

    application.propertyOwner.disabled =
        application.plantOperator.separatePropertyOwner.disabled ||
        application.plantOperator.separatePropertyOwner.value === 'no';

    application.billing.disabled =
        application.generationType.plantType.value === 'storage' ||
        application.powerConnection.doesConnectionExist.value === 'no' ||
        application.powerConnection.whichVoltageLevel.value ===
            'mediumVoltage' ||
        application.powerConnection.whichVoltageLevel.value === 'unknown' ||
        isRequestWithoutPlanning(application) ||
        (isPv(application) &&
            (application.plantPerformance.photovoltaic.pvOverallNominalPower
                .value ?? 0) > 100);

    application.measuringConcepts.disabled = isRequestWithoutPlanning(
        application,
    );

    application.controllableConsumptionDevices.disabled =
        !isStorage(application) ||
        (!application.plantData.newOrExistingPlant.disabled &&
            application.plantData.newOrExistingPlant.value ==
                'capacityIncrease') ||
        !(
            (application.generationType.totalPlantPerformance.disabled ||
                application.generationType.totalPlantPerformance.value ===
                    'below300') &&
            (application.powerConnection.whichVoltageLevel.disabled ||
                application.powerConnection.whichVoltageLevel.value ===
                    'lowVoltage' ||
                application.powerConnection.whichVoltageLevel.value ===
                    'unknown') &&
            isDischargePowerAboveTippingPoint(application.plantPerformance, 4.2)
        );

    application.plantData.disabled = isRequestWithoutPlanning(application);
};
