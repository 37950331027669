import { parseGermanFloat } from './germanFormats';

// As there are fields of type string, that actually contain something else,
// this group of utils intends to provide parsing certain checks
// that also take care of the parsing necessary

/**
 * Compares two number strings, whether one is higher than or equal to the other
 * @param value the value that should be higher or equal
 * @param valueToCompareTo the value that should be lower or equal
 * @returns {boolean} whether `value` is higher than or equal to `valueToCompareTo`
 */
export function isHigherThanOrEqual(
    value: string | null,
    valueToCompareTo: string | null,
): boolean {
    const parsedValue = parseGermanFloat(value || '0');
    const parsedValueToCompareTo = parseGermanFloat(valueToCompareTo || '0');

    return parsedValue >= parsedValueToCompareTo;
}
