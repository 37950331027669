import { FormElement } from '@/types/forms/formElement';
import { FormOption } from '@/types/forms/formOption';
import { required } from 'vuelidate/lib/validators';
import { iban } from '@/validators/iban';
import { requiredDate } from '@/validators/requiredDate';
import { InfeedManagementFormGroup } from '@/types/forms/formGroups/plantData/infeedManagement/infeedManagementFormGroup';
import { RadioButtonsField } from '@/types/forms/fields/radioButtonsField';
import { TextField } from '@/types/forms/fields/textField';
import { DateField } from '@/types/forms/fields/dateField';

export const infeedManagementFormGroup = new InfeedManagementFormGroup({
    imHeadline: new FormElement(),
    imPowerReductionType: new RadioButtonsField({
        value: 'noPowerReduction',
        options: [
            new FormOption({ key: 'noPowerReduction' }),
            new FormOption({ key: 'audioFrequencyControlReceiverInternal' }),
            new FormOption({ key: 'audioFrequencyControlReceiverExternal' }),
        ],
        validators: {
            required,
        },
    }),
    imAudioFrequencyControlReceiverContract: new FormElement(),
    imProvisionThrough: new TextField({
        validators: {
            required,
        },
    }),
    imTechnicalSpecificationsNote: new FormElement(),
    imBilling: new RadioButtonsField({
        value: 'invoice',
        options: [
            new FormOption({ key: 'invoice' }),
            new FormOption({ key: 'sepa' }),
        ],
        validators: {
            required,
        },
    }),
    imDirectDebitMandate: new FormElement(),
    imIban: new TextField({
        validators: {
            required,
            iban,
        },
    }),
    imCreditInstitutionName: new TextField(),
    imAccountHolderName: new TextField({
        validators: {
            required,
        },
    }),
    imBillingDate: new DateField({
        validators: {
            requiredDate,
        },
    }),
});
