import { FormElementConfig } from '../../formElement';
import { FormGroup } from '../../formGroup';
import { TextField } from '../../fields/textField';

export type KitchenSinkUserFormGroupConfig = FormElementConfig &
    Omit<KitchenSinkUserFormGroup, keyof FormGroup>;

export class KitchenSinkUserFormGroup extends FormGroup {
    public readonly firstName: TextField;
    public readonly lastName: TextField;

    public constructor(config: KitchenSinkUserFormGroupConfig) {
        super(config);

        this.firstName = config.firstName;
        this.lastName = config.lastName;
    }
}
