import { get as _get } from 'lodash';
import { LocationFormStep } from '@/types/forms/formSteps/locationFormStep';
import { AddressesApiAvailabilityResult } from '@/types/forms/shared/addressesApi/addressesApiAvailabilityResult';

interface LocationVueContainer {
    availabilityResult: AddressesApiAvailabilityResult | null;
    model: LocationFormStep;
}

/**
 * If there was no issue with availability api and the address isn't available,
 * then the user can't proceed unless they switch to cadastral
 */
export function addressRequiredIfNoCadastral(this: Vue): boolean {
    const locationVueContainer: LocationVueContainer | undefined | null =
        _get(this, '$options.name') == 'LocationForm'
            ? this
            : _get(
                  this,
                  'formReference.$refs.applicationFormTemplate.$refs.location[0]',
              );

    // Container not found, so we prefer to allow the user to pass,
    // as the issue lies with the form, not the user
    if (!locationVueContainer) {
        return true;
    }
    // User switched to cadastral, ignore availability result
    if (
        !locationVueContainer.model.proceedWithCadastral.disabled &&
        locationVueContainer.model.proceedWithCadastral.value === true
    ) {
        return true;
    }

    // The api returned that the address is unavailable, but user hasn't switched to cadastral.
    if (locationVueContainer.model.availabilityResult.value === 'unavailable') {
        return false;
    }

    // The api wasn't called at all, which isn't allowed
    if (locationVueContainer.model.availabilityResult.value === null) {
        return false;
    }

    // At this point we have one of these cases left:
    // - The api returned a bad response, which generally allows the user to just pass,
    //   as the issue lies with the form, not the user
    // - The api returned that the address is available
    // - There could have been some other technical problem, in which case we don't block the form.
    return true;
}
