import { CountField } from '@/types/forms/fields/countField';
import { FormList } from '@/types/forms/fields/formList';
import { TextField } from '@/types/forms/fields/textField';
import { required } from 'vuelidate/lib/validators';
import { float } from '@/validators/float';
import { positive } from '@/validators/positive';
import { FormField } from '@/types/forms/fields/formField';
import { FormElement } from '@/types/forms/formElement';
import { StorageModuleFormGroup } from '@/types/forms/formGroups/plantPerformance/storage/storageModuleFormGroup';
import { StorageFormGroup } from '@/types/forms/formGroups/plantPerformance/storage/storageFormGroup';
import { ManufacturerAndStorageTypeFormGroup } from '@/types/forms/formGroups/plantPerformance/storage/manufacturerAndStorageTypeFormGroup';
import { DischargePowerAndStorageCapacityFormGroup } from '@/types/forms/formGroups/plantPerformance/storage/dischargePowerAndStorageCapacityFormGroup';

export const storageFormGroup = new StorageFormGroup({
    storageHeadline: new FormElement(),
    storageModules: new FormList(StorageModuleFormGroup, {
        initializeItems: 1,
        minItemCount: 1,
        maxItemCount: 5,
        id: new FormField<string>(),
        storageManufacturerAndStorageType: new ManufacturerAndStorageTypeFormGroup(
            {
                manufacturer: new TextField({
                    validators: {
                        required,
                    },
                }),
                storageType: new TextField({
                    validators: {
                        required,
                    },
                }),
            },
        ),
        storageQuantity: new CountField({
            value: 1,
            min: 1,
            validators: {
                required,
            },
        }),
        storageDischargePowerAndStorageCapacity: new DischargePowerAndStorageCapacityFormGroup(
            {
                dischargePower: new TextField({
                    validators: {
                        required,
                        float,
                        positive,
                    },
                }),
                storageCapacity: new TextField({
                    validators: {
                        required,
                        float,
                        positive,
                    },
                }),
            },
        ),
        storageTotalDischargePower: new FormField({
            value: 0,
        }),
        storageTotalStorageCapacity: new FormField({
            value: 0,
        }),
    }),
    storageOverallDischargePower: new FormField({
        value: 0,
    }),
    storageOverallStorageCapacity: new FormField({
        value: 0,
    }),
});
