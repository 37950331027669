import { PlantPerformanceFormStep } from '@/types/forms/formSteps/plantPerformanceFormStep';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import {
    isPluginGeneratingPlant,
    isPv,
    isStorage,
    isTotalPlantPerformanceAbove300,
    isTotalPlantPerformanceBelow300,
} from './common';
import Vue from 'vue';
import { plantPerformancePhotovoltaicRules } from './plantPerformance/photovoltaic';
import { plantPerformanceGeneratorRules } from './plantPerformance/generator';
import { plantPerformanceInverterRules } from './plantPerformance/inverter';
import { getOverallNominalPower } from '../../utilities/application/plantPerformance';

export const plantPerformanceRules = async (
    formStep: {
        model: PlantPerformanceFormStep;
        formModel: ApplicationForm;
    } & Vue,
): Promise<void> => {
    formStep.model.photovoltaic.disabled = !isPv(formStep.formModel);

    formStep.model.storage.disabled = !isStorage(formStep.formModel);

    formStep.model.inverter.disabled = !isInverterEnabled(formStep.formModel);

    formStep.model.generator.disabled = !isGeneratorEnabled(formStep.formModel);

    formStep.model.inverter.inverterOverallActivePower.disabled = isPluginGeneratingPlant(
        formStep.formModel,
    );

    formStep.model.inverter.inverterOverallApparentPower.disabled = isPluginGeneratingPlant(
        formStep.formModel,
    );

    formStep.model.certificateHint.disabled = !isPluginGeneratingPlant(
        formStep.formModel,
    );

    // we have to wait one additional tick for the component watchers
    // to set the overall sums to the underlying models
    await Vue.nextTick();

    // https://netzkern.atlassian.net/browse/ED-597
    const overallNominalPower = getOverallNominalPower(formStep.model);

    formStep.model.overallNominalPowerNotMatchingTotalPlantPerformanceValueTypeWarning.disabled =
        formStep.formModel.generationType.totalPlantPerformance.disabled ||
        overallNominalPower === null ||
        (isTotalPlantPerformanceAbove300(formStep.formModel) &&
            overallNominalPower > 300) ||
        (isTotalPlantPerformanceBelow300(formStep.formModel) &&
            overallNominalPower <= 300);

    await plantPerformanceGeneratorRules(formStep);
    await plantPerformanceInverterRules(formStep);
    await plantPerformancePhotovoltaicRules(formStep);
};

function isInverterEnabled(form: ApplicationForm): boolean {
    return isStorage(form) || isPv(form);
}

function isGeneratorEnabled(form: ApplicationForm): boolean {
    return !isStorage(form) && !isPv(form);
}
