import { CheckboxField } from '../fields/checkboxField';
import { RadioButtonsField } from '../fields/radioButtonsField';
import { SelectField } from '../fields/selectField';
import { TextField } from '../fields/textField';
import { OperatorFormListType } from '../formGroups/operatorFormList';
import { StreetAndHousenumberFormGroup } from '../formGroups/streetAndHousenumberFormGroup';
import { ZipAndCityFormGroup } from '../formGroups/zipAndCityFormGroup';
import { FormStep } from '../formStep';
import type { CountryValueType } from '../shared/countryValueType';
import { PlantBuilderValueType } from '../shared/plantBuilderValueType';
import { YesNoValueType } from '../shared/yesNoValueType';
import { WithOptional } from '../utility';

type ConfigProps = WithOptional<PlantOperatorFormStep, keyof FormStep>;

export class PlantOperatorFormStep extends FormStep {
    public readonly separatePropertyOwner: RadioButtonsField<YesNoValueType>;
    public readonly plantBuilder: RadioButtonsField<PlantBuilderValueType>;
    public readonly operators: OperatorFormListType;
    public readonly country: SelectField<CountryValueType>;
    public readonly usePlantLocation: CheckboxField;
    public readonly zipAndCity: ZipAndCityFormGroup;
    public readonly streetAndHousenumber: StreetAndHousenumberFormGroup;
    public readonly email: TextField;
    public readonly phoneNumber1: TextField;
    public readonly phoneNumber2: TextField;

    public constructor(config: ConfigProps) {
        super(config);

        this.separatePropertyOwner = config.separatePropertyOwner;
        this.plantBuilder = config.plantBuilder;
        this.operators = config.operators;
        this.country = config.country;
        this.usePlantLocation = config.usePlantLocation;
        this.zipAndCity = config.zipAndCity;
        this.streetAndHousenumber = config.streetAndHousenumber;
        this.email = config.email;
        this.phoneNumber1 = config.phoneNumber1;
        this.phoneNumber2 = config.phoneNumber2;
    }
}
