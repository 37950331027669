import moment from 'moment';
import { maxDate } from './maxDate';
import { germanDateFormat } from '@/utilities/germanFormats';

/*
 * Determines whether the given value is a date, which is the current date or a past date. Moment js is
 used here to parse the string in German format. The moment() function accepts the string and format and
 will internally create a Date object. The chained isSameOrBefore function performs the comparison.
 https://momentjs.com/docs/#/use-it/
*/

export const maxDateNow = maxDate(moment().format(germanDateFormat));
