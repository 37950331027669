import { CheckboxField } from '../fields/checkboxField';
import { RadioButtonsField } from '../fields/radioButtonsField';
import { SelectField } from '../fields/selectField';
import { TextField } from '../fields/textField';
import { FormElement } from '../formElement';
import { FormStep } from '../formStep';
import { MeasuringConceptOperatingModeType } from '../shared/measuringConceptsOperatingModeType';
import { MeasuringPointOperationType } from '../shared/measuringPointOperationType';
import { WithOptional } from '../utility';

type ConfigProps = WithOptional<MeasuringConceptsFormStep, keyof FormStep>;

export class MeasuringConceptsFormStep extends FormStep {
    public readonly operatingMode: SelectField<
        MeasuringConceptOperatingModeType
    >;
    public readonly fullInfeedBonusHeadline: FormElement;
    public readonly fullInfeedBonus: CheckboxField;
    public readonly measuringDeviceStorage: CheckboxField;
    public readonly measuringPointOperation: RadioButtonsField<
        MeasuringPointOperationType
    >;
    public readonly meterNumber: TextField;
    public readonly measuringPointOperator: SelectField<string>;
    public readonly priceSheetMeasuringPointOperation: FormElement;
    public readonly selfGenerationQuantity: TextField;
    public readonly selfSupplyQuantity: TextField;
    public readonly remainingElectricityInfo: FormElement;

    constructor(config: ConfigProps) {
        super(config);
        this.operatingMode = config.operatingMode;
        this.fullInfeedBonusHeadline = config.fullInfeedBonusHeadline;
        this.fullInfeedBonus = config.fullInfeedBonus;
        this.measuringDeviceStorage = config.measuringDeviceStorage;
        this.measuringPointOperation = config.measuringPointOperation;
        this.meterNumber = config.meterNumber;
        this.measuringPointOperator = config.measuringPointOperator;
        this.priceSheetMeasuringPointOperation =
            config.priceSheetMeasuringPointOperation;
        this.selfGenerationQuantity = config.selfGenerationQuantity;
        this.selfSupplyQuantity = config.selfSupplyQuantity;
        this.remainingElectricityInfo = config.remainingElectricityInfo;
    }
}
