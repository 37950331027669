import { helpers, ValidationRule } from 'vuelidate/lib/validators';
import { getComparableNumber } from './util';

export function lt(comparedValue: number | string | Date): ValidationRule {
    return helpers.withParams(
        { type: 'lt', comparedValue },
        (value: number | string | Date) => {
            if (!helpers.req(value)) {
                return true;
            }

            const comparableValue = getComparableNumber(value);
            const comparableComparedValue = getComparableNumber(comparedValue);

            if (comparableValue === null || comparableComparedValue === null) {
                return true;
            }

            return comparableValue < comparableComparedValue;
        },
    );
}
