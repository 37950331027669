import { parseGermanFloat } from '@/utilities/germanFormats';
import { ValidationRule } from 'vuelidate/lib/validators';

interface ExistingPlantsTotalPowerPartialVm {
    existingPlantsTotalPower: string;
}

export const mustBeLowerThanToExistingPlantsTotalPower = function (
    value: string,
    vm: ExistingPlantsTotalPowerPartialVm,
) {
    const existingPlantsTotalPower = parseGermanFloat(
        vm.existingPlantsTotalPower || '0',
    );
    const val = parseGermanFloat(value || '0');
    return val <= existingPlantsTotalPower;
} as ValidationRule;
