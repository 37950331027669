import { PlantDataFormStep } from '@/types/forms/formSteps/plantDataFormStep';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import {
    isStorageCouplingAcCoupled,
    isStorageCouplingDcCoupled,
    isStorageConnectionConceptWithoutPowerGeneration,
    isStorageConnectionConceptWithPowerGeneration,
    isStorageConnectionConceptStorageInGenerationPath,
    isStorageConnectionConceptStorageInConsumerPath,
    isStorageChargeFromPublicGrid,
    isStorageDischargeIntoPublicGrid,
    isPluginGeneratingPlant,
    isPv,
    isMediumVoltage,
    isStorage,
    isStorageDischargeCustomersGridAndPublicGrid,
} from './common';
import { plantDataInfeedManagementRules } from './plantData/infeedManagement';

export const plantDataRules = async (
    formStep: { model: PlantDataFormStep; formModel: ApplicationForm } & Vue,
): Promise<void> => {
    const applicationType = formStep.formModel.applicationType;
    const hasStorage = isStorage(formStep.formModel);

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-applicationNumber
    formStep.model.applicationNumber.disabled =
        formStep.model.newOrExistingApplication.value !== 'change';

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-newOrExistingPlant
    {
        formStep.model.newOrExistingPlant.disabled =
            applicationType === 'photovoltaic';

        for (const option of formStep.model.newOrExistingPlant.options) {
            switch (option.key) {
                case 'performanceIncrease':
                    option.disabled = hasStorage;
                    break;
                case 'capacityIncrease':
                    option.disabled = !hasStorage;
            }
        }
    }

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-powerHeatCouplingPlantType
    formStep.model.powerHeatCouplingPlantType.disabled =
        applicationType !== 'powerHeatCoupling';

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-powerHeatCouplingPaymentsBasedOnLawHint
    formStep.model.powerHeatCouplingPaymentsBasedOnLawHint.disabled =
        formStep.model.powerHeatCouplingPlantType.disabled ||
        formStep.model.powerHeatCouplingPlantType.value !==
            'highEfficiencyChpPlant';

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-bafaApprovalWillBeSubmittedLater
    formStep.model.bafaApprovalWillBeSubmittedLater.disabled =
        formStep.model.powerHeatCouplingPlantType.disabled ||
        formStep.model.powerHeatCouplingPlantType.value !==
            'highEfficiencyChpPlant';

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-initialCommissioningDate
    formStep.model.initialCommissioningDate.disabled =
        formStep.model.plantWasAlreadyCommissioned.value !== true;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-plantBuildingType
    formStep.model.plantBuildingType.disabled =
        formStep.formModel.generationType.plantStructureType.disabled ||
        formStep.formModel.generationType.plantStructureType.value !==
            'building' ||
        (isPv(formStep.formModel) &&
            (formStep.formModel.plantPerformance.photovoltaic
                .pvOverallNominalPower.value ?? 0) > 100) ||
        isMediumVoltage(formStep.formModel.powerConnection);

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-plantPosition
    formStep.model.plantPosition.disabled =
        formStep.model.plantBuildingType.disabled ||
        formStep.model.plantBuildingType.value !== 'noResidentialBuilding';

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-pvInstallationType
    {
        formStep.model.pvInstallationType.disabled =
            applicationType !== 'photovoltaic' ||
            (formStep.formModel.plantPerformance.photovoltaic
                .pvOverallNominalPower.value ?? 0) > 100 ||
            isMediumVoltage(formStep.formModel.powerConnection);

        for (const option of formStep.model.pvInstallationType.options) {
            switch (option.key) {
                case 'residentialOrSimilarBuilding':
                    option.disabled =
                        (formStep.formModel.generationType.plantStructureType
                            .disabled ||
                            formStep.formModel.generationType.plantStructureType
                                .value !== 'noiseBarrier') &&
                        (formStep.model.plantBuildingType.disabled ||
                            formStep.model.plantBuildingType.value !==
                                'residentialBuilding') &&
                        (formStep.model.plantPosition.disabled ||
                            formStep.model.plantPosition.value) !== 'indoor';
                    break;

                case 'outsideNonResidentialBuilding':
                    option.disabled =
                        formStep.model.plantPosition.disabled ||
                        formStep.model.plantPosition.value !== 'outdoor';
                    break;
                case 'outsideNonResidentialBuildingEEG48Abs1':
                    option.disabled =
                        formStep.model.plantPosition.disabled ||
                        formStep.model.plantPosition.value !== 'outdoor';
                    break;

                case 'structuralFacility':
                case 'nextToResidentialBuilding':
                case 'publicWasteDisposalFacilities':
                case 'approvedDevelopmentPlan':
                case 'artificialWaterBody':
                case 'agriculturalLandOrParkingLots':
                    option.disabled =
                        formStep.formModel.generationType.disabled ||
                        formStep.formModel.generationType.plantStructureType
                            .value !== 'openSpacePlant';
                    break;
            }
        }
    }

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-pvInstallationTypeForAgriculturalLandOrParkingLots
    formStep.model.pvInstallationTypeForAgriculturalLandOrParkingLots.disabled =
        applicationType !== 'photovoltaic' ||
        formStep.model.pvInstallationType.disabled ||
        formStep.model.pvInstallationType.value !==
            'agriculturalLandOrParkingLots';

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-storageConnection
    formStep.model.storageConnection.disabled = !hasStorage;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-storageCoupling
    formStep.model.storageCoupling.disabled = !hasStorage;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-storageConnectionConcept
    {
        formStep.model.storageConnectionConcept.disabled =
            !hasStorage || !formStep.model.storageCoupling.value;

        for (const option of formStep.model.storageConnectionConcept.options) {
            switch (option.key) {
                case 'storageWithoutPowerGenerationPlantAndWithoutConsumptionPlant':
                case 'storageFacilityWithPowerGenerationPlantAndWithoutConsumptionPlant':
                case 'powerGenerationPlantWithStorageInTheConsumerPathAndConsumptionPlant':
                    option.disabled = !isStorageCouplingAcCoupled(
                        formStep.model,
                    );
                    break;

                case 'powerGenerationPlantWithStorageFacilityInTheGenerationPathAndConsumptionPlant':
                    option.disabled = !isStorageCouplingDcCoupled(
                        formStep.model,
                    );
                    break;
            }
        }
    }

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-electricityTaxExemptionHint
    formStep.model.electricityTaxExemptionHint.disabled = hasStorage;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-tenantElectricitySurcharge
    formStep.model.tenantElectricitySurcharge.disabled =
        applicationType !== 'photovoltaic' ||
        isPluginGeneratingPlant(formStep.formModel) ||
        formStep.model.plantBuildingType.value !== 'residentialBuilding' ||
        formStep.formModel.generationType.plantStructureType.value !==
            'building';

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-requirementTenantElectricitySurchargeHint
    formStep.model.requirementTenantElectricitySurchargeHint.disabled =
        formStep.model.tenantElectricitySurcharge.disabled ||
        !formStep.model.tenantElectricitySurcharge.value;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-minimumRequirementLivingSpace
    formStep.model.minimumRequirementLivingSpace.disabled =
        formStep.model.tenantElectricitySurcharge.disabled ||
        !formStep.model.tenantElectricitySurcharge.value;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-electricityConsumptionWithinThisBuilding
    formStep.model.electricityConsumptionWithinThisBuilding.disabled =
        formStep.model.tenantElectricitySurcharge.disabled ||
        !formStep.model.tenantElectricitySurcharge.value;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-noAdditionalNetworkForEndConsumer
    formStep.model.noAdditionalNetworkForEndConsumer.disabled =
        formStep.model.tenantElectricitySurcharge.disabled ||
        !formStep.model.tenantElectricitySurcharge.value;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-federalNetworkAgencyHint
    formStep.model.federalNetworkAgencyHint.disabled =
        formStep.model.tenantElectricitySurcharge.disabled ||
        !formStep.model.tenantElectricitySurcharge.value;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-proofOfRegistrationPhotovoltaic
    formStep.model.proofOfRegistrationPhotovoltaic.disabled =
        formStep.model.tenantElectricitySurcharge.disabled ||
        !formStep.model.tenantElectricitySurcharge.value;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-proofOfAllocationOfTenantElectricitySurcharge
    formStep.model.proofOfAllocationOfTenantElectricitySurcharge.disabled =
        formStep.model.tenantElectricitySurcharge.disabled ||
        !formStep.model.tenantElectricitySurcharge.value;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-declarationEnwgHint
    formStep.model.declarationEnwgHint.disabled =
        formStep.model.tenantElectricitySurcharge.disabled ||
        !formStep.model.tenantElectricitySurcharge.value;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-plantOperatorKnowsEnwgSpecifications
    formStep.model.plantOperatorKnowsEnwgSpecifications.disabled =
        formStep.model.tenantElectricitySurcharge.disabled ||
        !formStep.model.tenantElectricitySurcharge.value;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-storageCharge
    {
        formStep.model.storageCharge.disabled =
            !hasStorage || !formStep.model.storageCoupling.value;

        for (const option of formStep.model.storageCharge.options) {
            switch (option.key) {
                case 'onlyFromEeg':
                case 'onlyFromKwkg':
                case 'fromEegAndKwkg':
                case 'fromPublicGridAndEeg':
                case 'fromPublicGridAndKwkg':
                case 'fromPublicGridAndEegAndKwkg':
                    option.disabled = isStorageConnectionConceptWithoutPowerGeneration(
                        formStep.model,
                    );
                    break;

                case 'onlyFromPublicGrid':
                    option.disabled = !isStorageConnectionConceptWithoutPowerGeneration(
                        formStep.model,
                    );
                    break;
            }
        }
    }

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-storageDischarge
    {
        formStep.model.storageDischarge.disabled =
            !hasStorage || !formStep.model.storageCoupling.value;

        for (const option of formStep.model.storageDischarge.options) {
            switch (option.key) {
                case 'onlyIntoCustomersGridSelfSupply':
                case 'intoCustomersGridAndPublicGridBivalent':
                    option.disabled =
                        !isStorageConnectionConceptStorageInGenerationPath(
                            formStep.model,
                        ) &&
                        !isStorageConnectionConceptStorageInConsumerPath(
                            formStep.model,
                        );
                    break;

                case 'onlyIntoPublicGridGridFeedIn':
                    option.disabled =
                        !isStorageConnectionConceptWithoutPowerGeneration(
                            formStep.model,
                        ) &&
                        !isStorageConnectionConceptWithPowerGeneration(
                            formStep.model,
                        );
                    break;
            }
        }
    }

    const storageChargeFromPublicGrid = isStorageChargeFromPublicGrid(
        formStep.model,
    );

    const storageDischargeIntoPublicGrid = isStorageDischargeIntoPublicGrid(
        formStep.model,
    );

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-storageBalancingEnergyMarketParticipation
    formStep.model.storageBalancingEnergyMarketParticipation.disabled =
        !hasStorage ||
        (!storageChargeFromPublicGrid && !storageDischargeIntoPublicGrid);

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-storageBalancingEnergyMarketParticipationType
    {
        formStep.model.storageBalancingEnergyMarketParticipationType.disabled =
            formStep.model.storageBalancingEnergyMarketParticipation.disabled ||
            !formStep.model.storageBalancingEnergyMarketParticipation.value;

        for (const option of formStep.model
            .storageBalancingEnergyMarketParticipationType.options) {
            switch (option.key) {
                case 'positiveAndNegative':
                    option.disabled =
                        !storageChargeFromPublicGrid ||
                        !storageDischargeIntoPublicGrid;
                    break;

                case 'onlyPositive':
                    option.disabled = !storageDischargeIntoPublicGrid;
                    break;

                case 'onlyNegative':
                    option.disabled = !storageChargeFromPublicGrid;
                    break;
            }
        }
    }

    formStep.model.storagePublicDischargeWarning.disabled =
        !hasStorage ||
        !(
            (isStorageConnectionConceptStorageInGenerationPath(
                formStep.model,
            ) ||
                isStorageConnectionConceptStorageInConsumerPath(
                    formStep.model,
                )) &&
            isStorageDischargeCustomersGridAndPublicGrid(formStep.model)
        );

    await plantDataInfeedManagementRules(formStep);
};
