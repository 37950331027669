import { CheckboxField } from '@/types/forms/fields/checkboxField';
import { RadioButtonsField } from '@/types/forms/fields/radioButtonsField';
import { SelectField } from '@/types/forms/fields/selectField';
import { TextField } from '@/types/forms/fields/textField';
import { FormElement } from '@/types/forms/formElement';
import { FormOption } from '@/types/forms/formOption';
import { MeasuringConceptsFormStep } from '@/types/forms/formSteps/measuringConceptsFormStep';
import { float } from '@/validators/float';
import { mustBeLowerThanOrEqualToSelfGenerationQuantity } from '@/validators/mustBeLowerThanOrEqualToSelfGenerationQuantity';
import { mustBeNoMoreThan20PercentAboveSuggestion } from '@/validators/mustBeNoMoreThan20PercentAboveSuggestion';
import { positive } from '@/validators/positive';
import { alphaNum, maxLength, required } from 'vuelidate/lib/validators';

export const measuringConceptsFormStep = new MeasuringConceptsFormStep({
    operatingMode: new SelectField({
        options: [
            new FormOption({ key: 'fullInfeed' }),
            new FormOption({ key: 'surplusInfeed' }),
            new FormOption({ key: 'zeroInfeed' }),
            new FormOption({ key: 'commercialTransfer' }),
        ],
        validators: {
            required,
        },
    }),
    fullInfeedBonusHeadline: new FormElement(),
    fullInfeedBonus: new CheckboxField(),
    measuringDeviceStorage: new CheckboxField(),
    meterNumber: new TextField({
        validators: {
            required,
            alphaNum,
            maxLength: maxLength(14),
        },
    }),
    measuringPointOperation: new RadioButtonsField({
        value: 'ewe',
        options: [
            new FormOption({ key: 'ewe' }),
            new FormOption({ key: 'other' }),
        ],
        validators: {
            required,
        },
    }),
    measuringPointOperator: new SelectField({
        options: [],
        validators: {
            required,
        },
    }),
    priceSheetMeasuringPointOperation: new FormElement(),
    selfGenerationQuantity: new TextField({
        value: '0,00',
        validators: {
            required,
            float,
            positive,
            mustBeNoMoreThan20PercentAboveSuggestion,
        },
    }),
    selfSupplyQuantity: new TextField({
        value: '0,00',
        validators: {
            required,
            float,
            positive,
            mustBeLowerThanOrEqualToSelfGenerationQuantity,
        },
    }),
    remainingElectricityInfo: new FormElement(),
});
