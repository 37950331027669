import { DateRange } from '@/types/forms/shared/dateRange';
import { helpers } from 'vuelidate/lib/validators';

export const requiredDate = (value: string | null | DateRange): boolean => {
    if (typeof value === 'string' || value === null) {
        return helpers.req(value);
    } else {
        return helpers.req(value.start) && helpers.req(value.end);
    }
};
