import { FormField } from '@/types/forms/fields/formField';
import { TextField } from '@/types/forms/fields/textField';
import { FormElement, FormElementConfig } from '@/types/forms/formElement';
import { FormGroup } from '@/types/forms/formGroup';
import { ActivePowerAndApparentPowerFormGroup } from '../activePowerAndApparentPowerFormGroup';
import { ManufacturerAndModuleTypeFormGroup } from '../manufacturerAndModuleTypeFormGroup';
import { QuantityAndGeneratorTypeFormGroup } from '../quantityAndGeneratorTypeFormGroup';
import { ReferenceFormGroup } from '../../referenceFormGroup';

export type GeneratorModuleFormGroupConfig = FormElementConfig &
    Omit<GeneratorModuleFormGroup, keyof FormGroup>;

export class GeneratorModuleFormGroup extends ReferenceFormGroup {
    public readonly generatorManufacturerAndModuleType: ManufacturerAndModuleTypeFormGroup;
    public readonly generatorQuantityAndGeneratorType: QuantityAndGeneratorTypeFormGroup;
    public readonly generatorContributionSymmetricalShortCircuitCurrent: TextField;
    public readonly generatorVoltageSymmetricalShortCircuitCurrent: TextField;
    public readonly generatorContributionSustainedShortCircuitCurrent: TextField;
    public readonly generatorVoltageSustainedShortCircuitCurrent: TextField;
    public readonly generatorActivePowerAndApparentPower: ActivePowerAndApparentPowerFormGroup;
    public readonly generatorTotalActivePowerIsGreaterThanTotalApparentPowerWarning: FormElement;
    public readonly generatorTotalActivePower: FormField<number>;
    public readonly generatorTotalApparentPower: FormField<number>;

    constructor(config: GeneratorModuleFormGroupConfig) {
        super(config);

        this.generatorManufacturerAndModuleType =
            config.generatorManufacturerAndModuleType;
        this.generatorQuantityAndGeneratorType =
            config.generatorQuantityAndGeneratorType;
        this.generatorContributionSymmetricalShortCircuitCurrent =
            config.generatorContributionSymmetricalShortCircuitCurrent;
        this.generatorVoltageSymmetricalShortCircuitCurrent =
            config.generatorVoltageSymmetricalShortCircuitCurrent;
        this.generatorContributionSustainedShortCircuitCurrent =
            config.generatorContributionSustainedShortCircuitCurrent;
        this.generatorVoltageSustainedShortCircuitCurrent =
            config.generatorVoltageSustainedShortCircuitCurrent;
        this.generatorActivePowerAndApparentPower =
            config.generatorActivePowerAndApparentPower;
        this.generatorTotalActivePowerIsGreaterThanTotalApparentPowerWarning =
            config.generatorTotalActivePowerIsGreaterThanTotalApparentPowerWarning;
        this.generatorTotalActivePower = config.generatorTotalActivePower;
        this.generatorTotalApparentPower = config.generatorTotalApparentPower;
    }
}
