/**
 *
 * @param value current value
 * @param previousValue previous value
 * @returns difference as floating-type number (e.g. "0.5" for a 50% increase)
 */
export function getPercentageChange(
    value: number,
    previousValue: number,
): number {
    if (!previousValue) {
        throw new Error(
            'Can not determine percentage change, if the previous value is 0.',
        );
    }

    return value / previousValue - 1;
}

export function getPercentageDifference(
    value1: number,
    value2: number,
): number {
    const sum = value1 + value2;

    if (!sum) {
        throw new Error(
            'Can not determine percentage difference, if the value sum is 0.',
        );
    }

    return Math.abs(value1 - value2) / Math.abs(sum / 2);
}
