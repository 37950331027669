import { lte } from '@/services/floatComparison';
import { modelService } from '@/services/form/modelService';
import {
    isPluginGeneratingPlant,
    isPv,
} from '@/services/form/rules/application/common';
import {
    formatGermanNumber,
    parseGermanFloat,
} from '@/utilities/germanFormats';
import { helpers, ValidationRule } from 'vuelidate/lib/validators';

export const maxNominalPowerPlugInGeneratingPlant = (
    max: number,
): ValidationRule => {
    return helpers.withParams(
        {
            type: 'maxNominalPowerPlugInGeneratingPlant',
            max: formatGermanNumber(max, { decimals: 2 }),
        },
        (value: string) => {
            const application = modelService.model.application;
            const parsedValue =
                (typeof value == 'number' ? value : parseGermanFloat(value)) /
                1000;
            return (
                !isPluginGeneratingPlant(application) ||
                !isPv(application) ||
                lte(parsedValue, max)
            );
        },
    );
};
