import Vue from 'vue';
import {
    cloneDeep as _cloneDeep,
    mergeWith as _mergeWith,
    difference as _difference,
    keys as _keys,
    get as _get,
    set as _set,
} from 'lodash';
import { formsConfig } from './config';
import { Forms } from '@/types/forms/forms';
import { FormDto } from './dto';
import { FormField } from '@/types/forms/fields/formField';
import { FormList } from '@/types/forms/fields/formList';
import { Form } from '@/types/forms/form';
import { ValidationGroups, ValidationProperties } from 'vue/types/vue';
import { Validation } from 'vuelidate';
import { createFormValidations } from './validations';
import { FormFieldWithOptions } from '@/types/forms/fields/formFieldWithOptions';
import { FormElement } from '@/types/forms/formElement';
import { rules } from './rules';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import { FormStep } from '@/types/forms/formStep';
import { applicationRules } from '@/services/form/rules/application';

/**
 * Customizes value setting during a lodash mergeWith
 */
function formCustomizer(objValue: unknown, srcValue: unknown) {
    if (objValue instanceof FormList) {
        if (!Array.isArray(srcValue)) {
            throw 'SrcValue is not an array';
        }

        srcValue.forEach((item, index) => {
            if (!objValue.items[index]) {
                objValue.createItem();
            }

            _mergeWith(objValue.items[index], item, formCustomizer);
        });

        return objValue;
    } else if (objValue instanceof FormField) {
        objValue.value = srcValue;
        return objValue;
    }
    return undefined;
}

/**
 * Handles instantiation of the model by creating writable copies of the form config
 */
class ModelService {
    private _model: Forms;
    private _validations: ValidationProperties<Vue> &
        ValidationGroups &
        Validation;

    constructor() {
        this._model = this.createModel();
        this._validations = createFormValidations(this._model);
    }

    /**
     * Creates a deep clone of the model
     */
    public getModelClone(): Forms {
        return this.createClone(this._model);
    }

    /**
     * Repopulates the model and validations to reset model state
     */
    public resetModel(formReference?: Vue) {
        this._model = this.createModel();
        this._validations = createFormValidations(this._model, formReference);
    }

    /**
     * The main form state object. Writable copy of config.ts and source for dto.ts and validations.ts.
     */
    get model(): Forms {
        return this._model;
    }

    /**
     * The generated validations for the current model state
     */
    get validations(): ValidationProperties<Vue> &
        ValidationGroups &
        Validation {
        return this._validations;
    }

    /**
     * Main method for loading data from a dto into the model.
     * @param dto See dto.ts
     * @param path The property name of the form to use on the model. Can also be a path to reach subproperties.
     */
    public loadFormIntoModel(dto: FormDto, path: string): void {
        const obj = _get(this._model, path);
        _mergeWith(obj, dto, formCustomizer);
        _set(this._model, path, obj);
    }

    /**
     * Perform migrations on raw form data before loading it into the model.
     * Specific to ApplicationForm model. Create new methods for other forms.
     * You also need to add new migrations to the backend: ApplicationRepository.ApplyDataMigrations()
     * To add a new migration, you also need to change the main data version in src\services\form\config\application.ts
     * @param dto See dto.ts
     */
    public migrateApplicationFormData(dto: FormDto): FormDto {
        if (dto.serverSideMigration) {
            // Server-side migration happened. So the data is already migrated, but maybe we need to reset the current step or something else.
            const version = dto.version as number;
            switch (true) {
                case version >= 1 && version <= 9: // 1-9
                    dto.currentStep = null;
                    break;
            }
        }

        return dto;
    }

    /**
     * Removes any data that may exist in the model passed the current step
     * @param stepId The current step as an index of the steps array
     * @param formKey The property name of the form to use on the model
     */
    public removeDataAfterCurrentStep(
        stepId: string,
        formKey: keyof Forms,
    ): void {
        const currentForm = this._model[formKey];
        const currentConfig = this.createModel()[formKey];
        const currentStepIndex = currentForm.steps.indexOf(stepId);

        for (let index = 0; index < currentForm.steps.length; index++) {
            const currentStepId = currentForm.steps[index];
            if (index > currentStepIndex) {
                _mergeWith(
                    currentForm[currentStepId as keyof Form],
                    currentConfig[currentStepId as keyof Form],
                );
            }
        }
    }

    /**
     * Resets disabled state in the model passed the current step
     * @param stepId The current step as an index of the steps array
     * @param formKey The property name of the form to use on the model
     */
    public resetStateAfterCurrentStep(
        stepId: string,
        formKey: keyof Forms,
        formReference?: Vue,
    ): void {
        const currentForm = this._model[formKey];
        const currentFormsConfig = this.createModel();
        const currentConfig = currentFormsConfig[formKey];
        const currentStepIndex = currentForm.steps.indexOf(stepId);
        const currentValidations = modelService.validations.application;
        const initialValidations = createFormValidations(
            currentFormsConfig,
            formReference,
        ).application;

        if (currentForm instanceof ApplicationForm) {
            // Only for application form. Not needed for kitchen sink. For future other forms we have to adjust the logic.

            for (let index = 0; index < currentForm.steps.length; index++) {
                const currentStepId = currentForm.steps[index];
                const currentStepIdFormKey = currentStepId as keyof Form;
                const currentStepIdApplicationRulesKey = currentStepId as keyof typeof applicationRules;

                // if step comes after edited step
                if (index > currentStepIndex) {
                    _mergeWith(
                        currentForm[currentStepIdFormKey],
                        currentConfig[currentStepIdFormKey],
                        (model, config) => {
                            // reset disabled for all field groups ...
                            if (
                                model instanceof FormElement &&
                                config instanceof FormElement
                            ) {
                                model.disabled = config.disabled;
                            }

                            // ... and fields
                            if (
                                model instanceof FormFieldWithOptions &&
                                config instanceof FormFieldWithOptions
                            ) {
                                model.options.forEach((modelOption) => {
                                    const matchingConfigOption = config.options.find(
                                        (configOption) =>
                                            configOption.key ===
                                            modelOption.key,
                                    );
                                    if (matchingConfigOption) {
                                        modelOption.disabled =
                                            matchingConfigOption.disabled;
                                    }
                                });
                            }

                            return model;
                        },
                    );

                    const currentFormStepModel =
                        currentForm[currentStepIdFormKey];

                    if (!(currentFormStepModel instanceof FormStep)) {
                        return; // ApplicationForm contains applicationId etc. so we need to tell TypeScript that we only use FormStep types.
                    }

                    // loop validation keys, check if any keys were removed and delete them
                    for (const key of _keys(
                        currentValidations[currentStepIdFormKey],
                    )) {
                        const currentValidationStep =
                            currentValidations[currentStepIdFormKey];
                        const initialValidationStep =
                            initialValidations[currentStepIdFormKey];

                        if (!currentValidationStep || !initialValidationStep) {
                            continue;
                        }

                        const currentVal = currentValidationStep[key];
                        const initialVal = initialValidationStep[key];
                        const currentValKeys = _keys(currentVal);
                        const initialValKeys = _keys(initialVal);
                        const diff = _difference(
                            currentValKeys,
                            initialValKeys,
                        );

                        for (const diffKey of diff) {
                            delete currentVal[diffKey];
                            // TODO: check if diff works in both directions. What if prop exists in initialVal but was removed in currentVal?
                        }

                        _mergeWith(currentVal, initialVal);
                    }

                    const stepRules = rules.application[
                        currentStepIdApplicationRulesKey
                    ] as (formStep: {
                        model: typeof currentFormStepModel;
                        formModel: ApplicationForm;
                    }) => void;

                    // run step rules
                    stepRules({
                        model: currentFormStepModel,
                        formModel: currentForm,
                    });

                    _mergeWith(
                        currentForm[currentStepIdFormKey],
                        currentConfig[currentStepIdFormKey],
                        (model, config) => {
                            // set value to default value for disabled fields
                            if (
                                model instanceof FormFieldWithOptions &&
                                config instanceof FormFieldWithOptions &&
                                model.disabled
                            ) {
                                model.value = config.value;
                            }

                            return model;
                        },
                    );
                }
            }
        }
    }

    private createModel() {
        return this.createClone(formsConfig);
    }

    private createClone(cloneSource: Forms): Forms {
        return Vue.observable(_cloneDeep(cloneSource));
    }
}

export const modelService = new ModelService();
