import { getOverallActivePower } from '@/services/form/utilities/application/plantPerformance';
import { Forms } from '@/types/forms/forms';
import { parseGermanFloat } from '@/utilities/germanFormats';
import { roundToDecimals } from '@/utilities/math';
import { CombinedVueInstance } from 'vue/types/vue';
import { ValidationRule } from 'vuelidate/lib/validators';

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const mustBeLowerThanFeedInPowerRecommendation = function <
    Instance extends Vue
>(
    this: CombinedVueInstance<
        Instance,
        { model: Forms },
        unknown,
        unknown,
        unknown
    >,
    value: string,
) {
    const plantPerformanceModel = this.model.application.plantPerformance;

    const overallActivePower =
        getOverallActivePower(
            this.model.application.applicationType,
            plantPerformanceModel,
        ) ?? 0;

    const existingPlantsTotalPower = parseGermanFloat(
        !this.model.application.powerConnection.existingPlantsTotalPower
            .disabled
            ? this.model.application.powerConnection.existingPlantsTotalPower
                  .value ?? '0'
            : '0',
    );
    const repoweringTotalPower = parseGermanFloat(
        !this.model.application.powerConnection.repoweringTotalPower.disabled
            ? this.model.application.powerConnection.repoweringTotalPower
                  .value ?? '0'
            : '0',
    );
    const recommendation = roundToDecimals(
        overallActivePower + existingPlantsTotalPower - repoweringTotalPower,
    );

    return parseGermanFloat(value) <= recommendation;
} as ValidationRule;
