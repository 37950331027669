import {
    integer,
    minValue,
    maxValue,
    ValidationRule,
} from 'vuelidate/lib/validators';
import { lt } from './lt';
import { float } from './float';
import { positive } from './positive';
import { requiredIfAny } from './requiredIfAny';
import { GeoOrientation } from '@/types/forms/fields/geoCoordinatesFormGroup';

export function getDegreesValidators(
    orientation: GeoOrientation,
): Record<string, ValidationRule | (() => ValidationRule)> {
    const limit = orientation === GeoOrientation.latitude ? 90 : 180;
    return {
        requiredIfAny,
        integer,
        minValue: minValue(-1 * limit),
        maxValue: maxValue(limit),
    };
}

export function getMinutesValidators(): Record<
    string,
    ValidationRule | (() => ValidationRule)
> {
    return {
        requiredIfAny,
        integer,
        positive,
        lt: lt(60),
    };
}

export function getSecondsValidators(): Record<
    string,
    ValidationRule | (() => ValidationRule)
> {
    return {
        requiredIfAny,
        float,
        positive,
        lt: lt(60),
    };
}
