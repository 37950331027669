import { CheckboxField } from '../fields/checkboxField';
import { OwnerFormListType } from '../formGroups/ownerFormList';
import { FormStep } from '../formStep';
import { WithOptional } from '../utility';

type ConfigProps = WithOptional<PropertyOwnerFormStep, keyof FormStep>;

export class PropertyOwnerFormStep extends FormStep {
    public readonly propertyOwnerAgreement: CheckboxField;
    public readonly owners: OwnerFormListType;

    public constructor(config: ConfigProps) {
        super(config);

        this.propertyOwnerAgreement = config.propertyOwnerAgreement;
        this.owners = config.owners;
    }
}
