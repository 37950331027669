import { CountField } from '../../../fields/countField';
import { FormField } from '../../../fields/formField';
import { TextField } from '@/types/forms/fields/textField';
import { FormElementConfig } from '../../../formElement';
import { FormGroup } from '../../../formGroup';
import { ManufacturerAndModuleTypeFormGroup } from '../manufacturerAndModuleTypeFormGroup';
import { ActivePowerAndApparentPowerFormGroup } from '../activePowerAndApparentPowerFormGroup';
import { ReferenceFormGroup } from '../../referenceFormGroup';

export type InverterModuleFormGroupConfig = FormElementConfig &
    Omit<InverterModuleFormGroup, keyof FormGroup>;

export class InverterModuleFormGroup extends ReferenceFormGroup {
    public readonly inverterManufacturerAndModuleType: ManufacturerAndModuleTypeFormGroup;
    public readonly inverterQuantity: CountField;
    public readonly inverterContributionSymmetricalShortCircuitCurrent: TextField;
    public readonly inverterVoltageSymmetricalShortCircuitCurrent: TextField;
    public readonly inverterContributionSustainedShortCircuitCurrent: TextField;
    public readonly inverterVoltageSustainedShortCircuitCurrent: TextField;
    public readonly inverterActivePowerAndApparentPower: ActivePowerAndApparentPowerFormGroup;
    public readonly inverterTotalActivePower: FormField<number>;
    public readonly inverterTotalApparentPower: FormField<number>;

    public constructor(config: InverterModuleFormGroupConfig) {
        super(config);

        this.inverterManufacturerAndModuleType =
            config.inverterManufacturerAndModuleType;
        this.inverterQuantity = config.inverterQuantity;
        this.inverterContributionSymmetricalShortCircuitCurrent =
            config.inverterContributionSymmetricalShortCircuitCurrent;
        this.inverterVoltageSymmetricalShortCircuitCurrent =
            config.inverterVoltageSymmetricalShortCircuitCurrent;
        this.inverterContributionSustainedShortCircuitCurrent =
            config.inverterContributionSustainedShortCircuitCurrent;
        this.inverterVoltageSustainedShortCircuitCurrent =
            config.inverterVoltageSustainedShortCircuitCurrent;
        this.inverterActivePowerAndApparentPower =
            config.inverterActivePowerAndApparentPower;
        this.inverterTotalActivePower = config.inverterTotalActivePower;
        this.inverterTotalApparentPower = config.inverterTotalApparentPower;
    }
}
