import Vue from 'vue';
import { FormField } from '@/types/forms/fields/formField';

export function removeRequiredValidator(formField: FormField<unknown>): void {
    if (
        Object.prototype.hasOwnProperty.call(formField.validators, 'required')
    ) {
        Vue.delete(formField.validators, 'required');
    }
}
