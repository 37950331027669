import { CheckboxField } from '../fields/checkboxField';
import { DateField } from '../fields/dateField';
import { RadioButtonsField } from '../fields/radioButtonsField';
import { SelectField } from '../fields/selectField';
import { TextField } from '../fields/textField';
import { FormElement } from '../formElement';
import { FormStep } from '../formStep';
import { NewOrExistingApplicationValueType } from '../shared/newOrExistingApplicationValueType';
import { NewOrExistingPlantValueType } from '../shared/newOrExistingPlantValueType';
import { PlantBuildingTypeValueType } from '../shared/plantBuildingTypeValueType';
import { PlantPositionValueType } from '../shared/plantPositionValueType';
import { PowerHeatCouplingPlantTypeValueType } from '../shared/powerHeatCouplingPlantTypeValueType';
import { PvInstallationTypeValueType } from '../shared/pvInstallationTypeValueType';
import { PvInstallationTypeForAgriculturalLandOrParkingLotsValueType } from '../shared/pvInstallationTypeForAgriculturalLandOrParkingLotsValueType';
import { StorageBalancingEnergyMarketParticipationTypeValueType } from '../shared/storageBalancingEnergyMarketParticipationTypeValueType';
import { StorageChargeValueType } from '../shared/storageChargeValueType';
import { StorageConnectionConceptValueType } from '../shared/storageConnectionConceptValueType';
import { StorageCouplingValueType } from '../shared/storageCouplingValueType';
import { StorageDischargeValueType } from '../shared/storageDischargeValueType';
import { WithOptional } from '../utility';
import { InfeedManagementFormGroup } from '../formGroups/plantData/infeedManagement/infeedManagementFormGroup';

type ConfigProps = WithOptional<PlantDataFormStep, keyof FormStep>;

export class PlantDataFormStep extends FormStep {
    public readonly newOrExistingApplication: RadioButtonsField<
        NewOrExistingApplicationValueType
    >;
    public readonly applicationNumber: TextField;
    public readonly newOrExistingPlant: RadioButtonsField<
        NewOrExistingPlantValueType
    >;
    public readonly powerHeatCouplingPlantType: RadioButtonsField<
        PowerHeatCouplingPlantTypeValueType
    >;
    public readonly powerHeatCouplingPaymentsBasedOnLawHint: FormElement;
    public readonly bafaApprovalWillBeSubmittedLater: CheckboxField;
    public readonly plantWasAlreadyCommissioned: CheckboxField;
    public readonly initialCommissioningDate: DateField;

    public readonly plantBuildingType: RadioButtonsField<
        PlantBuildingTypeValueType
    >;
    public readonly plantPosition: RadioButtonsField<PlantPositionValueType>;
    public readonly pvInstallationType: RadioButtonsField<
        PvInstallationTypeValueType
    >;
    public readonly pvInstallationTypeForAgriculturalLandOrParkingLots: RadioButtonsField<
        PvInstallationTypeForAgriculturalLandOrParkingLotsValueType
    >;
    public readonly storageConnection: FormElement;
    public readonly storageCoupling: SelectField<StorageCouplingValueType | ''>;
    public readonly storageConnectionConcept: RadioButtonsField<
        StorageConnectionConceptValueType
    >;
    public readonly electricityTaxExemptionHint: FormElement;
    public readonly tenantElectricitySurcharge: CheckboxField;
    public readonly requirementTenantElectricitySurchargeHint: FormElement;
    public readonly minimumRequirementLivingSpace: CheckboxField;
    public readonly electricityConsumptionWithinThisBuilding: CheckboxField;
    public readonly noAdditionalNetworkForEndConsumer: CheckboxField;
    public readonly federalNetworkAgencyHint: FormElement;
    public readonly proofOfRegistrationPhotovoltaic: CheckboxField;
    public readonly proofOfAllocationOfTenantElectricitySurcharge: CheckboxField;
    public readonly declarationEnwgHint: FormElement;
    public readonly plantOperatorKnowsEnwgSpecifications: CheckboxField;
    public readonly storageCharge: SelectField<StorageChargeValueType>;
    public readonly storageDischarge: SelectField<StorageDischargeValueType>;
    public readonly storagePublicDischargeWarning: FormElement;
    public readonly storageBalancingEnergyMarketParticipation: CheckboxField;
    public readonly storageBalancingEnergyMarketParticipationType: RadioButtonsField<
        StorageBalancingEnergyMarketParticipationTypeValueType
    >;
    public readonly infeedManagement: InfeedManagementFormGroup;

    public constructor(config: ConfigProps) {
        super(config);

        this.newOrExistingApplication = config.newOrExistingApplication;
        this.applicationNumber = config.applicationNumber;
        this.newOrExistingPlant = config.newOrExistingPlant;
        this.powerHeatCouplingPlantType = config.powerHeatCouplingPlantType;
        this.powerHeatCouplingPaymentsBasedOnLawHint =
            config.powerHeatCouplingPaymentsBasedOnLawHint;
        this.bafaApprovalWillBeSubmittedLater =
            config.bafaApprovalWillBeSubmittedLater;
        this.plantWasAlreadyCommissioned = config.plantWasAlreadyCommissioned;
        this.initialCommissioningDate = config.initialCommissioningDate;
        this.plantBuildingType = config.plantBuildingType;
        this.plantPosition = config.plantPosition;
        this.pvInstallationType = config.pvInstallationType;
        this.pvInstallationTypeForAgriculturalLandOrParkingLots =
            config.pvInstallationTypeForAgriculturalLandOrParkingLots;
        this.storageConnection = config.storageConnection;
        this.storageCoupling = config.storageCoupling;
        this.storageConnectionConcept = config.storageConnectionConcept;
        this.electricityTaxExemptionHint = config.electricityTaxExemptionHint;
        this.tenantElectricitySurcharge = config.tenantElectricitySurcharge;
        this.requirementTenantElectricitySurchargeHint =
            config.requirementTenantElectricitySurchargeHint;
        this.minimumRequirementLivingSpace =
            config.minimumRequirementLivingSpace;
        this.electricityConsumptionWithinThisBuilding =
            config.electricityConsumptionWithinThisBuilding;
        this.noAdditionalNetworkForEndConsumer =
            config.noAdditionalNetworkForEndConsumer;
        this.federalNetworkAgencyHint = config.federalNetworkAgencyHint;
        this.proofOfRegistrationPhotovoltaic =
            config.proofOfRegistrationPhotovoltaic;
        this.proofOfAllocationOfTenantElectricitySurcharge =
            config.proofOfAllocationOfTenantElectricitySurcharge;
        this.declarationEnwgHint = config.declarationEnwgHint;
        this.plantOperatorKnowsEnwgSpecifications =
            config.plantOperatorKnowsEnwgSpecifications;
        this.storageCharge = config.storageCharge;
        this.storageDischarge = config.storageDischarge;
        this.storagePublicDischargeWarning =
            config.storagePublicDischargeWarning;
        this.storageBalancingEnergyMarketParticipation =
            config.storageBalancingEnergyMarketParticipation;
        this.storageBalancingEnergyMarketParticipationType =
            config.storageBalancingEnergyMarketParticipationType;
        this.infeedManagement = config.infeedManagement;
    }
}
