import { CountField } from '@/types/forms/fields/countField';
import { FormList } from '@/types/forms/fields/formList';
import { TextField } from '@/types/forms/fields/textField';
import { PhotovoltaicModuleFormGroup } from '@/types/forms/formGroups/plantPerformance/photovoltaic/photovoltaicModuleFormGroup';
import { QuantityAndNominalPowerFormGroup } from '@/types/forms/formGroups/plantPerformance/photovoltaic/quantityAndNominalPowerFormGroup';
import { required } from 'vuelidate/lib/validators';
import { float } from '@/validators/float';
import { positive } from '@/validators/positive';
import { FormField } from '@/types/forms/fields/formField';
import { PhotovoltaicFormGroup } from '@/types/forms/formGroups/plantPerformance/photovoltaic/photovoltaicFormGroup';
import { FormElement } from '@/types/forms/formElement';
import { restrictedNominalPower } from './boundaryValues';
import { maxNominalPowerPlugInGeneratingPlant } from '@/validators/maxNominalPowerPlugInGeneratingPlant';

export const photovoltaicFormGroup = new PhotovoltaicFormGroup({
    pvHeadline: new FormElement(),
    pvModules: new FormList(PhotovoltaicModuleFormGroup, {
        initializeItems: 1,
        minItemCount: 1,
        maxItemCount: 2,
        id: new FormField<string>(),
        pvQuantityAndNominalPower: new QuantityAndNominalPowerFormGroup({
            quantity: new CountField({
                value: 1,
                min: 1,
                validators: {
                    required,
                },
            }),
            nominalPower: new TextField({
                validators: {
                    required,
                    float,
                    positive,
                },
            }),
        }),
        pvTotalNominalPower: new FormField({
            value: 0,
            validators: {
                maxNominalPowerPlugInGeneratingPlant: maxNominalPowerPlugInGeneratingPlant(
                    restrictedNominalPower,
                ),
            },
        }),
    }),
    pvOverallNominalPower: new FormField({
        value: 0,
    }),
});
