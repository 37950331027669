import { TextField } from '../../../fields/textField';
import { FormGroup } from '../../../formGroup';
import { WithOptional } from '../../../utility';

type ConfigProps = WithOptional<
    DischargePowerAndStorageCapacityFormGroup,
    keyof FormGroup
>;

export class DischargePowerAndStorageCapacityFormGroup extends FormGroup {
    public readonly dischargePower: TextField;
    public readonly storageCapacity: TextField;

    public constructor(config: ConfigProps) {
        super(config);

        this.dischargePower = config.dischargePower;
        this.storageCapacity = config.storageCapacity;
    }
}
