import { TextField } from '../fields/textField';
import { FormGroup } from '../formGroup';
import { WithOptional } from '../utility';

type ConfigProps = WithOptional<MeadowAndLotFormGroup, keyof FormGroup>;

export class MeadowAndLotFormGroup extends FormGroup {
    public readonly meadow: TextField;
    public readonly lot: TextField;

    public constructor(config: ConfigProps) {
        super(config);

        this.meadow = config.meadow;
        this.lot = config.lot;
    }
}
