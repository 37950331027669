import { helpers } from 'vuelidate/lib/validators';

export const requiredIfAny = helpers.withParams(
    { type: 'requiredIf' },
    function (this: unknown, value: unknown, parentVm: Vue) {
        for (const prop in parentVm) {
            if (helpers.ref(prop, this, parentVm)) {
                return helpers.req(value);
            }
        }
        return true;
    },
);
