import { TextField } from '../../../fields/textField';
import { FormGroup } from '../../../formGroup';
import { WithOptional } from '../../../utility';

type ConfigProps = WithOptional<
    ManufacturerAndStorageTypeFormGroup,
    keyof FormGroup
>;

export class ManufacturerAndStorageTypeFormGroup extends FormGroup {
    public readonly manufacturer: TextField;
    public readonly storageType: TextField;

    public constructor(config: ConfigProps) {
        super(config);

        this.manufacturer = config.manufacturer;
        this.storageType = config.storageType;
    }
}
