import { isArray as _isArray } from 'lodash';
import { helpers, ValidationRule } from 'vuelidate/lib/validators';

export function length(length: number): ValidationRule {
    return helpers.withParams(
        { type: 'length', length },
        (value: number | string | unknown[]) => {
            if (!helpers.req(value)) {
                return true;
            }

            const valueWithLength = _isArray(value) ? value : `${value}`;
            return valueWithLength.length === length;
        },
    );
}
