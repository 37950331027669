import { CountField } from '@/types/forms/fields/countField';
import { SelectField } from '@/types/forms/fields/selectField';
import { FormGroup } from '@/types/forms/formGroup';
import { GeneratorTypeFieldType } from '@/types/forms/shared/generatorTypeFieldType';
import { WithOptional } from '@/types/forms/utility';

type ConfigProps = WithOptional<
    QuantityAndGeneratorTypeFormGroup,
    keyof FormGroup
>;

export class QuantityAndGeneratorTypeFormGroup extends FormGroup {
    public readonly quantity: CountField;
    public readonly generatorType: SelectField<GeneratorTypeFieldType>;

    public constructor(config: ConfigProps) {
        super(config);

        this.quantity = config.quantity;
        this.generatorType = config.generatorType;
    }
}
