import { CheckboxField } from '../fields/checkboxField';
import { TextField } from '../fields/textField';
import { FormElement } from '../formElement';
import { FormStep } from '../formStep';
import { WithOptional } from '../utility';

type ConfigProps = WithOptional<CheckAndSendFormStep, keyof FormStep>;

export class CheckAndSendFormStep extends FormStep {
    public readonly checkNotice: FormElement;
    public readonly comments: TextField;
    public readonly summaryDocument: FormElement;
    public readonly privacyInformation: CheckboxField;
    public readonly generalTermsConsent: CheckboxField;
    public readonly correctnessNotice: FormElement;

    public constructor(config: ConfigProps) {
        super(config);

        this.checkNotice = config.checkNotice;
        this.comments = config.comments;
        this.summaryDocument = config.summaryDocument;
        this.privacyInformation = config.privacyInformation;
        this.generalTermsConsent = config.generalTermsConsent;
        this.correctnessNotice = config.correctnessNotice;
    }
}
