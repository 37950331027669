import { DateField } from '@/types/forms/fields/dateField';
import { FormList } from '@/types/forms/fields/formList';
import { RadioButtonsField } from '@/types/forms/fields/radioButtonsField';
import { SelectField } from '@/types/forms/fields/selectField';
import { TextField } from '@/types/forms/fields/textField';
import { FirstAndLastNameFormGroup } from '@/types/forms/formGroups/firstAndLastNameFormGroup';
import { OperatorFormGroup } from '@/types/forms/formGroups/operatorFormGroup';
import { StreetAndHousenumberFormGroup } from '@/types/forms/formGroups/streetAndHousenumberFormGroup';
import { ZipAndCityFormGroup } from '@/types/forms/formGroups/zipAndCityFormGroup';
import { FormOption } from '@/types/forms/formOption';
import { PlantOperatorFormStep } from '@/types/forms/formSteps/plantOperatorFormStep';
import { maxDateNow } from '@/validators/maxDateNow';
import { name } from '@/validators/name';
import { houseNumber } from '@/validators/houseNumber';
import { positiveInteger } from '@/validators/positiveInteger';
import { email, maxLength, required } from 'vuelidate/lib/validators';
import { requiredDate } from '@/validators/requiredDate';
import { CheckboxField } from '@/types/forms/fields/checkboxField';
import { zip } from '@/validators/zip';

export const plantOperatorFormStep = new PlantOperatorFormStep({
    separatePropertyOwner: new RadioButtonsField({
        value: 'no',
        options: [
            new FormOption({ key: 'no' }),
            new FormOption({ key: 'yes' }),
        ],
        validators: {
            required,
        },
    }),
    plantBuilder: new RadioButtonsField({
        value: 'operator',
        options: [
            new FormOption({ key: 'operator' }),
            new FormOption({ key: 'contractor' }),
        ],
        validators: {
            required,
        },
    }),
    operators: new FormList(OperatorFormGroup, {
        initializeItems: 1,
        minItemCount: 1,
        maxItemCount: 2,
        salutation: new RadioButtonsField({
            value: 'mr',
            options: [
                new FormOption({ key: 'mr' }),
                new FormOption({ key: 'mrs' }),
                new FormOption({ key: 'company' }),
            ],
            validators: {
                required,
            },
        }),
        companyName: new TextField({
            validators: {
                required,
            },
        }),
        title: new SelectField({
            value: 'untitled',
            options: [
                new FormOption({ key: 'untitled' }),
                new FormOption({ key: 'dr' }),
                new FormOption({ key: 'prof' }),
                new FormOption({ key: 'profDr' }),
            ],
        }),
        operatorName: new FirstAndLastNameFormGroup({
            firstName: new TextField({
                validators: {
                    required,
                    name,
                },
            }),
            lastName: new TextField({
                validators: {
                    required,
                    name,
                },
            }),
        }),
        contactPersonName: new FirstAndLastNameFormGroup({
            firstName: new TextField({
                validators: {
                    required,
                    name,
                },
            }),
            lastName: new TextField({
                validators: {
                    required,
                    name,
                },
            }),
        }),
        birthday: new DateField({
            validators: {
                requiredDate,
                maxDateNow,
            },
        }),
    }),
    country: new SelectField({
        value: 'deu',
        options: [
            new FormOption({ key: 'deu' }),
            new FormOption({ key: 'aut' }),
            new FormOption({ key: 'dnk' }),
            new FormOption({ key: 'fra' }),
            new FormOption({ key: 'nld' }),
            new FormOption({ key: 'pol' }),
            new FormOption({ key: 'che' }),
        ],
    }),
    usePlantLocation: new CheckboxField(),
    zipAndCity: new ZipAndCityFormGroup({
        zip: new TextField({
            validators: {
                required,
                zip: zip('application.plantOperator.country'),
            },
        }),
        city: new TextField({
            validators: {
                required,
            },
        }),
    }),
    streetAndHousenumber: new StreetAndHousenumberFormGroup({
        street: new TextField({
            validators: {
                required,
            },
        }),
        housenumber: new TextField({
            validators: {
                required,
                houseNumber,
                maxLength: maxLength(8),
            },
        }),
    }),
    email: new TextField({
        validators: {
            required,
            email,
        },
    }),
    phoneNumber1: new TextField({
        validators: {
            required,
            phoneNumber: positiveInteger,
        },
    }),
    phoneNumber2: new TextField({
        validators: {
            phoneNumber: positiveInteger,
        },
    }),
});
