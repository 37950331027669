import { CountField } from '../../../fields/countField';
import { TextField } from '../../../fields/textField';
import { FormGroup } from '../../../formGroup';
import { WithOptional } from '../../../utility';

type ConfigProps = WithOptional<
    QuantityAndNominalPowerFormGroup,
    keyof FormGroup
>;

export class QuantityAndNominalPowerFormGroup extends FormGroup {
    public readonly quantity: CountField;
    public readonly nominalPower: TextField;

    public constructor(config: ConfigProps) {
        super(config);

        this.quantity = config.quantity;
        this.nominalPower = config.nominalPower;
    }
}
