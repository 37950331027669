import Vue from 'vue';
import { Forms } from '@/types/forms/forms';
import { applicationForm } from './config/application';
import { kitchenSinkForm } from './config/kitchenSink';

/**
 * The main forms config object. Should be used as static readonly config, not as form state. See model.ts for that.
 */
export const formsConfig = Vue.observable(
    new Forms({
        kitchenSink: kitchenSinkForm,
        application: applicationForm,
    }),
);
