import { FormField } from '@/types/forms/fields/formField';
import { FormElement } from '@/types/forms/formElement';
import { FormGroup } from '@/types/forms/formGroup';
import { WithOptional } from '@/types/forms/utility';
import { GeneratorModuleFormList } from './generatorModuleFormList';
import { alwaysInDtoAttribute } from '@/types/forms/alwaysinDtoAttribute';

type ConfigProps = WithOptional<GeneratorFormGroup, keyof FormGroup>;

export class GeneratorFormGroup extends FormGroup {
    public readonly generatorHeadline: FormElement;
    public readonly generatorModules: GeneratorModuleFormList;
    @alwaysInDtoAttribute()
    public readonly generatorOverallActivePower: FormField<number>;
    @alwaysInDtoAttribute()
    public readonly generatorOverallApparentPower: FormField<number>;

    public constructor(config: ConfigProps) {
        super(config);

        this.generatorHeadline = config.generatorHeadline;
        this.generatorModules = config.generatorModules;
        this.generatorOverallActivePower = config.generatorOverallActivePower;
        this.generatorOverallApparentPower =
            config.generatorOverallApparentPower;
    }
}
