import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import { CheckAndSendFormStep } from '@/types/forms/formSteps/checkAndSendFormStep';
import { isPluginGeneratingPlant, isRequestWithoutPlanning } from './common';

export const checkAndSendRules = async (
    formStep: { model: CheckAndSendFormStep; formModel: ApplicationForm } & Vue,
): Promise<void> => {
    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854573899/Formular+berpr+fen+Absenden#FormularÜberprüfen&Absenden-correctnessNotice
    formStep.model.correctnessNotice.disabled =
        isPluginGeneratingPlant(formStep.formModel) ||
        isRequestWithoutPlanning(formStep.formModel);

    formStep.model.generalTermsConsent.disabled = isRequestWithoutPlanning(
        formStep.formModel,
    );
};
