import { PropertyOwnerFormStep } from '@/types/forms/formSteps/propertyOwnerFormStep';

export const propertyOwnerRules = async (
    formStep: { model: PropertyOwnerFormStep } & Vue,
): Promise<void> => {
    formStep.model.owners.items.forEach((owner) => {
        // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854571973/Formular+Grundst+ckseigent+mer#FormularGrundstückseigentümer-companyName
        owner.companyName.disabled = owner.salutation.value !== 'company';

        // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854571973/Formular+Grundst+ckseigent+mer#FormularGrundstückseigentümer-ownerName.firstName
        // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854571973/Formular+Grundst+ckseigent+mer#FormularGrundstückseigentümer-ownerName.lastName
        owner.ownerName.disabled = owner.salutation.value === 'company';

        // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854571973/Formular+Grundst+ckseigent+mer#FormularGrundstückseigentümer-contactPerson.firstName
        // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854571973/Formular+Grundst+ckseigent+mer#FormularGrundstückseigentümer-contactPerson.lastName
        owner.contactPersonName.disabled = owner.salutation.value !== 'company';

        // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854571973/Formular+Grundst+ckseigent+mer#FormularGrundstückseigentümer-birthday
        owner.birthday.disabled = owner.salutation.value === 'company';
    });
};
