import { FormElementConfig } from '../../formElement';
import { CheckboxField } from '../../fields/checkboxField';
import { FormGroup } from '../../formGroup';
import { TextField } from '../../fields/textField';

export type KitchenSinkPlantFormGroupConfig = FormElementConfig &
    Omit<KitchenSinkPlantFormGroup, keyof FormGroup>;

export class KitchenSinkPlantFormGroup extends FormGroup {
    public readonly number: TextField;
    public readonly checkbox: CheckboxField;

    public constructor(config: KitchenSinkPlantFormGroupConfig) {
        super(config);

        this.number = config.number;
        this.checkbox = config.checkbox;
    }
}
