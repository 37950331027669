import { DocumentsFormStep } from '@/types/forms/formSteps/documentsFormStep';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import {
    isPluginGeneratingPlant,
    isRequestWithPlanning,
    isRequestWithoutPlanning,
    isStorage,
    isStorageConnectionConceptStorageInGenerationPath,
    isTotalPlantPerformanceAbove300,
    isTotalPlantPerformanceBelow300,
} from './common';
import Vue from 'vue';
import { FormList } from '@/types/forms/fields/formList';
import { ReferenceFormGroup } from '@/types/forms/formGroups/referenceFormGroup';
import { FormField } from '@/types/forms/fields/formField';
import { toggleRequiredValidator } from '@/utilities/toggleRequiredValidator';
import {
    getOverallActivePower,
    getOverallApparentPower,
} from '../../utilities/application/plantPerformance';

export const documentsRules = async (
    formStep: {
        model: DocumentsFormStep;
        formModel: ApplicationForm;
    } & Vue,
): Promise<void> => {
    const storageEnabled = isStorage(formStep.formModel);
    const hasLowVoltage =
        formStep.formModel.powerConnection.whichVoltageLevel.value ==
        'lowVoltage';
    const hasMediumVoltage =
        formStep.formModel.powerConnection.whichVoltageLevel.value ==
        'mediumVoltage';
    const hasUnknownVoltage =
        formStep.formModel.powerConnection.whichVoltageLevel.value == 'unknown';

    const apparentPower =
        getOverallApparentPower(
            formStep.formModel.applicationType,
            formStep.formModel.plantPerformance,
        ) ?? 0;
    const activePower =
        getOverallActivePower(
            formStep.formModel.applicationType,
            formStep.formModel.plantPerformance,
        ) ?? 0;

    const hasLvBelow30 = hasLowVoltage && apparentPower <= 30;
    const hasLvAbove30 = hasLowVoltage && apparentPower > 30;
    const hasMvBelow135 =
        hasMediumVoltage && apparentPower > 30 && activePower < 135;
    const hasMvAbove135 = hasMediumVoltage && activePower >= 135;
    const hasUnBelow300 = hasUnknownVoltage && activePower <= 300;
    const hasUnAbove300 = hasUnknownVoltage && activePower > 300;

    const requestWithPlanning = isRequestWithPlanning(formStep.formModel);
    const requestWithoutPlanning = isRequestWithoutPlanning(formStep.formModel);

    const hasTotalPlantPerformanceAbove300 = isTotalPlantPerformanceAbove300(
        formStep.formModel,
    );
    const hasTotalPlantPerformanceBelow300 = isTotalPlantPerformanceBelow300(
        formStep.formModel,
    );

    if (requestWithoutPlanning) {
        for (const field of Object.values(formStep.model)) {
            if (field instanceof FormField) {
                field.disabled = true;
            }

            if (field instanceof FormList) {
                field.disabled = true;

                for (const module of field.items) {
                    for (const moduleField of Object.values(module)) {
                        if (moduleField instanceof FormField) {
                            moduleField.disabled = true;
                        }
                    }
                }
            }

            formStep.model.sitePlanWithLotNumber.disabled = false;
        }
    } else {
        syncArray(
            formStep.formModel.plantPerformance.generator.generatorModules,
            formStep.model.generatorModules,
        );
        syncArray(
            formStep.formModel.plantPerformance.inverter.inverterModules,
            formStep.model.inverterModules,
        );
        syncArray(
            formStep.formModel.plantPerformance.storage.storageModules,
            formStep.model.storageModules,
        );

        await Vue.nextTick();

        // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565613/Formular+Erzeugungsart#FormularErzeugungsart-meterPicture
        formStep.model.meterPicture.disabled =
            formStep.formModel.generationType.plugInGeneratingPlant.disabled ||
            !formStep.formModel.generationType.plugInGeneratingPlant.value;

        // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-bafaApproval
        formStep.model.bafaApproval.disabled =
            formStep.formModel.plantData.bafaApprovalWillBeSubmittedLater
                .disabled ||
            formStep.formModel.plantData.bafaApprovalWillBeSubmittedLater
                .value === true;

        //https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854573319/Formular+Anlagenleistung#FormularAnlagenleistung-gridAndPlantProtectionProofOfConformity
        formStep.model.gridAndPlantProtectionProofOfConformity.disabled =
            isPluginGeneratingPlant(formStep.formModel) ||
            isTotalPlantPerformanceAbove300(formStep.formModel) ||
            (storageEnabled &&
                isStorageConnectionConceptStorageInGenerationPath(
                    formStep.formModel.plantData,
                )) ||
            hasMvAbove135 ||
            hasUnAbove300;

        toggleRequiredValidator(
            formStep.model.gridAndPlantProtectionProofOfConformity,
            hasLvAbove30 || hasMvBelow135 || hasUnBelow300,
        );

        // we have to wait one additional tick for the component watchers
        // to set the overall sums to the underlying models
        await Vue.nextTick();

        const isNotStorageOrNotPowerGenerationPlantWithStorageFacilityInTheGenerationPathAndConsumptionPlant =
            !storageEnabled ||
            formStep.formModel.plantData.storageConnectionConcept.value !==
                'powerGenerationPlantWithStorageFacilityInTheGenerationPathAndConsumptionPlant';

        // Generator
        for (const module of formStep.model.generatorModules.items) {
            // lv / <=300
            module.generatorConformityConfirmationOrUnitCertificate.disabled = !hasTotalPlantPerformanceBelow300;
            module.generatorProofOfConformity.disabled = !hasTotalPlantPerformanceBelow300;

            // mv / >300
            module.generatorUnitOrPrototypeConfirmation.disabled = !hasTotalPlantPerformanceAbove300;
            module.generatorComponentCertificate.disabled = !hasTotalPlantPerformanceAbove300;

            module.generatorProofOfConformity.disabled =
                !hasTotalPlantPerformanceBelow300 ||
                !isNotStorageOrNotPowerGenerationPlantWithStorageFacilityInTheGenerationPathAndConsumptionPlant ||
                isPluginGeneratingPlant(formStep.formModel) ||
                hasMvAbove135 ||
                hasUnAbove300;

            toggleRequiredValidator(
                module.generatorProofOfConformity,
                hasLvBelow30,
            );
        }

        // Inverter
        for (const module of formStep.model.inverterModules.items) {
            // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854573355/Feldgruppe+Wechselrichter+PV+und+Speicher+NS#FeldgruppeWechselrichterPVundSpeicherNS-conformityConfirmationOrUnitCertificate
            module.inverterConformityConfirmationOrUnitCertificate.disabled =
                !hasTotalPlantPerformanceBelow300 ||
                !isNotStorageOrNotPowerGenerationPlantWithStorageFacilityInTheGenerationPathAndConsumptionPlant ||
                isPluginGeneratingPlant(formStep.formModel);

            // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854573355/Feldgruppe+Wechselrichter+PV+und+Speicher+NS#FeldgruppeWechselrichterPVundSpeicherNS-technicalDataSheet
            module.inverterTechnicalDataSheet.disabled =
                !isNotStorageOrNotPowerGenerationPlantWithStorageFacilityInTheGenerationPathAndConsumptionPlant ||
                isPluginGeneratingPlant(formStep.formModel);

            // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854573355/Feldgruppe+Wechselrichter+PV+und+Speicher+NS#FeldgruppeWechselrichterPVundSpeicherNS-proofOfConformity
            module.inverterProofOfConformity.disabled =
                !hasTotalPlantPerformanceBelow300 ||
                !isNotStorageOrNotPowerGenerationPlantWithStorageFacilityInTheGenerationPathAndConsumptionPlant ||
                isPluginGeneratingPlant(formStep.formModel) ||
                hasMvAbove135 ||
                hasUnAbove300;

            toggleRequiredValidator(
                module.inverterProofOfConformity,
                hasLvBelow30,
            );

            // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4864934167/Feldgruppe+Wechselrichter+PV+und+Speicher+MS#FeldgruppeWechselrichterPVundSpeicherMS-unitCertificate
            module.inverterUnitCertificate.disabled =
                !hasTotalPlantPerformanceAbove300 ||
                isStorageConnectionConceptStorageInGenerationPath(
                    formStep.formModel.plantData,
                );
        }

        // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565649/Formular+Netzanschluss#FormularNetzanschluss-performanceMonitoringCertificate
        formStep.model.performanceMonitoringCertificate.disabled =
            formStep.formModel.powerConnection
                .feedInPowerShallBeLimitedByAController.disabled ||
            !(
                formStep.formModel.powerConnection
                    .feedInPowerShallBeLimitedByAController.value ?? false
            );

        // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565649/Formular+Netzanschluss#FormularNetzanschluss-electricalSystemCircuitDiagram
        formStep.model.electricalSystemCircuitDiagram.disabled =
            activePower <= 300;

        formStep.model.proofOfReadyForPlanning.disabled = !requestWithPlanning;

        formStep.model.generatorModules.disabled =
            formStep.formModel.plantPerformance.generator.disabled;
        formStep.model.inverterModules.disabled =
            formStep.formModel.plantPerformance.inverter.disabled ||
            isPluginGeneratingPlant(formStep.formModel);
        formStep.model.storageModules.disabled =
            formStep.formModel.plantPerformance.storage.disabled;

        formStep.model.sitePlanWithLotNumber.disabled = isPluginGeneratingPlant(
            formStep.formModel,
        );
    }
};

// eslint-disable-next-line @typescript-eslint/comma-dangle
function syncArray<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    T extends FormList<ReferenceFormGroup, any>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    K extends FormList<ReferenceFormGroup, any>
>(source: T, destination: K) {
    const sourceIds = new Set(
        source.items
            .map((item) => item.id?.value)
            .filter((item) => item != null),
    );

    for (let i = destination.items.length - 1; i >= 0; i--) {
        if (!sourceIds.has(destination.items[i].id?.value ?? '')) {
            destination.sudoRemoveAt(i);
        }
    }

    source.items.forEach((sourceItem) => {
        if (
            !destination.items.some(
                (destItem) => destItem.id?.value === sourceItem.id?.value,
            )
        ) {
            destination.createItem(sourceItem.id?.value ?? undefined);
        }
    });
}
