import { v4 as uuidv4 } from 'uuid';
import { FormField } from '../fields/formField';
import { FormGroup } from '../formGroup';
import { WithOptional } from '../utility';

type ConfigProps = WithOptional<ReferenceFormGroup, keyof FormGroup>;

export class ReferenceFormGroup extends FormGroup {
    public readonly id?: FormField<string>;

    public constructor(
        config: ConfigProps,
        id: string | undefined = undefined,
    ) {
        super(config);

        this.id = new FormField<string>({ value: id ?? uuidv4() });
    }
}
