import { TextField } from '../fields/textField';
import { FormGroup } from '../formGroup';
import { WithOptional } from '../utility';

type ConfigProps = WithOptional<FirstAndLastNameFormGroup, keyof FormGroup>;

export class FirstAndLastNameFormGroup extends FormGroup {
    public readonly firstName: TextField;
    public readonly lastName: TextField;

    public constructor(config: ConfigProps) {
        super(config);

        this.firstName = config.firstName;
        this.lastName = config.lastName;
    }
}
