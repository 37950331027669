import { FormOption } from '@/types/forms/formOption';
import { RadioButtonsField } from '@/types/forms/fields/radioButtonsField';
import { KitchenSinkFormStep2 } from '@/types/forms/dummy/kitchensinkFormStep2';

export const kitchenSinkFormStep2 = new KitchenSinkFormStep2({
    selected: new RadioButtonsField({
        value: 'opt1',
        options: [
            new FormOption({ key: 'opt1' }),
            new FormOption({ key: 'opt2' }),
            new FormOption({ key: 'opt3' }),
        ],
        validators: {
            option3RightAnswer: (value: string): boolean => value === 'opt3',
        },
    }),
    selectedTiles: new RadioButtonsField({
        value: 'netzanschluss',
        options: [
            new FormOption({ key: 'netzanschluss' }),
            new FormOption({ key: 'einspeiseleistung' }),
            new FormOption({ key: 'verguetungen' }),
        ],
        validators: {
            everybodyIsInterestedInNetzanschluessen: (value: string): boolean =>
                value === 'netzanschluss',
        },
    }),
});
