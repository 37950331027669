import { KitchenSinkForm } from '@/types/forms/dummy/kitchenSinkForm';
import { kitchenSinkFormStep1 } from './kitchenSink/step1';
import { kitchenSinkFormStep2 } from './kitchenSink/step2';
import { kitchenSinkFormStep3 } from './kitchenSink/step3';
import { kitchenSinkFormStep4 } from './kitchenSink/step4';

export const kitchenSinkForm = new KitchenSinkForm({
    applicationId: 'kitchenSink',
    step1: kitchenSinkFormStep1,
    step2: kitchenSinkFormStep2,
    step3: kitchenSinkFormStep3,
    step4: kitchenSinkFormStep4,
});
