import { FormStep } from '../formStep';
import { TextField } from '../fields/textField';
import { SelectField } from '../fields/selectField';
import { FirstAndLastNameFormGroup } from '../formGroups/firstAndLastNameFormGroup';
import { ZipAndCityFormGroup } from '../formGroups/zipAndCityFormGroup';
import { StreetAndHousenumberFormGroup } from '../formGroups/streetAndHousenumberFormGroup';
import type { CountryValueType } from '../shared/countryValueType';
import { WithOptional } from '../utility';

type ConfigProps = WithOptional<PlantBuilderFormStep, keyof FormStep>;

export class PlantBuilderFormStep extends FormStep {
    public readonly companyName: TextField;
    public readonly builderName: FirstAndLastNameFormGroup;
    public readonly country: SelectField<CountryValueType>;
    public readonly zipAndCity: ZipAndCityFormGroup;
    public readonly streetAndHousenumber: StreetAndHousenumberFormGroup;
    public readonly email: TextField;
    public readonly phoneNumber1: TextField;
    public readonly phoneNumber2: TextField;

    public constructor(config: ConfigProps) {
        super(config);

        this.companyName = config.companyName;
        this.builderName = config.builderName;
        this.country = config.country;
        this.zipAndCity = config.zipAndCity;
        this.streetAndHousenumber = config.streetAndHousenumber;
        this.email = config.email;
        this.phoneNumber1 = config.phoneNumber1;
        this.phoneNumber2 = config.phoneNumber2;
    }
}
