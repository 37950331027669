import { parseGermanFloat } from '@/utilities/germanFormats';
import { ValidationRule } from 'vuelidate/lib/validators';

interface SelfGenerationQuantityPartialVm {
    selfGenerationQuantity: string;
}

export const mustBeLowerThanOrEqualToSelfGenerationQuantity = ((
    value: string,
    vm: SelfGenerationQuantityPartialVm,
) => {
    const selfGenerationQuantity = parseGermanFloat(
        vm.selfGenerationQuantity || '0',
    );
    const val = parseGermanFloat(value || '0');
    return val <= selfGenerationQuantity;
}) as ValidationRule;
