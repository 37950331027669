import { PhotovoltaicFormGroup } from '../formGroups/plantPerformance/photovoltaic/photovoltaicFormGroup';
import { StorageFormGroup } from '../formGroups/plantPerformance/storage/storageFormGroup';
import { FormStep } from '../formStep';
import { WithOptional } from '../utility';
import { GeneratorFormGroup } from '../formGroups/plantPerformance/generator/generatorFormGroup';
import { CheckboxField } from '../fields/checkboxField';
import { InverterFormGroup } from '../formGroups/plantPerformance/inverter/inverterFormGroup';
import { FormElement } from '../formElement';

type ConfigProps = WithOptional<PlantPerformanceFormStep, keyof FormStep>;

export type PhasePosition = 'l1' | 'l2' | 'l3';

export class PlantPerformanceFormStep extends FormStep {
    public readonly photovoltaic: PhotovoltaicFormGroup;
    public readonly inverter: InverterFormGroup;
    public readonly storage: StorageFormGroup;
    public readonly generator: GeneratorFormGroup;
    public readonly certificateHint: CheckboxField;
    public readonly overallNominalPowerNotMatchingTotalPlantPerformanceValueTypeWarning: FormElement;

    public constructor(config: ConfigProps) {
        super(config);

        this.photovoltaic = config.photovoltaic;
        this.inverter = config.inverter;
        this.storage = config.storage;
        this.generator = config.generator;
        this.certificateHint = config.certificateHint;
        this.overallNominalPowerNotMatchingTotalPlantPerformanceValueTypeWarning =
            config.overallNominalPowerNotMatchingTotalPlantPerformanceValueTypeWarning;
    }
}
