import Vue from 'vue';
import { PlantPerformanceFormStep } from '@/types/forms/formSteps/plantPerformanceFormStep';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import {
    isRequestWithoutPlanning,
    isTotalPlantPerformanceAbove300,
    isTotalPlantPerformanceBelow300,
} from '../common';

export const plantPerformanceGeneratorRules = async (
    formStep: {
        model: PlantPerformanceFormStep;
        formModel: ApplicationForm;
    } & Vue,
): Promise<void> => {
    const hasTotalPlantPerformanceAbove300 = isTotalPlantPerformanceAbove300(
        formStep.formModel,
    );
    const hasTotalPlantPerformanceBelow300 = isTotalPlantPerformanceBelow300(
        formStep.formModel,
    );
    const requestWithoutPlanning = isRequestWithoutPlanning(formStep.formModel);

    for (const module of formStep.model.generator.generatorModules.items) {
        const generatorTotalActivePower =
            module.generatorTotalActivePower.value ?? 0;
        const generatorTotalApparentPower =
            module.generatorTotalApparentPower.value ?? 0;

        // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854573576/Feldgruppe+Generatoren+nicht+PV+oder+Speicher+NS#FeldgruppeGeneratoren(nichtPVoderSpeicher)NS-totalActivePower
        // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854573576/Feldgruppe+Generatoren+nicht+PV+oder+Speicher+NS#FeldgruppeGeneratoren(nichtPVoderSpeicher)NS-totalApparentPower
        module.generatorTotalActivePowerIsGreaterThanTotalApparentPowerWarning.disabled =
            !hasTotalPlantPerformanceBelow300 ||
            generatorTotalActivePower <= generatorTotalApparentPower;

        module.generatorContributionSymmetricalShortCircuitCurrent.disabled =
            !hasTotalPlantPerformanceAbove300 || requestWithoutPlanning;
        module.generatorVoltageSymmetricalShortCircuitCurrent.disabled =
            !hasTotalPlantPerformanceAbove300 || requestWithoutPlanning;
        module.generatorContributionSustainedShortCircuitCurrent.disabled =
            !hasTotalPlantPerformanceAbove300 || requestWithoutPlanning;
        module.generatorVoltageSustainedShortCircuitCurrent.disabled =
            !hasTotalPlantPerformanceAbove300 || requestWithoutPlanning;
    }
};
