import { CheckboxField } from '@/types/forms/fields/checkboxField';
import { TextField } from '@/types/forms/fields/textField';
import { FormElement } from '@/types/forms/formElement';
import { CheckAndSendFormStep } from '@/types/forms/formSteps/checkAndSendFormStep';
import { checked } from '@/validators/checked';

export const checkAndSendFormStep = new CheckAndSendFormStep({
    checkNotice: new FormElement(),
    comments: new TextField(),
    summaryDocument: new FormElement(),
    privacyInformation: new CheckboxField({
        validators: {
            checked,
        },
    }),
    generalTermsConsent: new CheckboxField({
        validators: {
            checked,
        },
    }),
    correctnessNotice: new FormElement(),
});
