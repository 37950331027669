import { lte } from '@/services/floatComparison';
import { modelService } from '@/services/form/modelService';
import { isPluginGeneratingPlant } from '@/services/form/rules/application/common';
import {
    formatGermanNumber,
    parseGermanFloat,
} from '@/utilities/germanFormats';
import { helpers, ValidationRule } from 'vuelidate/lib/validators';

export const maxApparentPowerPlugInGeneratingPlant = (
    max: number,
): ValidationRule => {
    return helpers.withParams(
        {
            type: 'maxApparentPowerPlugInGeneratingPlant',
            max: formatGermanNumber(max, { decimals: 2 }),
        },
        (value: string) => {
            const application = modelService.model.application;
            const parsedValue =
                typeof value == 'number' ? value : parseGermanFloat(value);
            return (
                !isPluginGeneratingPlant(application) || lte(parsedValue, max)
            );
        },
    );
};
