import { required, helpers } from 'vuelidate/lib/validators';
import { FormOption } from '@/types/forms/formOption';
import { CheckboxesField } from '@/types/forms/fields/checkboxesField';
import { KitchenSinkFormStep3 } from '@/types/forms/dummy/kitchensinkFormStep3';

export const kitchenSinkFormStep3 = new KitchenSinkFormStep3({
    checkedOptions1: new CheckboxesField({
        value: ['opt1', 'opt2'],
        options: [
            new FormOption({ key: 'opt1' }),
            new FormOption({ key: 'opt2' }),
            new FormOption({ key: 'opt3' }),
        ],
        validators: {
            required,
        },
    }),
    checkedOptions2: new CheckboxesField({
        options: [
            new FormOption({ key: 'friends' }),
            new FormOption({ key: 'internet' }),
            new FormOption({ key: 'television' }),
            new FormOption({ key: 'radio' }),
        ],
        validators: {
            radioIsForbidden: (value: string[]): boolean =>
                !helpers.req(value) || !value.includes('radio'),
        },
    }),
    dropdownCheckedOptions: new CheckboxesField({
        options: [
            new FormOption({ key: 'friends' }),
            new FormOption({ key: 'internet' }),
            new FormOption({ key: 'television' }),
            new FormOption({ key: 'radio' }),
        ],
    }),
});
