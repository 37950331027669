import { CountField } from '../../../fields/countField';
import { FormField } from '../../../fields/formField';
import { FormElementConfig } from '../../../formElement';
import { FormGroup } from '../../../formGroup';
import { ManufacturerAndStorageTypeFormGroup } from './manufacturerAndStorageTypeFormGroup';
import { DischargePowerAndStorageCapacityFormGroup } from './dischargePowerAndStorageCapacityFormGroup';
import { ReferenceFormGroup } from '../../referenceFormGroup';

export type StorageModuleFormGroupConfig = FormElementConfig &
    Omit<StorageModuleFormGroup, keyof FormGroup>;

export class StorageModuleFormGroup extends ReferenceFormGroup {
    public readonly storageManufacturerAndStorageType: ManufacturerAndStorageTypeFormGroup;
    public readonly storageQuantity: CountField;
    public readonly storageDischargePowerAndStorageCapacity: DischargePowerAndStorageCapacityFormGroup;
    public readonly storageTotalDischargePower: FormField<number>;
    public readonly storageTotalStorageCapacity: FormField<number>;

    public constructor(config: StorageModuleFormGroupConfig) {
        super(config);

        this.storageManufacturerAndStorageType =
            config.storageManufacturerAndStorageType;
        this.storageQuantity = config.storageQuantity;
        this.storageDischargePowerAndStorageCapacity =
            config.storageDischargePowerAndStorageCapacity;
        this.storageTotalDischargePower = config.storageTotalDischargePower;
        this.storageTotalStorageCapacity = config.storageTotalStorageCapacity;
    }
}
