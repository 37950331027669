import { gt, lte } from '@/services/floatComparison';
import { GenerationTypeFormStep } from '@/types/forms/formSteps/generationTypeFormStep';
import { MeasuringConceptsFormStep } from '@/types/forms/formSteps/measuringConceptsFormStep';
import { PlantDataFormStep } from '@/types/forms/formSteps/plantDataFormStep';
import { PlantPerformanceFormStep } from '@/types/forms/formSteps/plantPerformanceFormStep';
import { PowerConnectionFormStep } from '@/types/forms/formSteps/powerConnectionFormStep';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import { parseGermanFloat } from '@/utilities/germanFormats';

/**
 * Returns true if the application type matches the given type
 * @param formModel ApplicationForm
 * @param applicationType string
 */
const isApplicationType = (
    formModel: ApplicationForm,
    applicationType: string,
) => formModel.applicationType === applicationType;

/**
 * PV
 * @param formModel ApplicationForm
 */
export function isPv(formModel: ApplicationForm): boolean {
    return isApplicationType(formModel, 'photovoltaic');
}

/**
 * PluginGeneratingPlant
 * @param formModel ApplicationForm
 */
export function isPluginGeneratingPlant(formModel: ApplicationForm): boolean {
    return isPluginGeneratingPlantGenerationTypeModel(formModel.generationType);
}

/**
 * PluginGeneratingPlant
 * @param model GenerationTypeFormStep
 */
export function isPluginGeneratingPlantGenerationTypeModel(
    model: GenerationTypeFormStep,
): boolean {
    return (
        !model.plugInGeneratingPlant.disabled &&
        !!model.plugInGeneratingPlant.value
    );
}

/**
 * PV & PluginGeneratingPlant & Simplified process
 * @param model GenerationTypeFormStep
 */
export function isPluginGeneratingPlantSimplified(
    model: GenerationTypeFormStep,
): boolean {
    const pv =
        model.plantType.value === null ||
        model.plantType.value === 'photovoltaicPowerPlant';
    const plugin = isPluginGeneratingPlantGenerationTypeModel(model);
    const proceed =
        model.proceedWithFullRegistrationForEEGCompensation.disabled ||
        !!model.proceedWithFullRegistrationForEEGCompensation.value;
    return pv && plugin && !proceed;
}

/**
 * KWK
 * @param formModel ApplicationForm
 */
export function isPowerHeatCoupling(formModel: ApplicationForm): boolean {
    return isApplicationType(formModel, 'powerHeatCoupling');
}

/**
 * Speicher
 * @param formModel ApplicationForm
 */
export function isStorage(formModel: ApplicationForm): boolean {
    return isApplicationType(formModel, 'storage');
}

/**
 * DEA
 * @param formModel ApplicationForm
 */
export function isDea(formModel: ApplicationForm): boolean {
    return isApplicationType(formModel, 'dea');
}

/**
 * Unbekannt
 * @param formModel ApplicationForm
 */
export function isUnknown(formModel: ApplicationForm): boolean {
    return isApplicationType(formModel, 'unknown');
}

export function isLowVoltage(
    powerConnection: PowerConnectionFormStep,
): boolean {
    return !isMediumVoltage(powerConnection);
}

export function isMediumVoltage(
    powerConnection: PowerConnectionFormStep,
): boolean {
    return powerConnection.whichVoltageLevel.value === 'mediumVoltage';
}

export function isFullInfeed(
    measuringConcepts: MeasuringConceptsFormStep,
): boolean {
    return (
        !measuringConcepts.operatingMode.disabled &&
        measuringConcepts.operatingMode.value === 'fullInfeed'
    );
}

export function isSurplusInfeed(
    measuringConcepts: MeasuringConceptsFormStep,
): boolean {
    return (
        !measuringConcepts.operatingMode.disabled &&
        measuringConcepts.operatingMode.value === 'surplusInfeed'
    );
}

export function isZeroInfeed(
    measuringConcepts: MeasuringConceptsFormStep,
): boolean {
    return (
        !measuringConcepts.operatingMode.disabled &&
        measuringConcepts.operatingMode.value === 'zeroInfeed'
    );
}

export function isNominalPowerAboveTippingPoint(
    plantPerformance: PlantPerformanceFormStep,
    tippingPoint: number,
): boolean {
    return (
        !plantPerformance.photovoltaic.disabled &&
        !plantPerformance.photovoltaic.pvOverallNominalPower.disabled &&
        plantPerformance.photovoltaic.pvOverallNominalPower.value !== null &&
        gt(
            plantPerformance.photovoltaic.pvOverallNominalPower.value,
            tippingPoint,
        )
    );
}

export function isNominalPowerBelowOrEqualTippingPoint(
    plantPerformance: PlantPerformanceFormStep,
    tippingPoint: number,
): boolean {
    return (
        !plantPerformance.photovoltaic.disabled &&
        !plantPerformance.photovoltaic.pvOverallNominalPower.disabled &&
        plantPerformance.photovoltaic.pvOverallNominalPower.value !== null &&
        lte(
            plantPerformance.photovoltaic.pvOverallNominalPower.value,
            tippingPoint,
        )
    );
}

export function isSelfSupplyQuantityAboveTippingPoint(
    measuringConcept: MeasuringConceptsFormStep,
    tippingPoint: number,
): boolean {
    return (
        !measuringConcept.selfSupplyQuantity.disabled &&
        !!measuringConcept.selfSupplyQuantity.value &&
        gt(
            parseGermanFloat(measuringConcept.selfSupplyQuantity.value),
            tippingPoint,
        )
    );
}

export function isSelfSupplyQuantityBelowOrEqualTippingPoint(
    measuringConcept: MeasuringConceptsFormStep,
    tippingPoint: number,
): boolean {
    return (
        !measuringConcept.selfSupplyQuantity.disabled &&
        !!measuringConcept.selfSupplyQuantity.value &&
        lte(
            parseGermanFloat(measuringConcept.selfSupplyQuantity.value),
            tippingPoint,
        )
    );
}

export function isActivePowerAboveTippingPoint(
    plantPerformance: PlantPerformanceFormStep,
    tippingPoint: number,
): boolean {
    return (
        !plantPerformance.generator.disabled &&
        !plantPerformance.generator.generatorOverallActivePower.disabled &&
        gt(
            plantPerformance.generator.generatorOverallActivePower.value ??
                Math.min(),
            tippingPoint,
        )
    );
}

export function isActivePowerBelowOrEqualTippingPoint(
    plantPerformance: PlantPerformanceFormStep,
    tippingPoint: number,
): boolean {
    return (
        !plantPerformance.generator.disabled &&
        !plantPerformance.generator.generatorOverallActivePower.disabled &&
        lte(
            plantPerformance.generator.generatorOverallActivePower.value ??
                Math.max(),
            tippingPoint,
        )
    );
}

export function isDischargePowerAboveTippingPoint(
    plantPerformance: PlantPerformanceFormStep,
    tippingPoint: number,
): boolean {
    return (
        !plantPerformance.storage.disabled &&
        !plantPerformance.storage.storageOverallDischargePower.disabled &&
        plantPerformance.storage.storageOverallDischargePower.value !== null &&
        gt(
            plantPerformance.storage.storageOverallDischargePower.value,
            tippingPoint,
        )
    );
}

export function isDischargePowerBelowOrEqualTippingPoint(
    plantPerformance: PlantPerformanceFormStep,
    tippingPoint: number,
): boolean {
    return (
        !plantPerformance.storage.disabled &&
        !plantPerformance.storage.storageOverallDischargePower.disabled &&
        plantPerformance.storage.storageOverallDischargePower.value !== null &&
        lte(
            plantPerformance.storage.storageOverallDischargePower.value,
            tippingPoint,
        )
    );
}

/**
 * AC-gekoppelt: Anschluss im Verbraucherpfad. Be- und Entladung über seperaten WR.
 * @param plantData PlantDataFormStep
 */
export function isStorageCouplingAcCoupled(
    plantData: PlantDataFormStep,
): boolean {
    return (
        !plantData.storageCoupling.disabled &&
        plantData.storageCoupling.value === 'acCoupled'
    );
}

/**
 * DC-gekoppelt: Anschluss im Erzeugungspfad. Beladung DC-seitig. Entladung über gemeinsamen WR.
 * @param plantData PlantDataFormStep
 */
export function isStorageCouplingDcCoupled(
    plantData: PlantDataFormStep,
): boolean {
    return (
        !plantData.storageCoupling.disabled &&
        plantData.storageCoupling.value === 'dcCoupled'
    );
}

/**
 * Speicher ohne Stromerzeugungsanlage und ohne Verbrauchsanlage
 * @param plantData PlantDataFormStep
 */
export function isStorageConnectionConceptWithoutPowerGeneration(
    plantData: PlantDataFormStep,
): boolean {
    return (
        !plantData.storageConnectionConcept.disabled &&
        plantData.storageConnectionConcept.value ===
            'storageWithoutPowerGenerationPlantAndWithoutConsumptionPlant'
    );
}

/**
 * Speicher mit Stromerzeugungsanlage und ohne Verbrauchsanlage
 * @param plantData PlantDataFormStep
 */
export function isStorageConnectionConceptWithPowerGeneration(
    plantData: PlantDataFormStep,
): boolean {
    return (
        !plantData.storageConnectionConcept.disabled &&
        plantData.storageConnectionConcept.value ===
            'storageFacilityWithPowerGenerationPlantAndWithoutConsumptionPlant'
    );
}

/**
 * Stromerzeugungsanlage mit Speicher im Erzeugungspfad und Verbrauchsanlage
 * @param plantData PlantDataFormStep
 */
export function isStorageConnectionConceptStorageInGenerationPath(
    plantData: PlantDataFormStep,
): boolean {
    return (
        !plantData.storageConnectionConcept.disabled &&
        plantData.storageConnectionConcept.value ===
            'powerGenerationPlantWithStorageFacilityInTheGenerationPathAndConsumptionPlant'
    );
}

/**
 * Stromerzeugungsanlage mit Speicher im Verbrauchspfad und Verbrauchsanlage
 * @param plantData PlantDataFormStep
 */
export function isStorageConnectionConceptStorageInConsumerPath(
    plantData: PlantDataFormStep,
): boolean {
    return (
        !plantData.storageConnectionConcept.disabled &&
        plantData.storageConnectionConcept.value ===
            'powerGenerationPlantWithStorageInTheConsumerPathAndConsumptionPlant'
    );
}

/**
 * nur aus der EEG - Anlage
 * @param plantData PlantDataFormStep
 */
export function isStorageChargeOnlyFromEeg(
    plantData: PlantDataFormStep,
): boolean {
    return (
        !plantData.storageCharge.disabled &&
        plantData.storageCharge.value === 'onlyFromEeg'
    );
}
/**
 * nur aus der KWKG - Anlage
 * @param plantData PlantDataFormStep
 */
export function isStorageChargeOnlyFromKwkg(
    plantData: PlantDataFormStep,
): boolean {
    return (
        !plantData.storageCharge.disabled &&
        plantData.storageCharge.value === 'onlyFromKwkg'
    );
}

/**
 * aus der EEG - und KWKG - Anlage
 * @param plantData PlantDataFormStep
 */
export function isStorageChargeFromEegAndKwkg(
    plantData: PlantDataFormStep,
): boolean {
    return (
        !plantData.storageCharge.disabled &&
        plantData.storageCharge.value === 'fromEegAndKwkg'
    );
}

/**
 * aus dem öffentlichen Netz und EEG - Anlage
 * @param plantData PlantDataFormStep
 */
export function isStorageChargeFromPublicGridAndEeg(
    plantData: PlantDataFormStep,
): boolean {
    return (
        !plantData.storageCharge.disabled &&
        plantData.storageCharge.value === 'fromPublicGridAndEeg'
    );
}

/**
 * aus dem öffentlichen Netz und KWKG - Anlage
 * @param plantData PlantDataFormStep
 */
export function isStorageChargeFromPublicGridAndKwkg(
    plantData: PlantDataFormStep,
): boolean {
    return (
        !plantData.storageCharge.disabled &&
        plantData.storageCharge.value === 'fromPublicGridAndKwkg'
    );
}

/**
 * aus dem öffentlichen Netz, KWKG und EEG - Anlagen
 * @param form ApplicationForm
 */
export function isStorageChargeFromPublicGridEegAndKwkg(
    plantData: PlantDataFormStep,
): boolean {
    return (
        !plantData.storageCharge.disabled &&
        plantData.storageCharge.value === 'fromPublicGridAndEegAndKwkg'
    );
}

/**
 * nur aus dem öffentlichen Netz
 * @param plantData PlantDataFormStep
 */
export function isStorageChargeOnlyFromPublicGrid(
    plantData: PlantDataFormStep,
): boolean {
    return (
        !plantData.storageCharge.disabled &&
        plantData.storageCharge.value === 'onlyFromPublicGrid'
    );
}

/**
 * nur aus dem öffentlichen Netz,
 * aus dem öffentlichen Netz und EEG - Anlage,
 * aus dem öffentlichen Netz und KWKG - Anlage,
 * aus dem öffentlichen Netz, KWKG und EEG - Anlagen,
 * @param plantData PlantDataFormStep
 */
export function isStorageChargeFromPublicGrid(
    plantData: PlantDataFormStep,
): boolean {
    return (
        !plantData.storageCharge.disabled &&
        (plantData.storageCharge.value === 'onlyFromPublicGrid' ||
            plantData.storageCharge.value === 'fromPublicGridAndEeg' ||
            plantData.storageCharge.value === 'fromPublicGridAndKwkg' ||
            plantData.storageCharge.value === 'fromPublicGridAndEegAndKwkg')
    );
}

/**
 * kundeneigenes Netz - Eigenversorgung
 * @param plantData PlantDataFormStep
 */
export function isStorageDischargeOnlySelfSupply(
    plantData: PlantDataFormStep,
): boolean {
    return (
        !plantData.storageDischarge.disabled &&
        plantData.storageDischarge.value === 'onlyIntoCustomersGridSelfSupply'
    );
}

/**
 * öffentliches Netz - Netzeinspeisung
 * @param plantData PlantDataFormStep
 */
export function isStorageDischargeOnlyPublicGridFeedIn(
    plantData: PlantDataFormStep,
): boolean {
    return (
        !plantData.storageDischarge.disabled &&
        plantData.storageDischarge.value === 'onlyIntoPublicGridGridFeedIn'
    );
}

/**
 * kundeneigenes und öffentliches Netz (bivalent)
 * @param plantData PlantDataFormStep
 */
export function isStorageDischargeCustomersGridAndPublicGrid(
    plantData: PlantDataFormStep,
): boolean {
    return (
        !plantData.storageDischarge.disabled &&
        plantData.storageDischarge.value ===
            'intoCustomersGridAndPublicGridBivalent'
    );
}

/**
 * öffentliches Netz - Netzeinspeisung,
 * kundeneigenes und öffentliches Netz (bivalent)
 * @param plantData PlantDataFormStep
 */
export function isStorageDischargeIntoPublicGrid(
    plantData: PlantDataFormStep,
): boolean {
    return (
        !plantData.storageDischarge.disabled &&
        (plantData.storageDischarge.value === 'onlyIntoPublicGridGridFeedIn' ||
            plantData.storageDischarge.value ===
                'intoCustomersGridAndPublicGridBivalent')
    );
}

/**
 * Nicht hocheffiziente KWK-Anlage oder konventionelle Erzeugungsanlage
 * @param plantData PlantDataFormStep
 */
export function isNonHighEfficiencyChpPlant(
    plantData: PlantDataFormStep,
): boolean {
    return (
        !plantData.powerHeatCouplingPlantType.disabled &&
        plantData.powerHeatCouplingPlantType.value ===
            'nonHighEfficiencyChpPlant'
    );
}

/**
 * Hocheffiziente KWK-Anlage
 * @param plantData PlantDataFormStep
 */
export function isHighEfficiencyChpPlant(
    plantData: PlantDataFormStep,
): boolean {
    return (
        !plantData.powerHeatCouplingPlantType.disabled &&
        plantData.powerHeatCouplingPlantType.value === 'highEfficiencyChpPlant'
    );
}

export function isRequestWithPlanning(
    applicationForm: ApplicationForm,
): boolean {
    return (
        !applicationForm.generationType.requestType.disabled &&
        applicationForm.generationType.requestType.value ==
            'requestWithPlanning'
    );
}

export function isRequestWithoutPlanning(
    applicationForm: ApplicationForm,
): boolean {
    return (
        !applicationForm.generationType.requestType.disabled &&
        applicationForm.generationType.requestType.value ==
            'requestWithoutPlanning'
    );
}

export function isTotalPlantPerformanceBelow300(
    applicationForm: ApplicationForm,
): boolean {
    return (
        !applicationForm.generationType.totalPlantPerformance.disabled &&
        applicationForm.generationType.totalPlantPerformance.value == 'below300'
    );
}

export function isTotalPlantPerformanceAbove300(
    applicationForm: ApplicationForm,
): boolean {
    return (
        !applicationForm.generationType.totalPlantPerformance.disabled &&
        applicationForm.generationType.totalPlantPerformance.value == 'above300'
    );
}
