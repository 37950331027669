import { CountField } from '@/types/forms/fields/countField';
import { FormField } from '@/types/forms/fields/formField';
import { FormList } from '@/types/forms/fields/formList';
import { SelectField } from '@/types/forms/fields/selectField';
import { TextField } from '@/types/forms/fields/textField';
import { FormElement } from '@/types/forms/formElement';
import { ActivePowerAndApparentPowerFormGroup } from '@/types/forms/formGroups/plantPerformance/activePowerAndApparentPowerFormGroup';
import { GeneratorFormGroup } from '@/types/forms/formGroups/plantPerformance/generator/generatorFormGroup';
import { GeneratorModuleFormGroup } from '@/types/forms/formGroups/plantPerformance/generator/generatorModuleFormGroup';
import { QuantityAndGeneratorTypeFormGroup } from '@/types/forms/formGroups/plantPerformance/quantityAndGeneratorTypeFormGroup';
import { ManufacturerAndModuleTypeFormGroup } from '@/types/forms/formGroups/plantPerformance/manufacturerAndModuleTypeFormGroup';
import { FormOption } from '@/types/forms/formOption';
import { float } from '@/validators/float';
import { maxApparentPowerPlugInGeneratingPlant } from '@/validators/maxApparentPowerPlugInGeneratingPlant';
import { positive } from '@/validators/positive';
import { required } from 'vuelidate/lib/validators';
import { restrictedApparentPower } from './boundaryValues';
import { alwaysFalse } from '@/validators/alwaysFalse';

export const generatorFormGroup = new GeneratorFormGroup({
    generatorHeadline: new FormElement(),
    generatorModules: new FormList(GeneratorModuleFormGroup, {
        initializeItems: 1,
        minItemCount: 1,
        maxItemCount: 5,
        id: new FormField<string>(),
        generatorManufacturerAndModuleType: new ManufacturerAndModuleTypeFormGroup(
            {
                manufacturer: new TextField({
                    validators: {
                        required,
                    },
                }),
                moduleType: new TextField({
                    validators: {
                        required,
                    },
                }),
            },
        ),
        generatorQuantityAndGeneratorType: new QuantityAndGeneratorTypeFormGroup(
            {
                quantity: new CountField({
                    value: 1,
                    min: 1,
                    validators: {
                        required,
                    },
                }),
                generatorType: new SelectField({
                    options: [
                        new FormOption({ key: 'type1' }),
                        new FormOption({ key: 'type2' }),
                    ],
                    validators: {
                        required,
                    },
                }),
            },
        ),
        generatorContributionSymmetricalShortCircuitCurrent: new TextField({
            validators: {
                required,
                float,
                positive,
            },
        }),
        generatorVoltageSymmetricalShortCircuitCurrent: new TextField({
            validators: {
                required,
                float,
                positive,
            },
        }),
        generatorContributionSustainedShortCircuitCurrent: new TextField({
            validators: {
                required,
                float,
                positive,
            },
        }),
        generatorVoltageSustainedShortCircuitCurrent: new TextField({
            validators: {
                required,
                float,
                positive,
            },
        }),
        generatorActivePowerAndApparentPower: new ActivePowerAndApparentPowerFormGroup(
            {
                activePower: new TextField({
                    validators: {
                        required,
                        float,
                        positive,
                    },
                }),
                apparentPower: new TextField({
                    validators: {
                        required,
                        float,
                        positive,
                        maxApparentPowerPlugInGeneratingPlant: maxApparentPowerPlugInGeneratingPlant(
                            restrictedApparentPower,
                        ),
                    },
                }),
            },
        ),
        generatorTotalActivePowerIsGreaterThanTotalApparentPowerWarning: new FormElement(
            {
                validators: {
                    alwaysFalse,
                },
            },
        ),
        generatorTotalActivePower: new FormField({
            value: 0,
        }),
        generatorTotalApparentPower: new FormField({
            value: 0,
        }),
    }),
    generatorOverallActivePower: new FormField({
        value: 0,
    }),
    generatorOverallApparentPower: new FormField({
        value: 0,
    }),
});
