import { PhotovoltaicModuleFormList } from './photovoltaicModuleFormList';
import { FormGroup } from '../../../formGroup';
import { FormField } from '../../../fields/formField';
import { FormElement } from '../../../formElement';
import { WithOptional } from '../../../utility';
import { alwaysInDtoAttribute } from '@/types/forms/alwaysinDtoAttribute';

type ConfigProps = WithOptional<PhotovoltaicFormGroup, keyof FormGroup>;

export class PhotovoltaicFormGroup extends FormGroup {
    public readonly pvHeadline: FormElement;
    public readonly pvModules: PhotovoltaicModuleFormList;
    @alwaysInDtoAttribute()
    public readonly pvOverallNominalPower: FormField<number>;

    public constructor(config: ConfigProps) {
        super(config);

        this.pvHeadline = config.pvHeadline;
        this.pvModules = config.pvModules;
        this.pvOverallNominalPower = config.pvOverallNominalPower;
    }
}
