import { required, email, maxLength } from 'vuelidate/lib/validators';
import { name } from '@/validators/name';
import { houseNumber } from '@/validators/houseNumber';
import { FormOption } from '@/types/forms/formOption';
import { TextField } from '@/types/forms/fields/textField';
import { SelectField } from '@/types/forms/fields/selectField';
import { ZipAndCityFormGroup } from '@/types/forms/formGroups/zipAndCityFormGroup';
import { StreetAndHousenumberFormGroup } from '@/types/forms/formGroups/streetAndHousenumberFormGroup';
import { PlantBuilderFormStep } from '@/types/forms/formSteps/plantBuilderFormStep';
import { FirstAndLastNameFormGroup } from '@/types/forms/formGroups/firstAndLastNameFormGroup';
import { positiveInteger } from '@/validators/positiveInteger';
import { zip } from '@/validators/zip';

export const plantBuilderFormStep = new PlantBuilderFormStep({
    companyName: new TextField({
        validators: {
            required,
        },
    }),
    builderName: new FirstAndLastNameFormGroup({
        firstName: new TextField({
            validators: {
                name,
            },
        }),
        lastName: new TextField({
            validators: {
                name,
            },
        }),
    }),
    country: new SelectField({
        value: 'deu',
        options: [
            new FormOption({ key: 'deu' }),
            new FormOption({ key: 'aut' }),
            new FormOption({ key: 'dnk' }),
            new FormOption({ key: 'fra' }),
            new FormOption({ key: 'nld' }),
            new FormOption({ key: 'pol' }),
            new FormOption({ key: 'che' }),
        ],
    }),
    zipAndCity: new ZipAndCityFormGroup({
        zip: new TextField({
            validators: {
                required,
                zip: zip('application.plantBuilder.country'),
            },
        }),
        city: new TextField({
            validators: {
                required,
            },
        }),
    }),
    streetAndHousenumber: new StreetAndHousenumberFormGroup({
        street: new TextField({
            validators: {
                required,
            },
        }),
        housenumber: new TextField({
            validators: {
                required,
                houseNumber,
                maxLength: maxLength(8),
            },
        }),
    }),
    email: new TextField({
        validators: {
            required,
            email,
        },
    }),
    phoneNumber1: new TextField({
        validators: {
            required,
            phoneNumber: positiveInteger,
        },
    }),
    phoneNumber2: new TextField({
        validators: {
            phoneNumber: positiveInteger,
        },
    }),
});
