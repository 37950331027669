import { helpers, ValidationRule } from 'vuelidate/lib/validators';
import { number } from './number';

export const maxNumber = (max: number): ValidationRule => {
    return helpers.withParams({ type: 'maxNumber', max }, (value: unknown) => {
        return (
            !helpers.req(value) || !number(value) || (value as number) <= max
        );
    });
};
