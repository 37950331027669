import { defaults as _defaults } from 'lodash';

export interface FormOptionConfig<TKey> {
    disabled?: boolean;
    key: TKey;
}

export const formOptionDefaultConfig = {
    disabled: false,
};

export class FormOption<TKey> {
    public disabled: boolean;
    public key: TKey;

    public constructor(config: FormOptionConfig<TKey>) {
        const resolvedConfig = _defaults(
            config || ({} as FormOptionConfig<TKey>),
            formOptionDefaultConfig,
        );
        this.disabled = resolvedConfig.disabled;
        this.key = resolvedConfig.key;
    }
}
