import { CheckboxField } from '@/types/forms/fields/checkboxField';
import { checked } from '@/validators/checked';
import { DateField } from '@/types/forms/fields/dateField';
import { FormList } from '@/types/forms/fields/formList';
import { RadioButtonsField } from '@/types/forms/fields/radioButtonsField';
import { SelectField } from '@/types/forms/fields/selectField';
import { TextField } from '@/types/forms/fields/textField';
import { FirstAndLastNameFormGroup } from '@/types/forms/formGroups/firstAndLastNameFormGroup';
import { OwnerFormGroup } from '@/types/forms/formGroups/ownerFormGroup';
import { StreetAndHousenumberFormGroup } from '@/types/forms/formGroups/streetAndHousenumberFormGroup';
import { ZipAndCityFormGroup } from '@/types/forms/formGroups/zipAndCityFormGroup';
import { FormOption } from '@/types/forms/formOption';
import { PropertyOwnerFormStep } from '@/types/forms/formSteps/propertyOwnerFormStep';
import { maxDateNow } from '@/validators/maxDateNow';
import { name } from '@/validators/name';
import { houseNumber } from '@/validators/houseNumber';
import { positiveInteger } from '@/validators/positiveInteger';
import { email, maxLength, required } from 'vuelidate/lib/validators';
import { zip } from '@/validators/zip';

export const propertyOwnerFormStep = new PropertyOwnerFormStep({
    propertyOwnerAgreement: new CheckboxField({
        validators: {
            checked,
        },
    }),
    owners: new FormList(OwnerFormGroup, {
        initializeItems: 1,
        minItemCount: 1,
        maxItemCount: 3,
        salutation: new RadioButtonsField({
            value: 'mr',
            options: [
                new FormOption({ key: 'mr' }),
                new FormOption({ key: 'mrs' }),
                new FormOption({ key: 'company' }),
            ],
            validators: {
                required,
            },
        }),
        companyName: new TextField({
            validators: {
                required,
            },
        }),
        title: new SelectField({
            value: 'untitled',
            options: [
                new FormOption({ key: 'untitled' }),
                new FormOption({ key: 'dr' }),
                new FormOption({ key: 'prof' }),
                new FormOption({ key: 'profDr' }),
            ],
        }),
        ownerName: new FirstAndLastNameFormGroup({
            firstName: new TextField({
                validators: {
                    required,
                    name,
                },
            }),
            lastName: new TextField({
                validators: {
                    required,
                    name,
                },
            }),
        }),
        contactPersonName: new FirstAndLastNameFormGroup({
            firstName: new TextField({
                validators: {
                    required,
                    name,
                },
            }),
            lastName: new TextField({
                validators: {
                    required,
                    name,
                },
            }),
        }),
        birthday: new DateField({
            validators: {
                maxDateNow,
            },
        }),
        country: new SelectField({
            value: 'deu',
            options: [
                new FormOption({ key: 'deu' }),
                new FormOption({ key: 'aut' }),
                new FormOption({ key: 'dnk' }),
                new FormOption({ key: 'fra' }),
                new FormOption({ key: 'nld' }),
                new FormOption({ key: 'pol' }),
                new FormOption({ key: 'che' }),
            ],
        }),
        zipAndCity: new ZipAndCityFormGroup({
            zip: new TextField({
                validators: {
                    required,
                    zip: zip(
                        'application.propertyOwner.owners.items[*].country',
                    ),
                },
            }),
            city: new TextField({
                validators: {
                    required,
                },
            }),
        }),
        streetAndHousenumber: new StreetAndHousenumberFormGroup({
            street: new TextField({
                validators: {
                    required,
                },
            }),
            housenumber: new TextField({
                validators: {
                    required,
                    houseNumber,
                    maxLength: maxLength(8),
                },
            }),
        }),
        email: new TextField({
            validators: {
                email,
            },
        }),
        phoneNumber1: new TextField({
            validators: {
                required,
                phoneNumber: positiveInteger,
            },
        }),
        phoneNumber2: new TextField({
            validators: {
                phoneNumber: positiveInteger,
            },
        }),
    }),
});
