import { float } from './float';

export function getComparableNumber(
    value: number | string | Date,
): number | null {
    if (value instanceof Date || typeof value === 'number') {
        return +value;
    }

    if (!float(value)) {
        return null;
    }

    const parsedValue = parseFloat(value.replace(',', '.'));

    return isNaN(parsedValue) ? null : parsedValue;
}
