import { CheckboxField } from '@/types/forms/fields/checkboxField';
import { KitchenSinkFormStep4 } from '@/types/forms/dummy/kitchensinkFormStep4';
import { KitchenSinkPlantFormGroup } from '@/types/forms/dummy/formGroups/kitchenSinkPlantFormGroup';
import { KitchenSinkUserFormGroup } from '@/types/forms/dummy/formGroups/kitchenSinkUserFormGroup';
import { FormList } from '@/types/forms/fields/formList';
import { TextField } from '@/types/forms/fields/textField';
import { required } from 'vuelidate/lib/validators';

export const kitchenSinkFormStep4 = new KitchenSinkFormStep4({
    primary: new TextField({
        validators: {
            required,
        },
    }),
    secondary: new TextField({
        validators: {
            required,
        },
    }),
    users: new FormList(KitchenSinkUserFormGroup, {
        initializeItems: 1,
        firstName: new TextField(),
        lastName: new TextField({
            validators: { required },
        }),
    }),
    plants: new FormList(KitchenSinkPlantFormGroup, {
        maxItemCount: 5,
        number: new TextField({
            validators: {
                required,
            },
        }),
        checkbox: new CheckboxField(),
    }),
});
