import { GenerationTypeFormStep } from '@/types/forms/formSteps/generationTypeFormStep';
import { EnergyTypeFieldType } from '@/types/forms/shared/energyTypeFieldType';
import { PlantTypeFieldTypeForGenerationTypeFormStep } from '@/types/forms/shared/plantTypeFieldType';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import { isPluginGeneratingPlantSimplified } from './common';

const PLANT_TYPE_RULES: Record<
    EnergyTypeFieldType,
    PlantTypeFieldTypeForGenerationTypeFormStep[]
> = {
    energyStorage: ['storage'],
    sun: ['photovoltaicPowerPlant'],
    wind: ['windPowerPlant'],
    water: ['hydroPowerPlant', 'steamTurbine'],
    biogas: ['thermalPowerPlantDea', 'gasTurbine', 'orcPowerPlant'],
    biowaste: ['thermalPowerPlantDea', 'gasTurbine', 'orcPowerPlant'],
    biodiesel: ['thermalPowerPlantDea', 'gasTurbine', 'orcPowerPlant'],
    slurry: ['thermalPowerPlantDea', 'gasTurbine', 'orcPowerPlant'],
    plantOil: ['thermalPowerPlantDea', 'gasTurbine', 'orcPowerPlant'],
    woodGas: ['thermalPowerPlantDea', 'gasTurbine', 'orcPowerPlant'],
    mineGas: ['thermalPowerPlantDea', 'gasTurbine', 'orcPowerPlant'],
    sewageGas: ['thermalPowerPlantDea', 'gasTurbine', 'orcPowerPlant'],
    landfillGas: ['thermalPowerPlantDea', 'gasTurbine', 'orcPowerPlant'],
    geothermics: ['thermalPowerPlantDea', 'gasTurbine', 'orcPowerPlant'],
    naturalGas: [
        'thermalPowerPlantKwk',
        'gasTurbine',
        'fuelCell',
        'orcPowerPlant',
        'emergencyGenerator',
        'stirlingEngine',
    ],
    liquidGas: ['thermalPowerPlantKwk', 'gasTurbine', 'orcPowerPlant'],
    dieselOil: [
        'thermalPowerPlantKwk',
        'gasTurbine',
        'orcPowerPlant',
        'emergencyGenerator',
    ],
};

export const generationTypeRules = async (
    formStep: {
        model: GenerationTypeFormStep;
        formModel: ApplicationForm;
    } & Vue,
): Promise<void> => {
    formStep.model.energyType.options.forEach((option) => {
        if (!formStep.model.plugInGeneratingPlant.value) {
            option.disabled = false;
            return;
        }

        const isEnergyStorageOrSunOrWind =
            option.key === 'energyStorage' ||
            option.key === 'sun' ||
            option.key === 'wind';

        option.disabled = !isEnergyStorageOrSunOrWind;
    });

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565587/Energieart+Anlagenart
    formStep.model.plantType.options.forEach((option) => {
        if (
            formStep.model.energyType.value == null ||
            formStep.model.energyType.value === '' ||
            option.key === ''
        ) {
            option.disabled = false;
            return;
        }

        const optionRules = PLANT_TYPE_RULES[formStep.model.energyType.value];
        option.disabled = !optionRules.includes(option.key);
    });

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565613/Formular+Erzeugungsart#FormularErzeugungsart-plantType
    formStep.model.plantType.disabled = !formStep.model.energyType.value;

    formStep.model.proceedWithFullRegistrationForEEGCompensation.disabled =
        formStep.model.plantType.value !== 'photovoltaicPowerPlant' ||
        formStep.model.plugInGeneratingPlant.disabled ||
        !formStep.model.plugInGeneratingPlant.value;

    //
    // Hide everything for plugin generating plants when proceedWithFullRegistrationForEEGCompensation not checked.
    //
    const pluginSimplified = isPluginGeneratingPlantSimplified(formStep.model);

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4868800911/Formular+Anlagendaten#FormularAnlagendaten-plantStructureType
    formStep.model.plantStructureType.disabled =
        pluginSimplified ||
        formStep.model.plantType.value !== 'photovoltaicPowerPlant';

    formStep.model.totalPlantPerformance.disabled =
        !formStep.model.plugInGeneratingPlant.disabled &&
        !!formStep.model.plugInGeneratingPlant.value;

    formStep.model.requestType.disabled =
        (!formStep.model.plantStructureType.disabled &&
            formStep.model.plantStructureType.value == 'building') ||
        formStep.model.totalPlantPerformance.disabled ||
        formStep.model.totalPlantPerformance.value == 'below300';

    formStep.model.requestWithPlanningInfo.disabled =
        formStep.model.requestType.disabled ||
        formStep.model.requestType.value == 'requestWithoutPlanning';

    formStep.model.requestWithoutPlanningInfo.disabled =
        formStep.model.requestType.disabled ||
        formStep.model.requestType.value == 'requestWithPlanning';

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565613/Formular+Erzeugungsart#FormularErzeugungsart-emergencyGeneratorOperatingMode
    formStep.model.emergencyGeneratorOperatingMode.disabled =
        formStep.model.plantType.disabled ||
        !(formStep.model.plantType.value === 'emergencyGenerator');

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565613/Formular+Erzeugungsart#FormularErzeugungsart-specifiedEmergencyGeneratorOperatingMode
    formStep.model.specifiedEmergencyGeneratorOperatingMode.disabled =
        formStep.model.emergencyGeneratorOperatingMode.disabled ||
        !formStep.model.emergencyGeneratorOperatingMode.value?.includes(
            'other',
        );

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565613/Formular+Erzeugungsart#FormularErzeugungsart-bidirectionalMeter
    formStep.model.bidirectionalMeter.disabled = true; // Disabled temporarily, as per customer requirement.
    // formStep.model.plugInGeneratingPlant.disabled ||
    // !formStep.model.plugInGeneratingPlant.value ||
    // pluginSimplified;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565613/Formular+Erzeugungsart#FormularErzeugungsart-bidirectionalMeterInfo
    formStep.model.bidirectionalMeterInfo.disabled = true; // Disabled temporarily, as per customer requirement.
    // formStep.model.bidirectionalMeter.disabled ||
    // !!formStep.model.bidirectionalMeter.value;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565613/Formular+Erzeugungsart#FormularErzeugungsart-consumption
    formStep.model.consumption.disabled = true; // Disabled temporarily, as per customer requirement.
    // formStep.model.bidirectionalMeter.disabled ||
    // !formStep.model.bidirectionalMeter.value;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565613/Formular+Erzeugungsart#FormularErzeugungsart-infeed
    formStep.model.infeed.disabled = true; // Disabled temporarily, as per customer requirement.
    // formStep.model.bidirectionalMeter.disabled ||
    // !formStep.model.bidirectionalMeter.value;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565613/Formular+Erzeugungsart#FormularErzeugungsart-readingDate
    formStep.model.readingDate.disabled = true; // Disabled temporarily, as per customer requirement.
    // formStep.model.bidirectionalMeter.disabled ||
    // !formStep.model.bidirectionalMeter.value;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565613/Formular+Erzeugungsart#FormularErzeugungsart-plugInGeneratingPlantInfo
    formStep.model.plugInGeneratingPlantInfo.disabled = true; // Disabled temporarily, as per customer requirement.
    // formStep.model.plugInGeneratingPlant.disabled ||
    // !formStep.model.plugInGeneratingPlant.value ||
    // pluginSimplified;

    formStep.model.simplifiedProcedureHint.disabled =
        formStep.model.plugInGeneratingPlant.disabled ||
        !formStep.model.plugInGeneratingPlant.value ||
        pluginSimplified;

    formStep.model.simplifiedRegistrationProcessNotice.disabled =
        !pluginSimplified ||
        formStep.model.plantType.value !== 'photovoltaicPowerPlant' ||
        formStep.model.plugInGeneratingPlant.disabled ||
        !formStep.model.plugInGeneratingPlant.value;
};
