import { Form } from '../form';
import { formDtoAttribute } from '../formDtoAttribute';
import { enumerableGetterAttribute } from '../enumerableGetterAttribute';
import { LocationFormStep } from '../formSteps/locationFormStep';
import { GenerationTypeFormStep } from '../formSteps/generationTypeFormStep';
import { PowerConnectionFormStep } from '../formSteps/powerConnectionFormStep';
import { PlantBuilderFormStep } from '../formSteps/plantBuilderFormStep';
import { PlantOperatorFormStep } from '../formSteps/plantOperatorFormStep';
import { PropertyOwnerFormStep } from '../formSteps/propertyOwnerFormStep';
import { getApplicationType } from '@/services/form/applicationType';
import { PlantDataFormStep } from '../formSteps/plantDataFormStep';
import { PlantPerformanceFormStep } from '../formSteps/plantPerformanceFormStep';
import { MeasuringConceptsFormStep } from '../formSteps/measuringConceptsFormStep';
import { BillingFormStep } from '../formSteps/billingFormStep';
import { CheckAndSendFormStep } from '../formSteps/checkAndSendFormStep';
import { WithOptional } from '../utility';
import { DocumentsFormStep } from '../formSteps/documentsFormStep';
import { ControllableConsumptionDevicesFormStep } from '../formSteps/controllableConsumptionDevicesFormStep';

type ConfigProps = WithOptional<
    ApplicationForm,
    keyof Form | 'applicationType'
>;

export type ApplicationType =
    | 'photovoltaic'
    | 'storage'
    | 'dea'
    | 'powerHeatCoupling'
    | 'unknown';

function applicationTypeFunction(target: ApplicationForm) {
    return getApplicationType(
        target.generationType?.plantType?.value || undefined,
    );
}

export class ApplicationForm extends Form {
    @formDtoAttribute({
        jsonSchema: {
            type: 'string',
        },
    })
    public applicationId: string | null;

    @formDtoAttribute({
        jsonSchema: {
            type: 'string',
        },
    })
    @enumerableGetterAttribute(applicationTypeFunction)
    public readonly applicationType?: ApplicationType;

    public readonly location: LocationFormStep;
    public readonly generationType: GenerationTypeFormStep;
    public readonly powerConnection: PowerConnectionFormStep;
    public readonly plantOperator: PlantOperatorFormStep;
    public readonly plantBuilder: PlantBuilderFormStep;
    public readonly plantData: PlantDataFormStep;
    public readonly propertyOwner: PropertyOwnerFormStep;
    public readonly plantPerformance: PlantPerformanceFormStep;
    public readonly measuringConcepts: MeasuringConceptsFormStep;
    public readonly controllableConsumptionDevices: ControllableConsumptionDevicesFormStep;
    public readonly billing: BillingFormStep;
    public readonly documents: DocumentsFormStep;
    public readonly checkAndSend: CheckAndSendFormStep;

    public constructor(config: ConfigProps) {
        super(Object.assign(config) as Form);

        this.applicationId = config.applicationId;
        this.location = config.location;
        this.generationType = config.generationType;
        this.powerConnection = config.powerConnection;
        this.plantOperator = config.plantOperator;
        this.plantBuilder = config.plantBuilder;
        this.plantData = config.plantData;
        this.propertyOwner = config.propertyOwner;
        this.plantPerformance = config.plantPerformance;
        this.measuringConcepts = config.measuringConcepts;
        this.controllableConsumptionDevices =
            config.controllableConsumptionDevices;
        this.billing = config.billing;
        this.documents = config.documents;
        this.checkAndSend = config.checkAndSend;
    }
}
