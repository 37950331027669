import { JSONSchema7Definition } from 'json-schema';
import { defaults as _defaults } from 'lodash';
import {
    FormFieldConfig,
    formFieldDefaultConfig,
    FormField,
} from './formField';

export interface CheckboxFieldConfig extends FormFieldConfig<boolean> {
    value?: boolean;
}

export const checkboxFieldDefaultConfig = {
    ...formFieldDefaultConfig,
};

export class CheckboxField extends FormField<boolean> {
    public constructor(config?: CheckboxFieldConfig) {
        const resolvedConfig = _defaults(
            config || ({} as CheckboxFieldConfig),
            checkboxFieldDefaultConfig,
        );

        super(resolvedConfig);
    }

    public getJsonSchema(): JSONSchema7Definition {
        return {
            type: 'boolean',
        };
    }
}
