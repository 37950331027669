import { TextField } from '../fields/textField';
import { FormGroup } from '../formGroup';
import { WithOptional } from '../utility';

type ConfigProps = WithOptional<StreetAndHousenumberFormGroup, keyof FormGroup>;

export class StreetAndHousenumberFormGroup extends FormGroup {
    public readonly street: TextField;
    public readonly housenumber: TextField;

    public constructor(config: ConfigProps) {
        super(config);

        this.street = config.street;
        this.housenumber = config.housenumber;
    }
}
