import { TextField } from '../../fields/textField';
import { FormGroup } from '../../formGroup';
import { WithOptional } from '../../utility';

type ConfigProps = WithOptional<
    ActivePowerAndApparentPowerFormGroup,
    keyof FormGroup
>;

export class ActivePowerAndApparentPowerFormGroup extends FormGroup {
    public readonly activePower: TextField;
    public readonly apparentPower: TextField;

    public constructor(config: ConfigProps) {
        super(config);

        this.activePower = config.activePower;
        this.apparentPower = config.apparentPower;
    }
}
