import { FormElement } from '../formElement';
import { FormStep } from '../formStep';
import { TextField } from '../fields/textField';
import { ZipAndCityFormGroup } from '../formGroups/zipAndCityFormGroup';
import { StreetAndHousenumberFormGroup } from '../formGroups/streetAndHousenumberFormGroup';
import { GeoCoordinatesFormGroup } from '../fields/geoCoordinatesFormGroup';
import { MeadowAndLotFormGroup } from '../formGroups/meadowAndLotFormGroup';
import { CheckboxField } from '../fields/checkboxField';
import { WithOptional } from '../utility';
import { AddressCheckFeedbackFormGroup } from '../formGroups/electricityUnavailableAtAddressFormGroup';
import { FormField } from '../fields/formField';
import { AddressesApiAvailabilityResult } from '../shared/addressesApi/addressesApiAvailabilityResult';

type ConfigProps = WithOptional<LocationFormStep, keyof FormStep>;

export class LocationFormStep extends FormStep {
    public readonly stepIntro: FormElement;
    public readonly zipAndCity: ZipAndCityFormGroup;
    public readonly streetAndHousenumber: StreetAndHousenumberFormGroup;
    public readonly subdistrict: TextField;
    public readonly meadowAndLot: MeadowAndLotFormGroup;
    public readonly availabilityResult: FormField<
        AddressesApiAvailabilityResult
    >;
    public readonly addressCheckFeedback: AddressCheckFeedbackFormGroup;
    public readonly proceedWithCadastral: CheckboxField;
    public readonly building: TextField;
    public readonly geographicMap: FormElement;
    public readonly mapHeadline: FormElement;
    public readonly mapHint: FormElement;
    public readonly mapConsentHeadline: FormElement;
    public readonly mapConsentText: FormElement;
    public readonly mapConsentHint: FormElement;
    public readonly geographicLatCoordinates: GeoCoordinatesFormGroup;
    public readonly geographicLngCoordinates: GeoCoordinatesFormGroup;

    public constructor(config: ConfigProps) {
        super(config);

        this.stepIntro = config.stepIntro;
        this.zipAndCity = config.zipAndCity;
        this.streetAndHousenumber = config.streetAndHousenumber;
        this.subdistrict = config.subdistrict;
        this.meadowAndLot = config.meadowAndLot;
        this.availabilityResult = config.availabilityResult;
        this.addressCheckFeedback = config.addressCheckFeedback;
        this.proceedWithCadastral = config.proceedWithCadastral;
        this.building = config.building;
        this.geographicMap = config.geographicMap;
        this.mapHeadline = config.mapHeadline;
        this.mapHint = config.mapHint;
        this.mapConsentHeadline = config.mapConsentHeadline;
        this.mapConsentText = config.mapConsentText;
        this.mapConsentHint = config.mapConsentHint;
        this.geographicLatCoordinates = config.geographicLatCoordinates;
        this.geographicLngCoordinates = config.geographicLngCoordinates;
    }
}
