import { FormStep } from '../formStep';
import { RadioButtonsField } from '../fields/radioButtonsField';
import { FormElement } from '../formElement';
import { CheckboxField } from '../fields/checkboxField';
import { TextField } from '../fields/textField';
import { DateField } from '../fields/dateField';
import { WithOptional } from '../utility';
import { DoesConnectionExistType } from '../shared/doesConnectionExistType';
import { InfoIconContent } from '@/api/interfaces/content/form/base/infoIconContent';
import { WhichVoltageLevelType } from '../shared/whichVoltageLevelType';

type ConfigProps = WithOptional<PowerConnectionFormStep, keyof FormStep>;

export class PowerConnectionFormStep extends FormStep {
    public readonly doesConnectionExist: RadioButtonsField<
        DoesConnectionExistType
    >;
    public readonly orderNumber: TextField;
    public readonly whichVoltageLevel: RadioButtonsField<WhichVoltageLevelType>;
    public readonly hasExistingPlants: CheckboxField;
    public readonly existingPlantsTotalPower: TextField & InfoIconContent;
    public readonly repoweringPlanned: CheckboxField;
    public readonly repoweringTotalPower: TextField & InfoIconContent;
    public readonly feedInPowerShallBeLimitedByAController: CheckboxField;
    public readonly feedInPower: TextField & InfoIconContent;
    public readonly plannedReferencePower: TextField & InfoIconContent;
    public readonly ofWhichOwnUseGeneratingPlant: TextField & InfoIconContent;
    public readonly ownUseExceedsPlannedPowerWarning: FormElement;
    public readonly noConnectionAvailable: FormElement;
    public readonly reactivePowerCompensationSystemExists: CheckboxField;
    public readonly unitCapacity: TextField;
    public readonly unitIsPrototype: CheckboxField;
    public readonly buildingPowerDemand: CheckboxField;
    public readonly requiredPower: TextField;
    public readonly fromWhen: DateField;

    public constructor(config: ConfigProps) {
        super(config);

        this.doesConnectionExist = config.doesConnectionExist;
        this.orderNumber = config.orderNumber;
        this.whichVoltageLevel = config.whichVoltageLevel;
        this.hasExistingPlants = config.hasExistingPlants;
        this.existingPlantsTotalPower = config.existingPlantsTotalPower;
        this.repoweringPlanned = config.repoweringPlanned;
        this.repoweringTotalPower = config.repoweringTotalPower;
        this.feedInPowerShallBeLimitedByAController =
            config.feedInPowerShallBeLimitedByAController;
        this.feedInPower = config.feedInPower;
        this.plannedReferencePower = config.plannedReferencePower;
        this.ofWhichOwnUseGeneratingPlant = config.ofWhichOwnUseGeneratingPlant;
        this.ownUseExceedsPlannedPowerWarning =
            config.ownUseExceedsPlannedPowerWarning;
        this.noConnectionAvailable = config.noConnectionAvailable;
        this.reactivePowerCompensationSystemExists =
            config.reactivePowerCompensationSystemExists;
        this.unitCapacity = config.unitCapacity;
        this.unitIsPrototype = config.unitIsPrototype;
        this.buildingPowerDemand = config.buildingPowerDemand;
        this.requiredPower = config.requiredPower;
        this.fromWhen = config.fromWhen;
    }
}
