import { JSONSchema7Definition } from 'json-schema';
import { defaults as _defaults } from 'lodash';
import {
    FormFieldConfig,
    formFieldDefaultConfig,
    FormField,
} from './formField';
import { number } from '@/validators/number';
import { minNumber } from '@/validators/minNumber';
import { maxNumber } from '@/validators/maxNumber';

export interface CountFieldConfig extends FormFieldConfig<number> {
    min?: number;
    max?: number;
    step?: number;
}

export const countFieldDefaultConfig = {
    ...formFieldDefaultConfig,
    min: 1,
    max: 99999999,
    step: 1,
};

export class CountField extends FormField<number> {
    public readonly min: number;
    public readonly max: number;
    public readonly step: number;

    public constructor(config?: CountFieldConfig) {
        const resolvedConfig = _defaults(
            config || ({} as CountFieldConfig),
            countFieldDefaultConfig,
        ) as Required<CountFieldConfig>;

        resolvedConfig.validators = {
            ...resolvedConfig.validators,
            number,
            minNumber: minNumber(resolvedConfig.min),
            maxNumber: maxNumber(resolvedConfig.max),
        };

        super(resolvedConfig);

        this.min = resolvedConfig.min;
        this.max = resolvedConfig.max;
        this.step = resolvedConfig.step;
    }

    public getJsonSchema(): JSONSchema7Definition {
        return {
            type: 'number',
        };
    }
}
