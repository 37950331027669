import { helpers, ValidationRule } from 'vuelidate/lib/validators';
import { number } from './number';

export const minNumber = (min: number): ValidationRule => {
    return helpers.withParams({ type: 'minNumber', min }, (value: unknown) => {
        return (
            !helpers.req(value) || !number(value) || (value as number) >= min
        );
    });
};
