import { PowerConnectionFormStep } from '@/types/forms/formSteps/powerConnectionFormStep';
import {
    isDea,
    isDischargePowerBelowOrEqualTippingPoint,
    isLowVoltage,
    isPluginGeneratingPlant,
    isPowerHeatCoupling,
    isPv,
    isRequestWithoutPlanning,
    isStorage,
    isUnknown,
} from './common';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import { findFirstValidKey } from '@/utilities/findFirstValidKey';
import { isHigherThanOrEqual } from '@/utilities/stringParsingUtils';

export const powerConnectionRules = async (
    formStep: {
        model: PowerConnectionFormStep;
        formModel: ApplicationForm;
    } & Vue,
): Promise<void> => {
    const hasLowVoltage = isLowVoltage(formStep.model);

    const isOther = (formModel: ApplicationForm) =>
        isDea(formModel) ||
        isPowerHeatCoupling(formModel) ||
        isUnknown(formModel);

    const requestWithoutPlanning = isRequestWithoutPlanning(formStep.formModel);

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565649/Formular+Netzanschluss#FormularNetzanschluss-orderNumber
    formStep.model.orderNumber.disabled =
        formStep.model.doesConnectionExist.disabled ||
        formStep.model.doesConnectionExist.value !== 'ordered';

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565649/Formular+Netzanschluss#FormularNetzanschluss-hasExistingPlants
    formStep.model.hasExistingPlants.disabled =
        isPluginGeneratingPlant(formStep.formModel) ||
        (!formStep.model.doesConnectionExist.disabled &&
            formStep.model.doesConnectionExist.value !== 'yes') ||
        isStorage(formStep.formModel);

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565649/Formular+Netzanschluss#FormularNetzanschluss-existingPlantsTotalPower
    formStep.model.existingPlantsTotalPower.disabled =
        formStep.model.hasExistingPlants.disabled ||
        !formStep.model.hasExistingPlants.value;

    type ExistingPlantsTotalPowerConditionalInfoKey =
        | 'pvInfo'
        | 'storageInfo'
        | 'otherInfo';

    //In-Feed Management Conditional Info
    const existingPlantsTotalPowerConditionInfo = {
        pvInfo: isPv(formStep.formModel),
        storageInfo: isStorage(formStep.formModel),
        otherInfo: isOther(formStep.formModel),
    } as Record<ExistingPlantsTotalPowerConditionalInfoKey, boolean>;

    formStep.model.existingPlantsTotalPower.conditionalInfoKey = findFirstValidKey(
        existingPlantsTotalPowerConditionInfo,
    );

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565649/Formular+Netzanschluss#FormularNetzanschluss-hasExistingPlants
    formStep.model.repoweringPlanned.disabled =
        formStep.model.hasExistingPlants.disabled ||
        !formStep.model.hasExistingPlants.value;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565649/Formular+Netzanschluss#FormularNetzanschluss-existingPlantsTotalPower
    formStep.model.repoweringTotalPower.disabled =
        formStep.model.repoweringPlanned.disabled ||
        !formStep.model.repoweringPlanned.value;

    const isLvStorageBelow30 =
        isStorage(formStep.formModel) &&
        isLowVoltage(formStep.model) &&
        isDischargePowerBelowOrEqualTippingPoint(
            formStep.formModel.plantPerformance,
            30,
        );

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565649/Formular+Netzanschluss#FormularNetzanschluss-feedInPowerShallBeLimitedByAController
    formStep.model.feedInPowerShallBeLimitedByAController.disabled =
        isPluginGeneratingPlant(formStep.formModel) || isLvStorageBelow30;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565649/Formular+Netzanschluss#FormularNetzanschluss-feedInPower
    formStep.model.feedInPower.disabled =
        isPluginGeneratingPlant(formStep.formModel) || isLvStorageBelow30;
    formStep.model.feedInPower.userChangeable =
        !formStep.model.feedInPowerShallBeLimitedByAController.disabled &&
        (formStep.model.feedInPowerShallBeLimitedByAController.value ?? false);

    const requestTypeIsWithPlanningOrDisabled =
        formStep.formModel.generationType.requestType.disabled ||
        (!formStep.formModel.generationType.requestType.disabled &&
            formStep.formModel.generationType.requestType.value ===
                'requestWithPlanning');
    const doesConnectionExistIsOrderedOrNo =
        !formStep.model.doesConnectionExist.disabled &&
        (formStep.model.doesConnectionExist.value === 'ordered' ||
            formStep.model.doesConnectionExist.value === 'no');
    const doesConnectionExistIsOrderedOrNoWithRequestTypePlanning =
        requestTypeIsWithPlanningOrDisabled && doesConnectionExistIsOrderedOrNo;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565649/Formular+Netzanschluss#FormularNetzanschluss-plannedReferencePower
    formStep.model.plannedReferencePower.disabled = !doesConnectionExistIsOrderedOrNoWithRequestTypePlanning;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565649/Formular+Netzanschluss#FormularNetzanschluss-ofWhichOwnUseGeneratingPlant
    formStep.model.ofWhichOwnUseGeneratingPlant.disabled = !doesConnectionExistIsOrderedOrNoWithRequestTypePlanning;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565649/Formular+Netzanschluss#FormularNetzanschluss-noConnectionAvailable
    formStep.model.noConnectionAvailable.disabled =
        formStep.model.doesConnectionExist.disabled ||
        formStep.model.doesConnectionExist.value !== 'no';

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565649/Formular+Netzanschluss#FormularNetzanschluss-reactivePowerCompensationSystemExists
    formStep.model.reactivePowerCompensationSystemExists.disabled = hasLowVoltage;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565649/Formular+Netzanschluss#FormularNetzanschluss-unitCapacity
    formStep.model.unitCapacity.disabled =
        formStep.model.reactivePowerCompensationSystemExists.disabled ||
        !formStep.model.reactivePowerCompensationSystemExists.value;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565649/Formular+Netzanschluss#FormularNetzanschluss-unitIsPrototype
    formStep.model.unitIsPrototype.disabled = hasLowVoltage;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565649/Formular+Netzanschluss#FormularNetzanschluss-buildingPowerDemand
    formStep.model.buildingPowerDemand.disabled =
        hasLowVoltage || requestWithoutPlanning;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565649/Formular+Netzanschluss#FormularNetzanschluss-requiredPower
    formStep.model.requiredPower.disabled =
        formStep.model.buildingPowerDemand.disabled ||
        !formStep.model.buildingPowerDemand.value;

    // https://netzkern.atlassian.net/wiki/spaces/EWE/pages/4854565649/Formular+Netzanschluss#FormularNetzanschluss-fromWhen
    formStep.model.fromWhen.disabled =
        formStep.model.buildingPowerDemand.disabled ||
        !formStep.model.buildingPowerDemand.value;

    // https://netzkern.atlassian.net/browse/ED-597
    formStep.model.ownUseExceedsPlannedPowerWarning.disabled =
        formStep.model.plannedReferencePower.disabled ||
        formStep.model.ofWhichOwnUseGeneratingPlant.disabled ||
        isHigherThanOrEqual(
            formStep.model.plannedReferencePower.value,
            formStep.model.ofWhichOwnUseGeneratingPlant.value,
        );
};
