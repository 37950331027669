import { TextField } from '../../fields/textField';
import { FormGroup } from '../../formGroup';
import { WithOptional } from '../../utility';

type ConfigProps = WithOptional<
    ManufacturerAndModuleTypeFormGroup,
    keyof FormGroup
>;

export class ManufacturerAndModuleTypeFormGroup extends FormGroup {
    public readonly manufacturer: TextField;
    public readonly moduleType: TextField;

    public constructor(config: ConfigProps) {
        super(config);

        this.manufacturer = config.manufacturer;
        this.moduleType = config.moduleType;
    }
}
