import { FormStep } from '../formStep';
import { TextField } from '../fields/textField';
import { KitchenSinkUserListType } from './formGroups/kitchenSinkUserList';
import { KitchenSinkPlantListType } from './formGroups/kitchenSinkPlantList';
import { WithOptional } from '../utility';

export type KitchenSinkFormStep4Config = WithOptional<
    KitchenSinkFormStep4,
    keyof FormStep
>;

export class KitchenSinkFormStep4 extends FormStep {
    public readonly primary: TextField;
    public readonly secondary: TextField;

    public readonly users: KitchenSinkUserListType;
    public readonly plants: KitchenSinkPlantListType;

    public constructor(config: KitchenSinkFormStep4Config) {
        super(config);

        this.primary = config.primary;
        this.secondary = config.secondary;

        this.users = config.users;
        this.plants = config.plants;
    }
}
