import { DateField } from '../fields/dateField';
import { RadioButtonsField } from '../fields/radioButtonsField';
import { SelectField } from '../fields/selectField';
import { TextField } from '../fields/textField';
import { FormElementConfig } from '../formElement';
import { FormGroup } from '../formGroup';
import type { SalutationValueType } from '../shared/salutationValueType';
import type { TitleValueType } from '../shared/titleValueType';
import { FirstAndLastNameFormGroup } from './firstAndLastNameFormGroup';

export type OperatorFormGroupConfig = FormElementConfig &
    Omit<OperatorFormGroup, keyof FormGroup>;

export class OperatorFormGroup extends FormGroup {
    public readonly salutation: RadioButtonsField<SalutationValueType>;
    public readonly companyName: TextField;
    public readonly title: SelectField<TitleValueType>;
    public readonly operatorName: FirstAndLastNameFormGroup;
    public readonly contactPersonName: FirstAndLastNameFormGroup;
    public readonly birthday: DateField;

    public constructor(config: OperatorFormGroupConfig) {
        super(config);

        this.salutation = config.salutation;
        this.companyName = config.companyName;
        this.title = config.title;
        this.operatorName = config.operatorName;
        this.contactPersonName = config.contactPersonName;
        this.birthday = config.birthday;
    }
}
