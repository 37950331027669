import { DateField } from '../fields/dateField';
import { RadioButtonsField } from '../fields/radioButtonsField';
import { SelectField } from '../fields/selectField';
import { TextField } from '../fields/textField';
import { StreetAndHousenumberFormGroup } from '../formGroups/streetAndHousenumberFormGroup';
import { ZipAndCityFormGroup } from '../formGroups/zipAndCityFormGroup';
import type { CountryValueType } from '../shared/countryValueType';
import { FormElementConfig } from '../formElement';
import { FormGroup } from '../formGroup';
import type { SalutationValueType } from '../shared/salutationValueType';
import type { TitleValueType } from '../shared/titleValueType';
import { FirstAndLastNameFormGroup } from './firstAndLastNameFormGroup';

export type OwnerFormGroupConfig = FormElementConfig &
    Omit<OwnerFormGroup, keyof FormGroup>;

export class OwnerFormGroup extends FormGroup {
    public readonly salutation: RadioButtonsField<SalutationValueType>;
    public readonly companyName: TextField;
    public readonly title: SelectField<TitleValueType>;
    public readonly ownerName: FirstAndLastNameFormGroup;
    public readonly contactPersonName: FirstAndLastNameFormGroup;
    public readonly birthday: DateField;
    public readonly country: SelectField<CountryValueType>;
    public readonly zipAndCity: ZipAndCityFormGroup;
    public readonly streetAndHousenumber: StreetAndHousenumberFormGroup;
    public readonly email: TextField;
    public readonly phoneNumber1: TextField;
    public readonly phoneNumber2: TextField;

    public constructor(config: OwnerFormGroupConfig) {
        super(config);

        this.salutation = config.salutation;
        this.companyName = config.companyName;
        this.title = config.title;
        this.ownerName = config.ownerName;
        this.contactPersonName = config.contactPersonName;
        this.birthday = config.birthday;
        this.country = config.country;
        this.zipAndCity = config.zipAndCity;
        this.streetAndHousenumber = config.streetAndHousenumber;
        this.email = config.email;
        this.phoneNumber1 = config.phoneNumber1;
        this.phoneNumber2 = config.phoneNumber2;
    }
}
