import {
    getDegreesValidators,
    getMinutesValidators,
    getSecondsValidators,
} from '@/validators/geoCoordinates';
import { FormGroup } from '../formGroup';
import { WithOptional } from '../utility';
import { TextField } from './textField';

export enum GeoOrientation {
    null = 'null',
    longitude = 'longitude',
    latitude = 'latitude',
}

type OptionalProps = 'degrees' | 'minutes' | 'seconds';

export type GeoOrientationConfig = WithOptional<
    GeoCoordinatesFormGroup,
    keyof FormGroup | OptionalProps
>;

export class GeoCoordinatesFormGroup extends FormGroup {
    public readonly orientation: GeoOrientation;
    public readonly degrees: TextField;
    public readonly minutes: TextField;
    public readonly seconds: TextField;

    public constructor(config: GeoOrientationConfig) {
        super(config);

        this.orientation = GeoOrientation.null;
        this.degrees =
            config.degrees ??
            new TextField({
                validators: getDegreesValidators(this.orientation),
            });
        this.minutes =
            config.minutes ??
            new TextField({
                validators: getMinutesValidators(),
            });
        this.seconds =
            config.seconds ??
            new TextField({
                validators: getSecondsValidators(),
            });
    }
}
